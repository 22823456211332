import { getDateString } from "utils/dateTimeUtils";
import { fileSize } from "utils/fileUtils";
import { ReportResponse } from "types";

export const headerHeight = 38;
export const rowHeight = 40;
export const defaultOrderBy = "createdOn";
export const defaultOrder = "DESC";

export const reportsInitialState: ReportResponse = {
  totalCount: 0,
  data: []
};

export const reportTableColumns = [
  {
    width: 220,
    flexGrow: 0,
    label: "Group Name",
    dataKey: "clientGroup",
    disableSort: true,
    cellContentRenderer: (cellRendererProps: any) => {
      if (!cellRendererProps.cellData) return "Full Report";
      if (cellRendererProps.cellData === "...") return "...";

      return cellRendererProps.cellData.name;
    }
  },
  {
    width: 480,
    flexGrow: 1.0,
    label: "File Name",
    dataKey: "originalName"
  },
  {
    width: 180,
    flexGrow: 0,
    label: "Generation Date",
    dataKey: "createdOn",
    cellContentRenderer: (cellRendererProps: any) => {
      if (!cellRendererProps.cellData) return null;
      if (cellRendererProps.cellData === "...") return "...";

      return getDateString(cellRendererProps.cellData);
    }
  },
  {
    width: 150,
    flexGrow: 0,
    label: "File Size",
    dataKey: "size",
    disableSort: true,
    cellContentRenderer: (cellRendererProps: any) => {
      if (!cellRendererProps.cellData) return null;
      if (cellRendererProps.cellData === "...") return "...";

      return fileSize(cellRendererProps.cellData, true);
    }
  }
];
