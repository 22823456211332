import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  menuButton: {
    fontSize: "0.7125rem",
    padding: "2px 5px",
    borderRadius: 0,
    marginRight: "4px"
  },
  row: {
    marginBottom: "16px",
    display: "grid"
  },
  dialogTitle: {
    fontSize: "24px"
  },
  dialogPaper: {
    overflow: "visible",
    padding: "18px 24px 8px"
  },
  mb: {
    marginBottom: "6px"
  },
  selected: {
    backgroundColor: "rgba(240, 80, 37, 0.08)",
    "&:hover": { backgroundColor: "rgba(240, 80, 37, 0.08)" }
  },
  switchLabel: {
    marginLeft: 0
  },
  progressWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px"
  },
  helperText: {
    position: "absolute",
    marginTop: "-6px",
    fontSize: "14px",
    color: "rgba(240, 80, 37, 0.6)"
  }
}));
