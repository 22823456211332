import React, { useRef } from "react";
import { FieldProps } from "formik";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";

const useStyles = makeStyles(() =>
  createStyles({
    mfaContainer: {
      display: "flex"
    },
    helperText: {
      position: "absolute",
      margin: "4px 0 0 4px",
      color: "#f44336",
      fontSize: "0.75rem"
    },
    singleField: {
      margin: "0 5px",
      height: 85,
      display: "flex",
      flexDirection: "row",
      borderRadius: "6px 6px 0 0",
      "&:not(:focus)": {
        backgroundColor: "rgba(0,0,0,0.02)"
      },
      "& input": {
        fontSize: 65,
        fontWeight: 300,
        textAlign: "center"
      }
    }
  })
);

export type MFAComponentProps = {
  textFieldProps?: TextFieldProps;
};

export type FormikMFAComponentProps = MFAComponentProps & FieldProps;

export const FormikMFA = (props: FormikMFAComponentProps) => {
  const {
    field,
    form: { setFieldValue, setFieldTouched },
    textFieldProps
  } = props;
  const mfa0 = useRef<HTMLInputElement>();
  const mfa1 = useRef<HTMLInputElement>();
  const mfa2 = useRef<HTMLInputElement>();
  const mfa3 = useRef<HTMLInputElement>();
  const mfa4 = useRef<HTMLInputElement>();
  const mfa5 = useRef<HTMLInputElement>();

  const classes = useStyles();

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    nextInput?: React.MutableRefObject<HTMLInputElement | undefined>
  ) => {
    const { target } = event;
    handleInputChange(target, nextInput);
  };

  const handleInputChange = (
    target: HTMLInputElement,
    nextInput?: React.MutableRefObject<HTMLInputElement | undefined>
  ) => {
    const { value } = target;
    const newValue = value.replace(/ /g, "");

    if (!isValidNumber(newValue)) {
      target.value = "";
      updateMFAValue();
      return;
    }

    if (target.name === "mfa0" && newValue.length >= 6) {
      const mfaCodeArray = newValue.split("");

      target.value = mfaCodeArray[0];
      if (mfa1 && mfa1.current) mfa1.current.value = mfaCodeArray[1];
      if (mfa2 && mfa2.current) mfa2.current.value = mfaCodeArray[2];
      if (mfa3 && mfa3.current) mfa3.current.value = mfaCodeArray[3];
      if (mfa4 && mfa4.current) mfa4.current.value = mfaCodeArray[4];
      if (mfa5 && mfa5.current) {
        mfa5.current.value = mfaCodeArray[5];
        mfa5.current.focus();
      }
    } else {
      target.value = target.value.charAt(target.value.length - 1);
      if (nextInput && nextInput.current && value.length) nextInput.current.focus();
    }

    updateMFAValue();
  };

  const onBlur = () => {
    setFieldTouched(field.name, true);
  };

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    previousInput?: React.MutableRefObject<HTMLInputElement | undefined>,
    nextInput?: React.MutableRefObject<HTMLInputElement | undefined>
  ) => {
    const { target, key, keyCode } = event;
    const targetInput = target as HTMLInputElement;
    const value = targetInput.value;

    if (!!value.length && (key === "ArrowRight" || key === " " || keyCode === 39 || keyCode === 32)) {
      event.preventDefault();
      event.stopPropagation();

      if (nextInput && nextInput.current) nextInput.current.focus();
    } else if (key === "ArrowLeft" || keyCode === 37 || (!value.length && (key === "Backspace" || keyCode === 8))) {
      event.preventDefault();
      event.stopPropagation();
      if (previousInput && previousInput.current) previousInput.current.focus();
    }
  };

  const isValidNumber = (value: string) => {
    const reg = new RegExp("^[0-9]+$");
    return reg.test(value);
  };

  const updateMFAValue = () => {
    const mfa0Value = mfa0 && mfa0.current ? mfa0.current.value : "";
    const mfa1Value = mfa1 && mfa1.current ? mfa1.current.value : "";
    const mfa2Value = mfa2 && mfa2.current ? mfa2.current.value : "";
    const mfa3Value = mfa3 && mfa3.current ? mfa3.current.value : "";
    const mfa4Value = mfa4 && mfa4.current ? mfa4.current.value : "";
    const mfa5Value = mfa5 && mfa5.current ? mfa5.current.value : "";
    const value = `${mfa0Value}${mfa1Value}${mfa2Value}${mfa3Value}${mfa4Value}${mfa5Value}`;

    setFieldValue(field.name, value);
  };

  return (
    <div>
      <div className={classes.mfaContainer}>
        <TextField
          id="mfa0"
          name="mfa0"
          autoFocus
          className={classes.singleField}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event, mfa1)}
          onBlur={onBlur}
          InputProps={{
            onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(event, undefined, mfa1)
          }}
          inputRef={mfa0}
          {...textFieldProps}
        />
        <TextField
          id="mfa1"
          name="mfa1"
          className={classes.singleField}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event, mfa2);
          }}
          onBlur={onBlur}
          InputProps={{
            onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(event, mfa0, mfa2)
          }}
          inputRef={mfa1}
          {...textFieldProps}
        />
        <TextField
          id="mfa2"
          name="mfa2"
          className={classes.singleField}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event, mfa3);
          }}
          onBlur={onBlur}
          InputProps={{
            onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(event, mfa1, mfa3)
          }}
          inputRef={mfa2}
          {...textFieldProps}
        />
        <TextField
          id="mfa3"
          name="mfa3"
          className={classes.singleField}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event, mfa4);
          }}
          InputProps={{
            onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(event, mfa2, mfa4)
          }}
          inputRef={mfa3}
          {...textFieldProps}
        />
        <TextField
          id="mfa4"
          name="mfa4"
          className={classes.singleField}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event, mfa5);
          }}
          onBlur={onBlur}
          InputProps={{
            onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(event, mfa3, mfa5)
          }}
          inputRef={mfa4}
          {...textFieldProps}
        />
        <TextField
          id="mfa5"
          name="mfa5"
          className={classes.singleField}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event);
          }}
          onBlur={onBlur}
          InputProps={{
            onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(event, mfa4)
          }}
          inputRef={mfa5}
          {...textFieldProps}
        />
      </div>
    </div>
  );
};

export default FormikMFA;
