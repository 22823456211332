import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  panelTitle: {
    height: "36px",
    fontSize: "18px"
  },
  expPanel: {
    borderRadius: "4px",
    "&::before": {
      display: "none"
    }
  },
  noOptions: {
    width: "100%",
    marginTop: "15px",
    textAlign: "center"
  },
  legacyRequestsLink: {
    margin: "8px 0"
  }
}));
