import axios from "axios";
import InteractionModel from "models/interaction.model";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { InteractionReducerModel, UpdateIntercationPayload } from "types";

const setInteractions: Action<
  InteractionReducerModel,
  InteractionModel[]
> = action((state, interactions) => {
  state.interactions = interactions;
});

const createInteraction: Thunk<InteractionReducerModel, {}> = thunk(
  async (actions, payload) => {
    const response: any = await axios.post("/interactions", payload);

    return response;
  }
);

const updateInteraction: Thunk<
  InteractionReducerModel,
  UpdateIntercationPayload
> = thunk(async (actions, payload) => {
  const response = axios.patch(`/interactions/${payload.id}`, payload.data);

  return response;
});

const InteractionReducer: InteractionReducerModel = {
  interactions: [],
  setInteractions,
  createInteraction,
  updateInteraction
};

export default InteractionReducer;
