import React, { useEffect } from "react";
import { StoreProvider } from "easy-peasy";
import { AMPSTheme } from "@amps/material-ui";
import CareNavigatorTheme from "CareNavigatorTheme";
import Main from "Main";
import store from "redux/store";
import "./styles/App.scss";

export default function App() {
  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("lastUnload", Date.now().toString());
    });
  }, []);

  return (
    <StoreProvider store={store}>
      <AMPSTheme>
        <CareNavigatorTheme>
          <Main />
        </CareNavigatorTheme>
      </AMPSTheme>
    </StoreProvider>
  );
}
