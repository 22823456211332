import { useState, useEffect } from "react";

function useDownloadReportContainer() {
  const [downloadReportContainer] = useState(document.createElement("div"));

  useEffect(() => {
    const downloadReportRoot = document.querySelector("#download-requests");

    if (!downloadReportRoot) return;

    downloadReportRoot.appendChild(downloadReportContainer);

    return function() {
      downloadReportRoot.removeChild(downloadReportContainer);
    };
  }, [downloadReportContainer]);

  return downloadReportContainer;
}

export { useDownloadReportContainer };
