import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  progressWrapper: {
    position: "relative"
  },
  downloadButton: {
    marginLeft: "8px",
    backgroundColor: "#fefefe"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px"
  }
}));
