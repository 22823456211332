import orderByFunc from "lodash/orderBy";
import { getDateString } from "utils/dateTimeUtils";
import { getUserFullName } from "utils/userUtils";

import UserModel from "models/user.model";
import RequestModel from "models/request.model";

export function getSortedData(data: RequestModel[], orderBy?: string, order?: "asc" | "desc") {
  let orderByParam, orderParam;

  if (!orderBy || !order) return data;

  switch (orderBy) {
    case "currentStage":
      orderByParam = ["currentStage.id"];
      orderParam = [order];
      break;
    case "service":
      orderByParam = ["services[0].type.name"];
      orderParam = [order];
      break;
    case "client":
      orderByParam = ["client.code"];
      orderParam = [order];
      break;
    case "vip":
      orderByParam = [(request: RequestModel) =>request?.member?.isVip];
      orderParam = [order];
      break;
    case "member":
      orderByParam = ["member.lastName", "member.firstName"];
      orderParam = [order, order];
      break;
    case "updatedOn":
      orderByParam = ["updatedOn"];
      orderParam = [order];
      break;
    case "updatedBy":
      orderByParam = ["updatedBy.lastName", "updatedBy.firstName"];
      orderParam = [order, order];
      break;
    case "Priority":
      orderByParam = ["priority"];
      orderParam = [order];
      break;
    case "id":
      orderByParam = [(request: RequestModel) => request.id];
      orderParam = [order];
      break;
    case "status":
      orderByParam = [
        (request: RequestModel) => request.requestStatus.name,
        (request: RequestModel) => request.additionalStatus || ""
      ];
      orderParam = [order, order];
      break;
    case "nearestFollowUp":
      orderByParam = [
        (request: RequestModel) => (order === "asc" ? request.nearestFollowUp : request.nearestFollowUp || "")
      ];
      orderParam = [order];
      break;
  }

  return orderByFunc(data, orderByParam, orderParam);
}

export function getFilteredData(
  data: RequestModel[],
  loggedInUser: UserModel | null,
  onlyMyRequests?: boolean,
  searchFilter?: string
) {
  if (!loggedInUser) return data;

  let filteredData = [...data];

  if (onlyMyRequests) {
    filteredData = getOnlyMyRequests(filteredData, loggedInUser);
  }

  if (searchFilter) {
    filteredData = getFilteredRequests(filteredData, searchFilter);
  }

  return filteredData;
}

function getOnlyMyRequests(data: RequestModel[], loggedInUser: UserModel) {
  return data.filter(request => {
    if (!request.services.length) return false;

    let hasMyInteraction = false;

    request.services.forEach(service => {
      service.interactions.forEach(interaction => {
        if (interaction.createdBy.id.toString() === loggedInUser.id.toString()) {
          hasMyInteraction = true;
        }
      });
    });

    return hasMyInteraction;
  });
}

function getFilteredRequests(data: RequestModel[], searchFilter: string) {
  const filter = searchFilter.toLowerCase();

  return data.filter(request => {
    return (
      request.id
        .toString()
        .toLowerCase()
        .includes(filter) ||
      (request.additionalStatus
        ? request.requestStatus &&
          `${request.requestStatus.name} - ${request.additionalStatus}`.toLowerCase().includes(filter)
        : request.requestStatus && request.requestStatus.name.toLowerCase().includes(filter)) ||
      (request.currentStage &&
        `${request.currentStage.id}: ${request.currentStage.name}`.toLowerCase().includes(filter)) ||
      (request.nearestFollowUp &&
        getDateString(request.nearestFollowUp)
          .toLowerCase()
          .includes(filter)) ||
      (request.member && `${request.member.lastName}, ${request.member.firstName}`.toLowerCase().includes(filter)) ||
      getDateString(request.updatedOn)
        .toLowerCase()
        .includes(filter) ||
      getUserFullName(request.updatedBy)
        .toLowerCase()
        .includes(filter)
    );
  });
}
