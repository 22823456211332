import React, { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { WarnModal } from "@amps/material-ui";
import jwt_decode from "jwt-decode";
import UserModel from "models/user.model";
import { getFingerprint } from "utils/fingerprint";
import { useStoreState, useStoreActions } from "redux/reducers/hooks";
import { ErrorOutline } from "@material-ui/icons";

function Axios() {
  const [openBRM, setOpenBRM] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { user: userFromRedux } = useStoreState(state => state.auth);
  const { logout, updateToken } = useStoreActions(actions => actions.auth);
  const handleCloseBRM = () => {
    setOpenBRM(false);
  };

  const handleAxiosError = (error: AxiosError) => {
    setErrorMessage("");

    const {
      config: { url = "" },
      response
    } = error;
    const ignoredAPIs = ["/auth", "/auth/renew", "auth/resend"];
    const isIgnored = ignoredAPIs.some(api => url.endsWith(api));

    if (!isIgnored) {
      setOpenBRM(true);

      if (response && response.data && response.data.message) {
        setErrorMessage(response.data.message);
      }
    }
  };

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || undefined;
    axios.defaults.headers.common["Cache-Control"] = "no-cache,no-store,must-revalidate,max-age=-1,private";

    const reqInterceptor = axios.interceptors.request.use(
      async config => {
        config.headers.fingerprint = await getFingerprint();

        if (config.url && config.url.endsWith("/auth")) {
          return config;
        }

        const token = sessionStorage.getItem("jwtToken");

        if (!token) {
          logout(false);

          return {
            ...config,
            cancelToken: new axios.CancelToken(cancel => cancel("Cancel request w/o token"))
          };
        }

        const userFromToken: UserModel = jwt_decode(token);

        if (userFromRedux && userFromToken.id !== userFromRedux.id) {
          window.location.reload();
          return config;
        }

        config.headers.Authorization = `bearer ${token}`;

        return config;
      },
      error => {
        Promise.reject(error);
      }
    );

    const resInterceptor = axios.interceptors.response.use(
      response => {
        const newToken: string = response && response.headers && response.headers["elephant-jwt-token"];

        newToken && updateToken(newToken);
        return response && response.data;
      },
      error => {
        if (error.response && error.response.status === 401) {
          return Promise.reject(error);
        }

        if (!axios.isCancel(error)) {
          handleAxiosError(error);
        }

        return Promise.reject(error);
      }
    );

    return () => {
      // remove all intercepts when done
      axios.interceptors.request.eject(reqInterceptor);
      axios.interceptors.response.eject(resInterceptor);
    };
  }, [logout, updateToken, userFromRedux]);

  return (
    <WarnModal
      open={openBRM}
      Icon={ErrorOutline}
      header="Bad Request"
      body={<span>{errorMessage || "Some error occured on server. Please try again"}</span>}
      onOk={handleCloseBRM}
      buttonText="OK"
    />
  );
}

export default Axios;
