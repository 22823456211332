import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  row: {
    marginBottom: "16px"
  },
  expansionPanel: {
    cursor: "default !important"
  },
  menuButton: {
    fontSize: "0.7125rem",
    padding: "2px 5px",
    borderRadius: 0,
    marginRight: "4px",
    textTransform: "uppercase",
    fontWeight: 500
  },
  emergencyIcon: {
    fontSize: "18px"
  },
  panelTitle: {
    verticalAlign: "middle",
    fontSize: "18px"
  },
  helperText: {
    fontStyle: "italic",
    fontSize: "12px"
  },
  panelDetails: {
    display: "block",
    padding: "8px 24px"
  },
  expandButton: {
    marginLeft: "12px"
  },
  pr: {
    paddingRight: "52px"
  },
  urgentText: {
    fontSize: "13px"
  },
  divider: {
    display: "inline-block",
    marginRight: "4px",
    verticalAlign: "middle",
    width: "1px",
    height: "24px"
  },
  prButtons: {
    paddingRight: "15px"
  },
  barContainer: {
    height: "24px"
  },
  rowTitle: {
    marginBottom: "4px",
    wordBreak: "break-all"
  },
  reOpenButton: {
    marginLeft: "8px"
  }
}));
