import React, { useState } from "react";
import { MUI, SideIconButton } from "@amps/material-ui";
import useStyles from "components/ReportsPage/styles/ReportsToolbar.style";
import { CloudDownload, AssignmentInd, Assignment, Search } from "@material-ui/icons";

type Props = {
  onCreateReport: Function;
  onModalOpen: Function;
  onSearch: Function;
};

export function ReportsToolbar(props: Props) {
  const { onCreateReport, onModalOpen, onSearch } = props;
  const classes = useStyles();
  const [searchFilter, setSearchFilter] = useState("");

  const isDev = process.env.REACT_APP_SERVER_ENV && process.env.REACT_APP_SERVER_ENV === "-dev";

  return (
    <div className={classes.toolbar}>
      <div className={classes.rowItem}>
        <SideIconButton
          id="download-custom-report"
          variant="contained"
          color="primary"
          Icon={CloudDownload}
          align="left"
          onClick={() => {
            onModalOpen();
          }}
        >
          Download Custom Report
        </SideIconButton>
      </div>
      {isDev && (
        <>
          <div className={classes.rowItem}>
            <SideIconButton
              id="generate-client-report"
              variant="contained"
              color="secondary"
              Icon={AssignmentInd}
              align="left"
              onClick={() => {
                onCreateReport("client");
              }}
            >
              Generate client reports
            </SideIconButton>
          </div>
          <div className={classes.rowItem}>
            <SideIconButton
              id="generate-full-report"
              variant="contained"
              color="secondary"
              Icon={Assignment}
              align="left"
              onClick={() => {
                onCreateReport("full");
              }}
            >
              Generate full reports
            </SideIconButton>
          </div>
        </>
      )}
      <div className={`${classes.rowItem}--right`}>
        <MUI.TextField
          variant="outlined"
          fullWidth
          placeholder="Search"
          value={searchFilter}
          onChange={event => {
            setSearchFilter(event.target.value);
            onSearch(event.target.value);
          }}
          InputProps={{
            classes: {
              input: classes.textField
            },
            endAdornment: (
              <MUI.InputAdornment position="start">
                <Search />
              </MUI.InputAdornment>
            )
          }}
        />
      </div>
    </div>
  );
}

export default ReportsToolbar;
