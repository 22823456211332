import React from "react";
import { MUI } from "@amps/material-ui";

export default function FormikSwitch(props: any) {
  const { field, form, label, labelPlacement = "end", ...otherProps } = props;

  return (
    <MUI.FormControlLabel
      control={<MUI.Switch {...otherProps} {...field} color="primary" />}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
}
