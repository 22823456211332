import axios from "axios";
import MiscCodeModel from "models/miscCode.model";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { MiscCodesReducerModel } from "types";

const setMiscCodes: Action<MiscCodesReducerModel, MiscCodeModel[]> = action(
  (state, miscCodes) => {
    state.miscCodes = miscCodes;
  }
);

const getMiscCodes: Thunk<MiscCodesReducerModel, string> = thunk(
  async (actions, search) => {
    const response: any = await axios.get("/misc-codes", {
      params: {
        value: search
      }
    });

    return response.data;
  }
);

const MiscCodesReducer: MiscCodesReducerModel = {
  miscCodes: [],
  setMiscCodes,
  getMiscCodes
};

export default MiscCodesReducer;
