import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-24px 0 0 -24px"
  }
}));
