import React, { useState, useCallback, useEffect, useRef } from "react";
import { MUI } from "@amps/material-ui";
import useStyles from "components/DashboardPage/styles/DashboardPage.style";
import RequestsTable from "components/DashboardPage/RequestsTable/RequestsTable";
import PageTitle from "components/shared/PageTitle";
import RequestsToolbar from "components/DashboardPage/RequestsToolbar";
import ClosedRequestsTable from "components/DashboardPage/RequestsTable/ClosedRequestsTable";
import RequestModel from "models/request.model";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useStoreState, useStoreActions } from "redux/reducers/hooks";
import { initialState } from "redux/reducers/requestReducer";
import { RequestsSearchCriteriaType } from "types";
import { debounce } from "lodash";

function DashboardPage(props: RouteComponentProps<any>) {
  const { history } = props;
  const classes = useStyles();
  const [onlyMyRequests, setOnlyMyRequests] = useState(false);
  const [onlyClosedRequests, setOnlyClosedRequests] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const { requests, requestsData, searchCriteria, filterCriteria } = useStoreState(state => state.request);
  const { getRequests, setFilterCriteria, setSearchCriteria, setRequests } = useStoreActions(
    actions => actions.request
  );

  const fetchOpenRequests = useCallback(async () => {
    setIsSearching(true);
    try {
      const searchData = {
        skip: 0,
        take: 500,
        openOnly: true
      };
      setSearchCriteria(searchData);
      await getRequests(searchData);
    } finally {
      setIsSearching(false);
    }
  }, [getRequests, setSearchCriteria]);

  const onClosedRequestsChange = (checked: boolean) => {
    setOnlyClosedRequests(checked);
    setOnlyMyRequests(false);
    setSearchCriteria({
      ...searchCriteria,
      openOnly: !checked
    });
    setFilterCriteria({
      ...filterCriteria,
      onlyMyRequests: false,
      searchText: ""
    });

    if (checked) {
      setRequests(initialState.requestsData);
    } else {
      fetchOpenRequests();
    }
  };

  const onMyRequestsChange = (event: object, checked: boolean) => {
    setOnlyMyRequests(checked);
    setFilterCriteria({ ...filterCriteria, onlyMyRequests: checked });
  };

  const onSearchTextChange = useRef(
    debounce((searchText: string) => {
      setFilterCriteria({ ...filterCriteria, searchText });
    }, 1000)
  ).current;

  const onSearchClosedRequests = async (newSearchCriteria: RequestsSearchCriteriaType) => {
    setIsSearching(true);
    try {
      setSearchCriteria(newSearchCriteria);
      await getRequests(newSearchCriteria);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSortChange = (sortBy: string, sortDirection: "asc" | "desc") => {
    setFilterCriteria({ ...filterCriteria, sortBy, sortDirection });
  };

  const onSelectRequest = (request: RequestModel) => {
    if (onlyClosedRequests) {
      window.open(`${window.location.href}request/${request.id}`, "_newtab" + Date.now());
    } else {
      history.push(`request/${request.id}`);
    }
  };

  useEffect(() => {
    fetchOpenRequests();
  }, [fetchOpenRequests]);

  useEffect(() => {
    return () => {
      setSearchCriteria(initialState.searchCriteria);
      setFilterCriteria(initialState.filterCriteria);
    };
  }, [setSearchCriteria, setFilterCriteria]);

  return (
    <MUI.Container className={classes.container} maxWidth="lg">
      <PageTitle title="Member Service Requests" />
      <RequestsToolbar
        isLoading={isSearching}
        onlyMyRequests={onlyMyRequests}
        onlyClosedRequests={onlyClosedRequests}
        onClosedRequestsChange={onClosedRequestsChange}
        onMyRequestsChange={onMyRequestsChange}
        onSearchTextChange={onSearchTextChange}
        onSearchClosedRequests={onSearchClosedRequests}
      />
      <div className={classes.tableContainer}>
        {onlyClosedRequests ? (
          <ClosedRequestsTable
            isLoading={isSearching}
            requestsData={requestsData}
            searchCriteria={searchCriteria}
            onSelectRequest={onSelectRequest}
            onSearchClosedRequests={onSearchClosedRequests}
          />
        ) : (
          <RequestsTable
            isLoading={isSearching}
            requests={requests}
            onSortChange={handleSortChange}
            onSelectRequest={onSelectRequest}
          />
        )}
      </div>
    </MUI.Container>
  );
}

export default withRouter(DashboardPage);
