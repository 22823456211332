import React from "react";
import { MUI } from "@amps/material-ui";
import { Field } from "formik";
import clsx from "clsx";
import addDays from "date-fns/add_days";

import useStyles from "components/shared/styles/TreatmentForm.style";
import MiscCodeModel from "models/miscCode.model";
import MiscCodeTypes from "utils/constants/MiscCodeTypes";
import { getFormattedDate, getPickerMinDate, getPickerMaxDate } from "utils/dateTimeUtils";
import NumberFormatField from "containers/NumberFormatField";
import FormikReactSelect from "containers/FormikReactSelect";
import FormikTextField from "containers/FormikTextField";
import FormikReactSelectAsync from "containers/FormikReactSelectAsync";
import FormikToggleButtonGroup from "containers/FormikToggleButtonGroup";
import FormikKeyboardDatePicker from "containers/FormikKeyboardDatePicker";
import MemberBenefits from "components/shared/MemberBenefits";
import { useStoreActions } from "redux/reducers/hooks";

export interface RadiusOption {
  value: string;
  label: string;
}
export const radiusOptions: RadiusOption[] = [
  { value: "5", label: "5 miles" },
  { value: "10", label: "10 miles" },
  { value: "15", label: "15 miles" },
  { value: "25", label: "25 miles" },
  { value: "50", label: "50 miles" },
  { value: "75", label: "75 miles" },
  { value: "100", label: "100 miles" }
];

interface Props {
  form: any;
  viewMode?: boolean;
  shortLabels?: boolean;
}

export const getCPTLabel = (cptCode: MiscCodeModel) => {
  if (cptCode.name) {
    return `${cptCode.code}: ${cptCode.name}`;
  } else if (cptCode.note) {
    return `${cptCode.code}: ${cptCode.note}`;
  }

  return cptCode.code;
};

export function TreatmentForm(props: Props) {
  const classes = useStyles();
  const { form, shortLabels = false, viewMode = false } = props;
  const getMiscCodes = useStoreActions(actions => actions.miscCodes.getMiscCodes);
  const minScheduledDate = getPickerMinDate(addDays(new Date(), 1));
  const minCertifiedDate = getPickerMinDate();
  const maxPickerDate = getPickerMaxDate();

  const loadMiscCodesOptions = async (inputValue: string) => {
    return getMiscCodes(inputValue);
  };

  const getDiagnosisLabel = (diagnosis: MiscCodeModel) => {
    return diagnosis.name ? `${diagnosis.code}: ${diagnosis.name}` : diagnosis.code;
  };

  const getMiscCodesLabel = (miscCode: MiscCodeModel) => {
    return miscCode.type === MiscCodeTypes.ICD_10_CM ? getDiagnosisLabel(miscCode) : getCPTLabel(miscCode);
  };

  const getMiscCodesTooltip = (miscCodes: MiscCodeModel[]) => {
    const miscCodesTooltip = miscCodes.map((miscCode: MiscCodeModel) => getMiscCodesLabel(miscCode)).join("\n");

    return miscCodesTooltip;
  };

  const handlePreAuthNeededChange = (form: any) => {
    form.setFieldValue("preActionNumber", "");
    form.setFieldValue("pointOfContact", "");
    form.setFieldValue("contactNumber", "");
    form.setFieldValue("authCertifiedDate", null);
  };

  const handleProcedureScheduledChange = (form: any) => {
    form.setFieldValue("procedureScheduledDate", null);
  };

  return (
    <>
      <div className={classes.row}>
        <MUI.Grid container spacing={3}>
          <MUI.Grid item xs={4}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              {shortLabels ? "Desired Location" : "Desired Location for Procedure"}
            </MUI.Typography>
            <Field
              name="zip"
              component={FormikTextField}
              variant="outlined"
              fullWidth
              placeholder="Enter Zipcode"
              tooltipText={form.values.zip}
              disabled={viewMode}
              InputProps={{
                classes: {
                  input: classes.textField,
                  disabled: classes.disabledTextField
                },
                inputComponent: NumberFormatField
              }}
              // eslint-disable-next-line
              inputProps={{
                format: "#####"
              }}
            />
          </MUI.Grid>
          <MUI.Grid item xs={4}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              Maximum Miles/Radius
            </MUI.Typography>
            <Field
              name="radius"
              component={FormikReactSelect}
              options={radiusOptions}
              isDisabled={viewMode}
              tooltipText={form.values.radius && form.values.radius.label}
            />
          </MUI.Grid>
          <MUI.Grid item xs={4} />
        </MUI.Grid>
      </div>

      <div className={classes.row}>
        <MUI.Grid container spacing={3}>
          <MUI.Grid item xs={6}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              Referring Physician - Contact Info
            </MUI.Typography>
            {viewMode && (
              <MUI.Tooltip classes={{ popper: classes.popper }} title={form.values.referringPhysician} placement="top">
                <div className={classes.viewModeTextArea}>{form.values.referringPhysician}</div>
              </MUI.Tooltip>
            )}
            {!viewMode && (
              <Field
                name="referringPhysician"
                component={FormikTextField}
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                rowsMax={2}
                tooltipText={form.values.referringPhysician}
                InputProps={{
                  classes: {
                    root: classes.multilineRoot,
                    input: clsx(classes.textField, classes.multilineTextField)
                  }
                }}
              />
            )}
          </MUI.Grid>
          <MUI.Grid item xs={6}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              Primary Care Physician - Contact Info
            </MUI.Typography>
            {viewMode && (
              <MUI.Tooltip
                classes={{ popper: classes.popper }}
                title={form.values.primaryCarePhysician}
                placement="top"
              >
                <div className={classes.viewModeTextArea}>{form.values.primaryCarePhysician}</div>
              </MUI.Tooltip>
            )}
            {!viewMode && (
              <Field
                name="primaryCarePhysician"
                component={FormikTextField}
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                rowsMax={2}
                tooltipText={form.values.primaryCarePhysician}
                InputProps={{
                  classes: {
                    root: classes.multilineRoot,
                    input: clsx(classes.textField, classes.multilineTextField)
                  }
                }}
              />
            )}
          </MUI.Grid>
        </MUI.Grid>
      </div>
      <div className={classes.row}>
        <MUI.Grid container spacing={3}>
          <MUI.Grid item xs={8}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              Type of Procedure Requested
            </MUI.Typography>
            <Field
              name="procedureType"
              component={FormikTextField}
              tooltipText={form.values.procedureType}
              variant="outlined"
              fullWidth
              disabled={viewMode}
              InputProps={{
                classes: {
                  input: classes.textField,
                  disabled: classes.disabledTextField
                }
              }}
            />
          </MUI.Grid>
        </MUI.Grid>
      </div>
      <div className={classes.row}>
        <MUI.Grid container spacing={3}>
          <MUI.Grid item xs={8}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              Service Diagnosis & CPT Codes
            </MUI.Typography>
            <Field
              name="miscCodes"
              component={FormikReactSelectAsync}
              components={{ DropdownIndicator: null }}
              placeholder="Search and select by typing at least 3 characters"
              tooltipText={
                form.values.miscCodes && form.values.miscCodes.length && getMiscCodesTooltip(form.values.miscCodes)
              }
              loadOptions={loadMiscCodesOptions}
              getOptionLabel={(option: MiscCodeModel) => getMiscCodesLabel(option)}
              getOptionValue={(option: MiscCodeModel) => option.id}
              isMulti
              maxOptionCount={25}
              limitReachedText="25 codes limit reached"
              isDisabled={viewMode}
            />
          </MUI.Grid>
        </MUI.Grid>
      </div>
      <div className={classes.row}>
        <MUI.Grid container spacing={3}>
          <MUI.Grid item xs={4}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              Is Procedure Scheduled?
            </MUI.Typography>
            <Field
              name="isProcedureScheduled"
              component={FormikToggleButtonGroup}
              options={[
                { value: true, label: "Yes", name: "procedureScheduled" },
                { value: false, label: "No", name: "procedureNotScheduled" }
              ]}
              disabled={viewMode}
              onChange={(isProcedureScheduled: boolean) => handleProcedureScheduledChange(form)}
            />
          </MUI.Grid>
          {form.values.isProcedureScheduled && (
            <MUI.Grid item xs={4}>
              <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                Date Procedure Scheduled
              </MUI.Typography>
              <Field
                name="procedureScheduledDate"
                tooltipText={
                  form.values.procedureScheduledDate &&
                  getFormattedDate(new Date(form.values.procedureScheduledDate), "MM/DD/YYYY")
                }
                component={FormikKeyboardDatePicker}
                fullWidth
                ignorePickerErrors={true}
                minDate={minScheduledDate}
                maxDate={maxPickerDate}
                disabled={viewMode}
              />
            </MUI.Grid>
          )}
        </MUI.Grid>
      </div>
      <div className={classes.row}>
        <MUI.Grid container spacing={3}>
          <MUI.Grid item xs={4}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              Pre-Cert/Pre-Auth Needed?
            </MUI.Typography>
            <Field
              name="isPreActionNeeded"
              component={FormikToggleButtonGroup}
              options={[
                { value: true, label: "Yes", name: "preActionNeeded" },
                { value: false, label: "No", name: "preActionNotNeeded" }
              ]}
              disabled={viewMode}
              onChange={(isPreActionNeeded: boolean) => handlePreAuthNeededChange(form)}
            />
          </MUI.Grid>
          {form.values.isPreActionNeeded && (
            <>
              <MUI.Grid item xs={4}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  {shortLabels ? "Pre-Cert/Pre-Auth #" : "Pre-Cert/Pre-Authorization #"}
                </MUI.Typography>
                <Field
                  name="preActionNumber"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                  tooltipText={form.values.preActionNumber}
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      disabled: classes.disabledTextField
                    }
                  }}
                  disabled={viewMode}
                />
              </MUI.Grid>
              <MUI.Grid item xs={4}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  Date Authorized/Certified
                </MUI.Typography>
                <Field
                  name="authCertifiedDate"
                  tooltipText={
                    form.values.authCertifiedDate &&
                    getFormattedDate(new Date(form.values.authCertifiedDate), "MM/DD/YYYY")
                  }
                  minDate={minCertifiedDate}
                  maxDate={maxPickerDate}
                  component={FormikKeyboardDatePicker}
                  fullWidth
                  disabled={viewMode}
                />
              </MUI.Grid>
              <MUI.Grid item xs={6} className={classes.alignField}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  Point of Contact
                </MUI.Typography>
                <Field
                  name="pointOfContact"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                  tooltipText={form.values.pointOfContact}
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      disabled: classes.disabledTextField
                    }
                  }}
                  disabled={viewMode}
                />
              </MUI.Grid>
              <MUI.Grid item xs={6} className={classes.alignField}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  Contact Number
                </MUI.Typography>
                <Field
                  name="contactNumber"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                  tooltipText={form.values.contactNumber}
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      disabled: classes.disabledTextField
                    }
                  }}
                  disabled={viewMode}
                />
              </MUI.Grid>
            </>
          )}
        </MUI.Grid>
      </div>
      <MemberBenefits viewMode={viewMode} form={form} enableTooltips={true} />
    </>
  );
}

export default TreatmentForm;
