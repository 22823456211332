import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  container: {
    "& > div": {
      marginBottom: 12,
      "&:last-child": {
        marginBottom: 0
      }
    }
  },
  rowTitle: {
    marginBottom: "8px",
    fontSize: "16px"
  },
  subRowTitle: {
    fontSize: "16px",
    marginRight: "15px"
  },
  progressWrapper: {
    position: "relative",
    width: "100%",
    height: "40.625px"
  },
  searchButton: {
    height: "100%"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px"
  },
  searchWrapper: {
    flexGrow: 0,
    flexShrink: 1,
    display: "flex",
    alignItems: "flex-end"
  },
  dateWrapper: {
    display: "flex",
    alignItems: "center"
  },
  formContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexWrap: "nowrap"
  },
  row: {
    display: "flex",
    flex: "auto",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  rowItem: {
    flex: "auto",
    paddingRight: 14,
    "&:last-child": {
      paddingRight: 0
    }
  },
  textField: {
    fontSize: "14px",
    padding: "12px",
    flexWrap: "wrap"
  },

  rowItems: {
    marginBottom: 30,
    flexGrow: 0,
    "&--right": {
      float: "right",
      marginTop: '-50px'

    }
  },
}));
