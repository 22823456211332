import React, { useState, useEffect } from "react";
import { MUI } from "@amps/material-ui";
import useStyles from "styles/App.style";
import Router from "Router";
import Axios from "Axios.config";
import { useStoreActions } from "redux/reducers/hooks";

export default function Main() {
  const [isLoading, setIsLoading] = useState(true);
  const getPermissions = useStoreActions(actions => actions.auth.getPermissions);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      getPermissions();
    }, 1500);
  }, [getPermissions]);

  return (
    <div>
      {isLoading ? (
        <MUI.CircularProgress size={48} className={classes.buttonProgress} />
      ) : (
        <div>
          <Axios />
          <Router />
        </div>
      )}
    </div>
  );
}
