import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  row: {
    marginBottom: "16px",
    display: "grid"
  },
  labelTitle: {
    marginBottom: "16px",
    fontSize: "16px"
  },
  rowTitle: {
    marginBottom: "8px",
    fontSize: "16px"
  },
  textField: {
    fontSize: "14px",
    padding: "12px",
    backgroundColor: "white"
  },
  bw: {
    backgroundColor: "white"
  },
  disabledTextField: {
    color: "rgb(153, 153, 153)"
  },
  wrapper: {
    padding: "15px",
    marginBottom: "16px",
    borderRadius: "2px",
    boxShadow: "0 0 3px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#f5f5f5",
    border: "0"
  }
}));
