import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  logo: {
    width: 90,
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    fontSize: 35
  },
  iconSmall: {
    fontSize: 25
  },
  logoSection: {
    flexBasis: 150,
    "&.dark #lgWording": {
      fill: "#FFF"
    },
    "&.light #lgWording": {
      fill: "#444"
    }
  }
}));
