import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  dialogTitle: {
    fontSize: "24px"
  },
  dialogPaper: {
    overflow: "visible",
    padding: "18px 24px 8px"
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: 600
  },
  row: {
    marginBottom: "16px",
    display: "grid"
  },
  sectionRow: {
    marginBottom: "16px"
  },
  rowTitle: {
    marginBottom: "8px",
    fontSize: "16px"
  },
  textField: {
    fontSize: "14px",
    padding: "12px",
    backgroundColor: "white"
  },
  paper: {
    marginTop: "16px",
    padding: "16px",
    backgroundColor: "#e6eaed"
  },
  multilineRoot: {
    padding: 0
  },
  mt: {
    marginTop: "8px"
  },
  requiredLabel: {
    fontSize: "12px",
    verticalAlign: "middle",
    color: "#f44336"
  },
  progressWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px"
  },
  productItem: {
    overflowWrap: "break-word",
    fontWeight: 600
  }
}));
