import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  row: {
    marginBottom: "16px",
    display: "grid"
  },
  labelTitle: {
    marginBottom: "16px",
    fontSize: "16px"
  },
  rowTitle: {
    marginBottom: "8px",
    fontSize: "16px"
  },
  textField: {
    fontSize: "14px",
    padding: "12px",
    backgroundColor: "white"
  },
  multilineRoot: {
    padding: 0
  },
  multilineTextField: {
    padding: "8px 12px",
    height: "52px!important"
  },
  bw: {
    backgroundColor: "white"
  },
  disabledTextField: {
    color: "rgb(153, 153, 153)"
  },
  wrapper: {
    padding: "15px",
    marginBottom: "16px",
    borderRadius: "2px",
    boxShadow: "0 0 3px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#f5f5f5",
    border: "0"
  },
  popper: {
    whiteSpace: "pre-line"
  },
  alignField: {
    marginTop: "-8px"
  },
  viewModeTextArea: {
    height: "68px",
    overflow: "auto",
    color: "rgb(153, 153, 153)",
    whiteSpace: "pre-line",
    padding: "7px 11px",
    backgroundColor: "white",
    border: "1px solid rgb(193, 193, 193)",
    borderRadius: "4px",
    lineHeight: "19px",
    overflowWrap: "break-word",
    letterSpacing: "normal"
  }
}));
