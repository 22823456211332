import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  row: {
    marginBottom: "16px"
  },
  pr: {
    paddingRight: "52px"
  },
  newInteractionButton: {
    fontWeight: "bold",
    textTransform: "none",
    marginRight: "16px"
  },
  actionButton: {
    marginRight: "16px"
  },
  iconMargin: {
    marginRight: "6px"
  },
  closeRequestButton: {
    backgroundColor: "#A12310"
  }
}));
