import React, { useState } from "react";
import { MUI, Button } from "@amps/material-ui";
import { Formik, Form, Field } from "formik";
import FormikKeyboardDatePicker from "containers/FormikKeyboardDatePicker";
import * as Yup from "yup";
import useStyles from "components/ReportsPage/styles/CustomReportModal.style";
import ClientModel from "models/client.model";
import GroupModel from "models/group.model";
import { getPickerMinDate, getPickerMaxDate, isDateLess } from "utils/dateTimeUtils";
import { RequestsSearchCriteriaType } from "types";
import FormikReactSelect from "containers/FormikReactSelect";
import { getGroupLabel, getClientLabel } from "utils/clientUtils";
import { useStoreActions, useStoreState } from "redux/reducers/hooks";
import { Modals } from "utils/modalUtils";

interface CustomReportFormValues {
  client: ClientModel | null;
  group: GroupModel | null;
  requestCreatedStartDate: string | null;
  requestCreatedEndDate: string | null;
  requestUpdatedEndDate: string | null;
  requestUpdatedStartDate: string | null;
}

const CustomReportFormSchema = Yup.object().shape({
  client: Yup.object().nullable(),
  group: Yup.object().nullable(),
  requestCreatedStartDate: Yup.date()
    .nullable()
    .typeError("Invalid field value")
    .test("isValidCreatedStartDate", "Invalid date range", function(requestCreatedStartDate: string) {
      return isDateLess(requestCreatedStartDate, this.parent.requestCreatedEndDate);
    }),
  requestCreatedEndDate: Yup.date()
    .nullable()
    .typeError("Invalid field value")
    .test("isValidCraetedEndDate", "Invalid date range", function(requestCreatedEndDate: string) {
      return isDateLess(this.parent.requestCreatedStartDate, requestCreatedEndDate);
    }),
  requestUpdatedStartDate: Yup.date()
    .nullable()
    .typeError("Invalid field value")
    .test("isValidUpdatedStartDate", "Invalid date range", function(requestUpdatedStartDate: string) {
      return isDateLess(requestUpdatedStartDate, this.parent.requestUpdatedEndDate);
    }),
  requestUpdatedEndDate: Yup.date()
    .nullable()
    .typeError("Invalid field value")
    .test("isValidUpdatedEndDate", "Invalid date range", function(requestUpdatedEndDate: string) {
      return isDateLess(this.parent.requestUpdatedStartDate, requestUpdatedEndDate);
    })
});

export function CustomReportModal() {
  const closeModal = useStoreActions(actions => actions.modal.closeModal);
  const fetchCustomReport = useStoreActions(actions => actions.report.fetchCustomReport);
  const [groups, setGroups] = useState([] as GroupModel[]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const getAllRequests = useStoreActions(actions => actions.request.getAllRequests);
  const getGroupsByClientId = useStoreActions(actions => actions.group.getGroupsByClientId);
  const clients = useStoreState(state => state.client.clients);
  const classes = useStyles();
  const pickerMinDate = getPickerMinDate();
  const pickerMaxDate = getPickerMaxDate(new Date());

  const handleSelectClient = async (client: ClientModel, form: any) => {
    setIsLoadingGroups(true);
    try {
      form.setFieldValue("group", null);
      setGroups([]);

      if (!client) return;

      const { id } = client;
      const groups = await getGroupsByClientId(id);
      setGroups(groups);
    } finally {
      setIsLoadingGroups(false);
    }
  };

  const handleCreateCustomReport = async (values: CustomReportFormValues) => {
    setIsLoading(true);
    setIsInvalid(false);
    try {
      const {
        client,
        group,
        requestCreatedStartDate,
        requestCreatedEndDate,
        requestUpdatedEndDate,
        requestUpdatedStartDate
      } = values;

      const data: RequestsSearchCriteriaType = {
        clientId: client ? parseInt(client.id) : null,
        clientGroupId: group ? parseInt(group.id) : null,
        requestCreatedStart: requestCreatedStartDate ? new Date(requestCreatedStartDate) : null,
        requestCreatedEnd: requestCreatedEndDate ? new Date(`${requestCreatedEndDate} 23:59:59`) : null,
        requestUpdatedEnd: requestUpdatedEndDate ? new Date(`${requestUpdatedEndDate} 23:59:59`) : null,
        requestUpdatedStart: requestUpdatedStartDate ? new Date(requestUpdatedStartDate) : null
      };

      const response = await getAllRequests(data);

      if (response.data && response.data.length) {
        setIsInvalid(false);
        await fetchCustomReport({
          searchData: data,
          client,
          clientGroup: group
        });
      } else {
        setIsInvalid(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isFormValid = (isFormikFormValid: boolean) => {
    return isFormikFormValid;
  };

  return (
    <MUI.Dialog
      open={true}
      onClose={() => closeModal(Modals.CustomReport)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth={true}
      disableBackdropClick
    >
      <Formik
        initialValues={{
          client: null,
          group: null,
          requestCreatedStartDate: null,
          requestCreatedEndDate: null,
          requestUpdatedEndDate: null,
          requestUpdatedStartDate: null
        }}
        validationSchema={CustomReportFormSchema}
        isInitialValid={true}
        onSubmit={handleCreateCustomReport}
      >
        {requestForm => (
          <>
            <MUI.DialogContent className={classes.dialogPaper}>
              <div className={classes.row}>
                <MUI.Typography variant="h5">Download Custom Report</MUI.Typography>
                <MUI.Typography variant="subtitle1" className={classes.dialogSubtitle}>
                  Create a one-off custom report with the filters below
                </MUI.Typography>
              </div>
              <Form>
                <div className={classes.row}>
                  <MUI.Grid container spacing={3}>
                    <MUI.Grid item xs>
                      <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                        Client
                      </MUI.Typography>
                      <Field
                        name="client"
                        id="client-select"
                        isClearable
                        options={clients}
                        component={FormikReactSelect}
                        getOptionLabel={getClientLabel}
                        getOptionValue={(option: ClientModel) => option.id}
                        onChange={(client: ClientModel) => handleSelectClient(client, requestForm)}
                      />
                    </MUI.Grid>
                    <MUI.Grid item xs>
                      <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                        Group
                      </MUI.Typography>
                      <Field
                        name="group"
                        isClearable
                        options={groups}
                        component={FormikReactSelect}
                        isDisabled={!requestForm.values.client || isLoadingGroups}
                        getOptionLabel={getGroupLabel}
                        getOptionValue={(option: GroupModel) => option.id}
                      />
                    </MUI.Grid>
                  </MUI.Grid>
                </div>
                <div className={classes.row}>
                  <MUI.Paper className={classes.paperWrapper}>
                    <MUI.Typography variant="body2" color="textSecondary" className={classes.paperTitle}>
                      Request Created Date
                    </MUI.Typography>
                    <MUI.Grid container spacing={3}>
                      <MUI.Grid item xs>
                        <MUI.Typography variant="body2" color="textSecondary" className={classes.subRowTitle}>
                          From
                        </MUI.Typography>
                        <Field
                          name="requestCreatedStartDate"
                          component={FormikKeyboardDatePicker}
                          fullWidth
                          minDate={pickerMinDate}
                          maxDate={pickerMaxDate}
                          minDateMessage="Invalid field value"
                          maxDateMessage="Future date is not allowed"
                        />
                      </MUI.Grid>
                      <MUI.Grid item xs>
                        <MUI.Typography variant="body2" color="textSecondary" className={classes.subRowTitle}>
                          To
                        </MUI.Typography>
                        <Field
                          name="requestCreatedEndDate"
                          component={FormikKeyboardDatePicker}
                          fullWidth
                          minDate={pickerMinDate}
                          maxDate={pickerMaxDate}
                          minDateMessage="Invalid field value"
                          maxDateMessage="Future date is not allowed"
                        />
                      </MUI.Grid>
                    </MUI.Grid>
                  </MUI.Paper>
                </div>
                <div className={classes.row}>
                  <MUI.Paper className={classes.paperWrapper}>
                    <MUI.Typography variant="body2" color="textSecondary" className={classes.paperTitle}>
                      Request Updated Date
                    </MUI.Typography>

                    <MUI.Grid container spacing={3}>
                      <MUI.Grid item xs>
                        <MUI.Typography variant="body2" color="textSecondary" className={classes.subRowTitle}>
                          From
                        </MUI.Typography>
                        <Field
                          name="requestUpdatedStartDate"
                          component={FormikKeyboardDatePicker}
                          fullWidth
                          minDate={pickerMinDate}
                          maxDate={pickerMaxDate}
                          minDateMessage="Invalid field value"
                          maxDateMessage="Future date is not allowed"
                        />
                      </MUI.Grid>
                      <MUI.Grid item xs>
                        <MUI.Typography variant="body2" color="textSecondary" className={classes.subRowTitle}>
                          To
                        </MUI.Typography>
                        <Field
                          name="requestUpdatedEndDate"
                          component={FormikKeyboardDatePicker}
                          fullWidth
                          minDate={pickerMinDate}
                          maxDate={pickerMaxDate}
                          minDateMessage="Invalid field value"
                          maxDateMessage="Future date is not allowed"
                        />
                      </MUI.Grid>
                    </MUI.Grid>
                  </MUI.Paper>
                </div>
                {isInvalid && (
                  <MUI.Paper className={classes.noOptionsPaper}>
                    <MUI.Typography className={classes.noOptions}>
                      There are no requests with this search criteria
                    </MUI.Typography>
                  </MUI.Paper>
                )}
              </Form>
            </MUI.DialogContent>
            <MUI.DialogActions>
              <Button
                id="cancel-custom-report-button"
                onClick={() => {
                  closeModal("CustomReportModal");
                }}
                color="primary"
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                onClick={() => requestForm.handleSubmit()}
                variant={isFormValid(requestForm.isValid) ? "contained" : "text"}
                color="primary"
                autoFocus
                disabled={!isFormValid(requestForm.isValid) || isLoading}
              >
                Download Report
              </Button>
            </MUI.DialogActions>
            {isLoading && (
              <MUI.Paper className={classes.loadingPaper}>
                <MUI.CircularProgress size={24} className={classes.buttonProgress} />
              </MUI.Paper>
            )}
          </>
        )}
      </Formik>
    </MUI.Dialog>
  );
}

export default CustomReportModal;
