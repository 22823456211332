import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  reportsTableContainer: {
    marginTop: "17px"
  },
  noOptions: {
    textAlign: "center",
    backgroundColor: "#e6eaed"
  },
  paper: {
    maxHeight: "calc(100vh - 240px)",
    border: "0",
    boxShadow: "none",
    background: "transparent"
  },
  tableRow: {
    borderLeft: "1px solid #e0e0e0",
    borderRight: "1px solid #e0e0e0"
  },
  rootContainer: {
    borderBottom: "1px solid #e0e0e0"
  },
  tableHead: {
    padding: "6px 24px 6px 16px",
    fontSize: "0.75rem",
    backgroundColor: "#f0502514"
  },
  tableCell: {
    fontSize: "0.875rem",
    padding: "6px 24px 6px 16px",
    overflowY: "auto"
  }
}));
