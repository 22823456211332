import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  form: {
    marginBottom: "16px"
  },
  panel: {
    backgroundColor: "#FCF8E3"
  },
  ml: {
    marginLeft: "8px"
  },
  panelDetails: {
    display: "block",
    paddingBottom: "16px"
  },
  progressWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -8px"
  }
}));
