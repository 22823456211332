import React from "react";
import { ThemeProvider, createMuiTheme, ThemeOptions } from "@material-ui/core/styles";
import { AMPSTheme } from "@amps/material-ui";

export type LoginThemeProps = {
  themeStyle?: "light" | "dark";
  children: React.ReactNode;
};
const LoginTheme = (props: LoginThemeProps) => {
  const { themeStyle = "light", children } = props;
  const theme: ThemeOptions = {
    palette: {
      primary: {
        main: "#3ccbda",
        contrastText: "#FFF"
      },
      secondary: {
        main: "#ff8a65",
        contrastText: "#FFF"
      },
      type: themeStyle
    }
  };
  const LoginTheme = createMuiTheme(theme, AMPSTheme);
  return <ThemeProvider theme={LoginTheme}>{children}</ThemeProvider>;
};

export default LoginTheme;
