import React, { useState, useEffect } from "react";
import { MUI, Button } from "@amps/material-ui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router";
import addDays from "date-fns/add_days";
import { getYupMinDate, getYupMaxDate, getPickerMinDate, getPickerMaxDate } from "utils/dateTimeUtils";
import useStyles from "components/shared/styles/NewServiceModal.style";
import ServiceTypeModel from "models/serviceType.model";
import MemberModel from "models/member.model";
import RequestModel from "models/request.model";
import MiscCodeModel from "models/miscCode.model";
import ServiceIntentionModel from "models/serviceIntention.model";
import ServiceIntentionTypes from "utils/constants/ServiceIntentionTypes";
import FormikReactSelect from "containers/FormikReactSelect";
import FormikTextField from "containers/FormikTextField";
import FormikKeyboardDatePicker from "containers/FormikKeyboardDatePicker";
import FormikSwitch from "containers/FormikSwitch";
import TreatmentForm, { RadiusOption, radiusOptions } from "components/shared/TreatmentForm";
import ProductModel from "models/product.model";
import { useStoreActions, useStoreState } from "redux/reducers/hooks";
import { Modals } from "utils/modalUtils";
import { sortProducts } from "./styles/ProductSortingUtils";

interface NewServiceFormValues {
  type?: ServiceTypeModel;
  intentions: ServiceIntentionModel[];
  zip?: string;
  radius?: RadiusOption;
  procedureType?: string;
  miscCodes?: MiscCodeModel[];
  isPreActionNeeded: boolean | null;
  pointOfContact?: string;
  contactNumber?: string;
  preActionNumber?: string;
  coPayAmount?: string;
  referringPhysician?: string;
  primaryCarePhysician?: string;
  followUpDate: Date | null;
  isCompleted: boolean;
  externalNote: string;
  internalNote: string;
  isProcedureScheduled?: boolean | null;
  procedureScheduledDate?: Date | string | null;
  authCertifiedDate?: Date | string | null;
  deductible?: string;
  deductibleMET?: string;
  oop?: string;
  oopMet?: string;
  coInsurance?: string;
  other?: string;
}

type NewServiceModalProps = {
  isFirstService?: boolean;
  member: MemberModel;
  request: RequestModel;
};

type Props = NewServiceModalProps & RouteComponentProps<any>;
export function NewServiceModal(props: Props) {
  const classes = useStyles();
  const [products, setProducts] = useState([] as ProductModel[]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const { isFirstService = true, history, member, request } = props;

  const {
    request: { getRequestById, createRequest },
    service: { createService, getServiceIntentions },
    product: { getProductsByGroupId },
    modal: { openModal, closeModal }
  } = useStoreActions(actions => actions);

  const { startIntentions: serviceStartIntentions, types: serviceTypes } = useStoreState(state => state.service);

  const minYupDate = getYupMinDate();
  const maxYupDate = getYupMaxDate();
  const followUpPickerMinDate = getPickerMinDate(addDays(new Date(), 1));
  const followUpPickerMaxDate = getPickerMaxDate();
  const NewServiceFormSchema = Yup.object().shape({
    type: Yup.object().required("Required"),
    intentions: Yup.array()
      .nullable()
      .required("Required"),
    zip: Yup.string(),
    radius: Yup.object(),
    procedureType: Yup.string().max(50, "Must be 50 characters or less"),
    preActionNumber: Yup.string().max(25, "Must be 25 characters or less"),
    coPayAmount: Yup.string(),
    referringPhysician: Yup.string().max(200, "Must be 200 characters or less"),
    primaryCarePhysician: Yup.string().max(200, "Must be 200 characters or less"),
    followUpDate: Yup.date().nullable(),
    isCompleted: Yup.bool(),
    externalNote: Yup.string()
      .required("Required")
      .max(2000, "Must be 2000 characters or less"),
    internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
    miscCodes: Yup.array().ensure(),
    isPreActionNeeded: Yup.boolean().nullable(),
    pointOfContact: Yup.string().max(50, "Must be 50 characters or less"),
    contactNumber: Yup.string().max(50, "Must be 50 characters or less"),
    isProcedureScheduled: Yup.boolean().nullable(),
    procedureScheduledDate: Yup.date()
      .nullable()
      .typeError("Invalid field value")
      .min(minYupDate, "Invalid field value")
      .max(maxYupDate, "Invalid field value"),
    authCertifiedDate: Yup.date()
      .nullable()
      .typeError("Invalid field value")
      .min(minYupDate, "Invalid field value")
      .max(maxYupDate, "Invalid field value"),
    deductible: Yup.string(),
    deductibleMET: Yup.string(),
    oop: Yup.string(),
    oopMet: Yup.string(),
    coInsurance: Yup.string().max(100, "Must be 100 characters or less"),
    other: Yup.string().max(100, "Must be 100 characters or less")
  });

  useEffect(() => {
    getServiceIntentions(ServiceIntentionTypes.START);
  }, [serviceStartIntentions, getServiceIntentions]);

  useEffect(() => {
    async function fetchProducts() {
      setProductsLoading(true);

      try {
        const { clientGroupId } = member;
        const data = await getProductsByGroupId(clientGroupId);

        setProducts(data.products);
      } finally {
        setProductsLoading(false);
      }
    }

    fetchProducts();
  }, [member, getProductsByGroupId]);

  const handleNewService = async (values: NewServiceFormValues) => {
    if (!values.type) return;

    setIsFormSubmitting(true);

    const {
      type,
      intentions,
      isCompleted,
      zip,
      radius,
      coPayAmount,
      preActionNumber,
      isPreActionNeeded,
      pointOfContact,
      contactNumber,
      primaryCarePhysician,
      referringPhysician,
      procedureType,
      externalNote,
      internalNote,
      followUpDate,
      isProcedureScheduled,
      procedureScheduledDate,
      authCertifiedDate,
      deductible,
      deductibleMET,
      oop,
      oopMet,
      coInsurance,
      other,
      miscCodes
    } = values;

    // collect service data
    const serviceData: any = {
      typeId: parseInt(type.id),
      startIntentionIds: intentions.map((intention: ServiceIntentionModel) => parseInt(intention.id)),
      isCompleted
    };

    zip && (serviceData.zipCode = zip);
    radius && (serviceData.searchRadius = parseInt(radius.value));
    coPayAmount && (serviceData.copayAmount = parseFloat(coPayAmount));
    isPreActionNeeded && preActionNumber && (serviceData.preCertNum = preActionNumber);
    isPreActionNeeded !== null && (serviceData.preCertNeeded = isPreActionNeeded);
    isPreActionNeeded && pointOfContact && (serviceData.pointOfContact = pointOfContact);
    isPreActionNeeded && contactNumber && (serviceData.contactNumber = contactNumber);
    primaryCarePhysician && (serviceData.primaryPhysician = primaryCarePhysician);
    referringPhysician && (serviceData.referringPhysician = referringPhysician);
    procedureType && (serviceData.procedureType = procedureType);
    externalNote && (serviceData.externalNote = externalNote);
    internalNote && (serviceData.internalNote = internalNote);
    followUpDate && (serviceData.followUp = new Date(followUpDate));
    isProcedureScheduled !== null && (serviceData.isProcedureScheduled = isProcedureScheduled);
    isProcedureScheduled &&
      procedureScheduledDate &&
      (serviceData.dateProcedureScheduled = new Date(procedureScheduledDate));
    isPreActionNeeded && authCertifiedDate && (serviceData.dateAuthorized = new Date(authCertifiedDate));
    deductible && (serviceData.deductible = parseFloat(deductible));
    deductibleMET && (serviceData.deductibleMET = parseFloat(deductibleMET));
    oop && (serviceData.oop = parseFloat(oop));
    oopMet && (serviceData.oopMet = parseFloat(oopMet));
    coInsurance && (serviceData.coInsurance = coInsurance);
    other && (serviceData.other = other);
    miscCodes && miscCodes.length && (serviceData.codes = miscCodes);

    isFirstService ? createNewRequest(serviceData) : createNewService(serviceData);
  };

  const createNewRequest = async (serviceData: any) => {
    try {
      const data = {
        ...request,
        service: {
          ...serviceData
        }
      };

      const newRequest = await createRequest(data);
      closeModal(Modals.NewService);
      history.push(`request/${newRequest.id}`);
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const createNewService = async (serviceData: any) => {
    try {
      const data = {
        requestId: request.id,
        ...serviceData
      };

      await createService(data);
      closeModal(Modals.NewService);
      getRequestById(request.id);
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const renderProducts = () => {
    if (!products.length && !productsLoading)
      return (
        <MUI.Typography variant="body1" color="textSecondary" className={classes.productItem}>
          No products
        </MUI.Typography>
      );
    const sortedProducts = sortProducts(products);
    return (
      <MUI.Grid container spacing={0}>
        {sortedProducts.map(product => (
          <MUI.Grid key={product.id} item xs={3}>
            <MUI.Typography variant="body1" color="textSecondary" className={classes.productItem}>
              &#9679; {product.name}
            </MUI.Typography>
          </MUI.Grid>
        ))}
      </MUI.Grid>
    );
  };

  const onCancel = () => {
    openModal({
      modalType: Modals.Confirmation,
      modalProps: {
        onOk: handleConfirmButtonClick
      }
    });
  };

  const handleConfirmButtonClick = () => {
    closeModal(Modals.NewService);
  };

  return (
    <MUI.Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth={true}
      disableBackdropClick
    >
      <Formik
        initialValues={{
          type: undefined,
          intentions: [],
          zip: "",
          radius: radiusOptions[0],
          procedureType: "",
          isPreActionNeeded: null,
          pointOfContact: "",
          contactNumber: "",
          preActionNumber: "",
          coPayAmount: "0.00",
          referringPhysician: "",
          primaryCarePhysician: "",
          followUpDate: null,
          isCompleted: false,
          externalNote: "",
          internalNote: "",
          isProcedureScheduled: null,
          procedureScheduledDate: null,
          authCertifiedDate: null,
          deductible: "0.00",
          deductibleMET: "0.00",
          oop: "0.00",
          oopMet: "0.00",
          coInsurance: "",
          other: ""
        }}
        validationSchema={NewServiceFormSchema}
        onSubmit={handleNewService}
      >
        {newServiceForm => (
          <Form>
            <MUI.DialogContent className={classes.dialogPaper}>
              <div className={classes.row}>
                <MUI.Typography variant="h6" className={classes.dialogTitle}>
                  New Service Request
                </MUI.Typography>
              </div>
              <div className={classes.sectionRow}>
                <MUI.Grid container spacing={0}>
                  <MUI.Grid item xs={12}>
                    <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                      Products accessible by member via client group
                    </MUI.Typography>
                    {productsLoading && <MUI.LinearProgress />}
                    {renderProducts()}
                  </MUI.Grid>
                </MUI.Grid>
              </div>
              <div className={classes.sectionRow}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  Select a service
                  <span className={classes.requiredLabel}> *Required</span>
                </MUI.Typography>
                <Field
                  name="type"
                  id="type-select"
                  component={FormikReactSelect}
                  options={serviceTypes}
                  getOptionLabel={(option: ServiceTypeModel) => option.name}
                  getOptionValue={(option: ServiceTypeModel) => option.id}
                />
              </div>
              <div className={classes.sectionRow}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  What service is the member looking to receive?
                  <span className={classes.requiredLabel}> *Required</span>
                </MUI.Typography>
                <Field
                  name="intentions"
                  id="service-intentions-select"
                  component={FormikReactSelect}
                  options={serviceStartIntentions}
                  isMulti
                  sortByParam="id"
                  getOptionLabel={(option: ServiceIntentionModel) => option.name}
                  getOptionValue={(option: ServiceIntentionModel) => option.id}
                />
              </div>
              <div className={classes.row}>
                <MUI.Typography variant="h6" className={classes.sectionTitle}>
                  STAGE 1: GATHERING INFORMATION
                </MUI.Typography>
              </div>
              <TreatmentForm form={newServiceForm} />
              <div className={classes.row}>
                <MUI.Paper className={classes.paper}>
                  <div className={classes.row}>
                    <MUI.Grid container spacing={3} justify="space-between">
                      <MUI.Grid item xs={3}>
                        <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                          If necessary, follow up by
                        </MUI.Typography>
                        <Field
                          name="followUpDate"
                          component={FormikKeyboardDatePicker}
                          minDate={followUpPickerMinDate}
                          maxDate={followUpPickerMaxDate}
                          helperText={"Invalid field value"}
                        />
                      </MUI.Grid>
                    </MUI.Grid>
                  </div>
                  <div className={classes.row}>
                    <MUI.Grid container spacing={3}>
                      <MUI.Grid item xs={12}>
                        <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                          External Notes
                          <span className={classes.requiredLabel}> *Required</span>
                        </MUI.Typography>
                        <Field
                          name="externalNote"
                          component={FormikTextField}
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={2}
                          rowsMax={2}
                          InputProps={{
                            classes: {
                              root: classes.multilineRoot,
                              input: classes.textField
                            }
                          }}
                        />
                      </MUI.Grid>
                    </MUI.Grid>
                  </div>
                  <div className={classes.row}>
                    <MUI.Grid container spacing={3}>
                      <MUI.Grid item xs={12}>
                        <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                          Internal Notes
                        </MUI.Typography>
                        <Field
                          name="internalNote"
                          component={FormikTextField}
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={2}
                          rowsMax={2}
                          InputProps={{
                            classes: {
                              root: classes.multilineRoot,
                              input: classes.textField
                            }
                          }}
                        />
                      </MUI.Grid>
                    </MUI.Grid>
                  </div>
                  <div className={classes.row}>
                    <MUI.Grid container spacing={3}>
                      <div className={classes.mt}>
                        <Field
                          name="isCompleted"
                          component={FormikSwitch}
                          label="Stage Completed?"
                          labelPlacement="start"
                        />
                      </div>
                    </MUI.Grid>
                  </div>
                </MUI.Paper>
              </div>
            </MUI.DialogContent>
            <MUI.DialogActions>
              <Button id="cancel-service-button" onClick={onCancel} color="primary">
                Cancel
              </Button>
              <div className={classes.progressWrapper}>
                <Button
                  id="submit-service-button"
                  type="submit"
                  variant={newServiceForm.isValid ? "contained" : "text"}
                  color="primary"
                  autoFocus
                  disabled={!newServiceForm.isValid || isFormSubmitting}
                >
                  {isFirstService ? "Save and Continue" : "Save"}
                </Button>
                {isFormSubmitting && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </MUI.DialogActions>
          </Form>
        )}
      </Formik>
    </MUI.Dialog>
  );
}

export default withRouter(NewServiceModal);
