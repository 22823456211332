import React, { useState, useEffect, useRef } from "react";
import { MUI, Button, SideIconButton } from "@amps/material-ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getYupMinDate, getYupMaxDate } from "utils/dateTimeUtils";
import ServiceModel from "models/service.model";
import RequestModel from "models/request.model";
import MiscCodeModel from "models/miscCode.model";
import useStyles from "components/RequestPage/styles/TreatmentPanel.style";
import TreatmentForm, { RadiusOption, radiusOptions } from "components/shared/TreatmentForm";
import { useStoreActions } from "redux/reducers/hooks";
import { Check, KeyboardArrowDown, KeyboardArrowUp, Edit } from "@material-ui/icons";

interface TreatmentFormValues {
  zip?: string;
  radius: RadiusOption;
  procedureType?: string;
  miscCodes?: MiscCodeModel[];
  isPreActionNeeded?: boolean | null;
  pointOfContact?: string;
  contactNumber?: string;
  preActionNumber?: string;
  coPayAmount?: string;
  referringPhysician?: string;
  primaryCarePhysician?: string;
  isProcedureScheduled?: boolean | null;
  procedureScheduledDate?: Date | string | null;
  authCertifiedDate?: Date | string | null;
  deductible?: string;
  deductibleMET?: string;
  oop?: string;
  oopMet?: string;
  coInsurance?: string;
  other?: string;
}

type Props = {
  service: ServiceModel;
  request: RequestModel;
};

export function TreatmentPanel(props: Props) {
  const { service, request } = props;
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const getRequestById = useStoreActions(actions => actions.request.getRequestById);
  const updateServiceDetails = useStoreActions(actions => actions.service.updateServiceDetails);

  const treatmentFormRef = useRef(null);
  const minYupDate = getYupMinDate();
  const maxYupDate = getYupMaxDate();
  const TreatmentFormSchema = Yup.object().shape({
    zip: Yup.string(),
    radius: Yup.object(),
    procedureType: Yup.string().max(50, "Must be 50 characters or less"),
    miscCodes: Yup.array().ensure(),
    isPreActionNeeded: Yup.boolean().nullable(),
    preActionNumber: Yup.string().max(25, "Must be 25 characters or less"),
    coPayAmount: Yup.string(),
    referringPhysician: Yup.string().max(200, "Must be 200 characters or less"),
    primaryCarePhysician: Yup.string().max(200, "Must be 200 characters or less"),
    isProcedureScheduled: Yup.boolean().nullable(),
    procedureScheduledDate: Yup.date()
      .nullable()
      .typeError("Invalid field value")
      .min(minYupDate, "Invalid field value")
      .max(maxYupDate, "Invalid field value"),
    authCertifiedDate: Yup.date()
      .nullable()
      .typeError("Invalid field value")
      .min(minYupDate, "Invalid field value")
      .max(maxYupDate, "Invalid field value"),
    pointOfContact: Yup.string().max(50, "Must be 50 characters or less"),
    contactNumber: Yup.string().max(50, "Must be 50 characters or less"),
    deductible: Yup.string(),
    deductibleMET: Yup.string(),
    oop: Yup.string(),
    oopMet: Yup.string(),
    coInsurance: Yup.string().max(100, "Must be 100 characters or less"),
    other: Yup.string().max(100, "Must be 100 characters or less")
  });

  useEffect(() => {
    if (service.closingReason) {
      if (treatmentFormRef.current) (treatmentFormRef.current as any).resetForm();
      setIsEditing(false);
    }
  }, [service.closingReason]);

  const handleEditMode = (event: any) => {
    event.stopPropagation();

    setIsEditing(true);
  };

  const handleCloseEditMode = (event: any, treatmentForm: any) => {
    event.stopPropagation();

    treatmentForm.resetForm();
    setIsEditing(false);
  };

  const handleSave = (event: any, treatmentForm: any) => {
    event.stopPropagation();

    treatmentForm.submitForm();
  };

  const handleSubmit = async (values: TreatmentFormValues, form: any) => {
    setIsFormSubmitting(true);

    try {
      const {
        zip,
        radius,
        coPayAmount,
        preActionNumber,
        isPreActionNeeded,
        primaryCarePhysician,
        referringPhysician,
        procedureType,
        isProcedureScheduled,
        procedureScheduledDate,
        authCertifiedDate,
        pointOfContact,
        contactNumber,
        deductible,
        deductibleMET,
        oop,
        oopMet,
        coInsurance,
        other,
        miscCodes
      } = values;

      const data: any = {
        procedureType: procedureType || null,
        zipCode: zip || null,
        searchRadius: parseInt(radius.value),
        copayAmount: coPayAmount ? parseFloat(coPayAmount) : null,
        preCertNum: isPreActionNeeded && preActionNumber ? preActionNumber : null,
        pointOfContact: isPreActionNeeded && pointOfContact ? pointOfContact : null,
        contactNumber: isPreActionNeeded && contactNumber ? contactNumber : null,
        preCertNeeded: isPreActionNeeded,
        primaryPhysician: primaryCarePhysician || null,
        referringPhysician: referringPhysician || null,
        codes: miscCodes && miscCodes.length ? miscCodes : null,
        isProcedureScheduled,
        dateProcedureScheduled:
          isProcedureScheduled && procedureScheduledDate ? new Date(procedureScheduledDate) : null,
        dateAuthorized: isPreActionNeeded && authCertifiedDate ? new Date(authCertifiedDate) : null,
        deductible: deductible ? parseFloat(deductible) : null,
        deductibleMET: deductibleMET ? parseFloat(deductibleMET) : null,
        oop: oop ? parseFloat(oop) : null,
        oopMet: oopMet ? parseFloat(oopMet) : null,
        coInsurance: coInsurance || null,
        other: other || null
      };

      await updateServiceDetails({ serviceId: service.id, data });
      await getRequestById(request.id);

      setIsEditing(false);
      // applying initial values
      form.resetForm();
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const renderPanelButtons = (treatmentForm: any) => {
    if (!isExpanded || !isEditing) return null;

    return (
      <>
        <Button
          name="details-cancel-button"
          color="primary"
          onClick={(event: any) => handleCloseEditMode(event, treatmentForm)}
        >
          Cancel
        </Button>
        <div className={classes.progressWrapper}>
          <SideIconButton
            name="details-save-button"
            variant="contained"
            color="primary"
            Icon={Check}
            align="left"
            className={classes.ml}
            disabled={!treatmentForm.isValid || isFormSubmitting}
            onClick={(event: any) => handleSave(event, treatmentForm)}
          >
            Save
          </SideIconButton>
          {isFormSubmitting && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </>
    );
  };

  const getInitialValues = () => {
    const {
      zipCode,
      searchRadius,
      procedureType,
      preCertNeeded,
      preCertNum,
      copayAmount,
      referringPhysician,
      primaryPhysician,
      codes,
      dateProcedureScheduled,
      dateAuthorized,
      pointOfContact,
      contactNumber,
      isProcedureScheduled,
      deductible,
      deductibleMET,
      oop,
      oopMet,
      coInsurance,
      other
    } = service;

    const radius = radiusOptions.find(option => option.value === searchRadius) || radiusOptions[0];

    const initialValues = {
      zip: zipCode || "",
      radius,
      procedureType: procedureType || "",
      miscCodes: codes || [],
      isPreActionNeeded: preCertNeeded,
      preActionNumber: preCertNum || "",
      pointOfContact: pointOfContact || "",
      contactNumber: contactNumber || "",
      coPayAmount: (copayAmount && parseFloat(copayAmount).toFixed(2)) || "0.00",
      referringPhysician: referringPhysician || "",
      primaryCarePhysician: primaryPhysician || "",
      isProcedureScheduled: isProcedureScheduled,
      procedureScheduledDate: dateProcedureScheduled || null,
      authCertifiedDate: dateAuthorized || null,
      deductible: (deductible && parseFloat(deductible).toFixed(2)) || "0.00",
      deductibleMET: (deductibleMET && parseFloat(deductibleMET).toFixed(2)) || "0.00",
      oop: (oop && parseFloat(oop).toFixed(2)) || "0.00",
      oopMet: (oopMet && parseFloat(oopMet).toFixed(2)) || "0.00",
      coInsurance: coInsurance || "",
      other: other || ""
    };

    return initialValues;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues()}
      validationSchema={TreatmentFormSchema}
      onSubmit={handleSubmit}
      isInitialValid={true}
      ref={treatmentFormRef}
    >
      {treatmentForm => (
        <Form className={classes.form}>
          <MUI.Accordion
            expanded={isExpanded}
            onChange={(event, expanded) => setIsExpanded(expanded)}
            className={classes.panel}
          >
            <MUI.AccordionSummary expandIcon={null}>
              <MUI.Grid container spacing={0} alignItems="center">
                <MUI.Grid item xs={9}>
                  <SideIconButton
                    name="open-panel-button"
                    variant="text"
                    Icon={isExpanded ? KeyboardArrowUp : KeyboardArrowDown}
                    align="left"
                  >
                    View Treatment and Details
                  </SideIconButton>
                </MUI.Grid>
                <MUI.Grid item xs={3}>
                  <MUI.Grid container spacing={0} justify="flex-end" alignItems="center">
                    {isExpanded && !isEditing && !service.closingReason && (
                      <SideIconButton id="edit-button" variant="text" Icon={Edit} align="left" onClick={handleEditMode}>
                        Edit
                      </SideIconButton>
                    )}
                    {renderPanelButtons(treatmentForm)}
                  </MUI.Grid>
                </MUI.Grid>
              </MUI.Grid>
            </MUI.AccordionSummary>
            <MUI.Divider variant="middle" />
            <MUI.AccordionDetails className={classes.panelDetails}>
              <TreatmentForm shortLabels={true} viewMode={!isEditing} form={treatmentForm} />
              <MUI.Grid container spacing={0} justify="flex-end" alignItems="center">
                {renderPanelButtons(treatmentForm)}
              </MUI.Grid>
            </MUI.AccordionDetails>
          </MUI.Accordion>
        </Form>
      )}
    </Formik>
  );
}

export default TreatmentPanel;
