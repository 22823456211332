import React from "react";
import NumberFormat from "react-number-format";
import { MUI } from "@amps/material-ui";
import clsx from "clsx";

const useStyles = MUI.makeStyles(theme => ({
  moneyInput: {
    textAlign: "right"
  }
}));

const getMoneyValue = (values: any) => {
  let value = undefined;

  if (values.value) {
    const decimalPart = values.value.split(".")[1] || "";
    const exponent = Math.pow(10, decimalPart.length);

    value = (values.floatValue * exponent) / 100;
  }

  return value;
};

const getValue = (values: any, fieldType: string, returnedValue: string) => {
  if (fieldType === "money") {
    const moneyValue = getMoneyValue(values);

    return moneyValue !== undefined ? moneyValue.toFixed(2) : undefined;
  }

  return values[returnedValue];
};

export default function NumberFormatField(props: any) {
  // returnedValue can be 'value', 'floatValue' or 'formattedValue'. 'value' as string by default
  // type can be 'regular' or 'money'. 'regular' by default
  const { inputRef, name, onChange, className, fieldType = "regular", returnedValue = "value", ...other } = props;
  const classes = useStyles();

  return (
    <NumberFormat
      {...other}
      name={name}
      className={clsx(className, fieldType === "money" && classes.moneyInput)}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        const value = getValue(values, fieldType, returnedValue);

        onChange({
          target: {
            name,
            value
          }
        });
      }}
    />
  );
}

export { getMoneyValue };
