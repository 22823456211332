import React, { useState } from "react";
import { SideIconButton, MUI } from "@amps/material-ui";
import { ExportToCsv } from "export-to-csv";
import { getDateString, getFormattedDate } from "utils/dateTimeUtils";
import { getUserFullName } from "utils/userUtils";
import { RequestsSearchCriteriaType } from "types";
import useStyles from "components/DashboardPage/styles/DownloadReport.style";
import RequestModel from "models/request.model";
import { useStoreActions } from "redux/reducers/hooks";
import { CloudDownload } from "@material-ui/icons";

interface DownloadRequestModel {
  requestId: string;
  status: string;
  stage: string;
  followUp: string;
  member: string;
  updatedOn: string;
  updatedBy: string;
}

type ClosedRequestsFilters = RequestsSearchCriteriaType;

type Props = {
  openRequests?: RequestModel[];
  filters?: ClosedRequestsFilters;
};

export function DownloadReport(props: Props) {
  const classes = useStyles();
  const { openRequests, filters } = props;
  const [isLoading, setIsLoading] = useState(false);
  const getAllRequests = useStoreActions(actions => actions.request.getAllRequests);

  const getFormattedRequests = (requests: RequestModel[]) => {
    if (!requests || !requests.length) return [{}];

    return requests.map((request: RequestModel) => ({
      requestId: request.id,
      status:
        request.additionalStatus && request.requestStatus
          ? `${request.requestStatus.name} - ${request.additionalStatus}`
          : request.requestStatus.name || "",
      stage: request.currentStage ? `${request.currentStage.id}: ${request.currentStage.name}` : "",
      followUp: request.nearestFollowUp ? getDateString(request.nearestFollowUp) : "",
      member: request.member ? `${request.member.lastName}, ${request.member.firstName}` : "",
      updatedOn: getDateString(request.updatedOn),
      updatedBy: getUserFullName(request.updatedBy)
    }));
  };

  const handleDownload = async () => {
    let closedRequests;

    if (filters) {
      setIsLoading(true);

      const {
        clientId,
        clientGroupId,
        members,
        createdByAgents,
        updatedByAgents,
        requestCreatedStart,
        requestCreatedEnd,
        requestUpdatedEnd,
        requestUpdatedStart,
        sortBy,
        sortDirection
      } = filters;

      const searchData: RequestsSearchCriteriaType = {
        clientId,
        clientGroupId,
        members,
        createdByAgents,
        updatedByAgents,
        requestCreatedStart,
        requestCreatedEnd,
        requestUpdatedEnd,
        requestUpdatedStart,
        openOnly: false,
        sortBy,
        sortDirection
      };

      try {
        closedRequests = await getAllRequests(searchData);
      } finally {
        setIsLoading(false);
      }
    }

    const data: DownloadRequestModel[] | {}[] = getFormattedRequests(
      closedRequests && closedRequests.data ? closedRequests.data : openRequests
    );

    const options = {
      filename: `Care_Navigator_Export_${getFormattedDate(new Date(), "MMDDYYYY")}`,
      fieldSeparator: ",",
      showLabels: true,
      headers: [
        "Request ID",
        "Status",
        "Current Stage",
        "Followup Date",
        "Member Name",
        "Request Updated",
        "Updated By"
      ]
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  };

  return (
    <div className={classes.progressWrapper}>
      <SideIconButton
        id="download-report-button"
        variant="contained"
        disabled={isLoading}
        Icon={CloudDownload}
        align="left"
        className={classes.downloadButton}
        onClick={handleDownload}
      >
        Download CSV
      </SideIconButton>
      {isLoading && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
}

export default DownloadReport;
