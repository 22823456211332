import ServiceModel from "models/service.model";
import InteractionModel from "models/interaction.model";
import ServiceIntentionsModel from "models/serviceIntention.model";

import sortBy from "lodash/sortBy";

const getInteractionWithNonClosedSCA = (service: ServiceModel) => {
  return service.interactions.find((interaction: InteractionModel) => {
    const { scaRequest } = interaction;

    return scaRequest && scaRequest.isOpen;
  });
};

const getServiceStartIntentions = (service: ServiceModel) => {
  const { startIntentions } = service;

  return sortBy(startIntentions, "id")
    .map((intention: ServiceIntentionsModel) => intention.name)
    .join(", ");
};

export { getInteractionWithNonClosedSCA, getServiceStartIntentions };
