import React from "react";
import Button from "@material-ui/core/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import useStyles from "components/LoginPage/styles/LoginPage.style";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormikMFA from "containers/FormikMFA";
import FormikCheckbox from "containers/FormikCheckbox";

export type LoginMFAFormValues = {
  mfaCode: string;
  trusted:boolean;
};

const LoginFormSchema = Yup.object().shape({
  mfaCode: Yup.string()
    .required("Required")
    .test("length", "Invalid MFA Code", val => {
      return val ? val.length === 6 : false;
    })
});

export type LoginCodeFormProps = {
  handleMFALogin: (values: LoginMFAFormValues) => Promise<void>;
  handleResendToken: () => void;
  isFormSubmitting: boolean;
};

export function LoginCodeForm(props: LoginCodeFormProps) {
  const { handleMFALogin, handleResendToken, isFormSubmitting } = props;
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        mfaCode: "",
        trusted:false
      }}
      validationSchema={LoginFormSchema}
      onSubmit={handleMFALogin}
    >
      {mfaForm => (
        <Form className={classes.form}>
          <div className={classes.mfaField}>
            <Field
              name="mfaCode"
              component={FormikMFA}
              textFieldProps={{ autoComplete: "off", autoCorrect: "new-token" }}
            />
          </div>

          <div className="note">
            We are enhancing the security on your account and have emailed a secure code to you, please enter it here.
          </div>
          <Grid container justify="space-between" alignItems="center">

            <div style={{paddingTop:"20px"}}>
              <Field
                name='trusted'
                id='trusted'
                label='Trust this computer'
                className='form-control'
                type='checkbox'
                component={FormikCheckbox}
                autoFocus='autoFocus'
              />
            </div>
          </Grid>
          <FormGroup className={classes.buttonContainer} row={true}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Button color="primary" disableElevation={true} onClick={handleResendToken} size="small" tabIndex={-1}>
                  Resend Code
                </Button>
              </Grid>
              <Grid item>
                <div className={classes.progressWrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!mfaForm.dirty || !mfaForm.isValid || isFormSubmitting}
                    className={classes.submit}
                  >
                    Submit
                  </Button>
                  {isFormSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </Grid>
            </Grid>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
}

export default LoginCodeForm;
