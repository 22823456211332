import React, { useState } from "react";
import { MUI, InfiniteLoadingTable } from "@amps/material-ui";
import useStyles from "components/ReportsPage/styles/ReportsTable.style";
import { defaultOrder, headerHeight, rowHeight, reportTableColumns } from "components/ReportsPage/reportsTableUtils";
import { ReportsSearchCriteriaType, ReportResponse } from "types";
import { useStoreActions } from "redux/reducers/hooks";

interface ReportsTableProps {
  isLoading: boolean;
  searchCriteria: ReportsSearchCriteriaType;
  reportData: ReportResponse;
}

export function ReportsTable(props: ReportsTableProps) {
  const { isLoading, searchCriteria, reportData } = props;
  const [isSearching, setIsSearching] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { getMoreReports, fetchReport, setSearchCriteria } = useStoreActions(actions => actions.report);
  const classes = useStyles();

  const wrapperHeight =
    reportData && reportData.data.length
      ? `${rowHeight * reportData.data.length + headerHeight + 2}px`
      : `${headerHeight}px`;

  const rowCount =
    reportData && searchCriteria && searchCriteria.take && reportData.data.length
      ? Math.min(reportData.data.length + searchCriteria.take, reportData.totalCount)
      : 0;

  const getMoreRows = async (startIndex: number, stopIndex: number) => {
    if (isSearching || !reportData) return;

    setIsSearching(true);
    try {
      await getMoreReports({
        ...searchCriteria,
        skip: startIndex,
        take: stopIndex - startIndex + 1
      });
    } finally {
      setIsSearching(false);
    }
  };

  const handleReportsSort = async (sortParams: any) => {
    if (!reportData) return;

    let { sortBy, sortDirection } = sortParams;

    // Remove sorting on 3rd click
    if (searchCriteria.sortBy !== sortBy) {
      sortDirection = "ASC";
    }

    setSearchCriteria({
      ...searchCriteria,
      sortBy,
      sortDirection
    });
  };

  const handleRowClick = async (row: any) => {
    const { rowData: report } = row;

    try {
      setIsDownloading(true);
      await fetchReport({ fileId: report.id, name: report.originalName });
    } finally {
      setIsDownloading(false);
    }
  };

  const hasNoReports = (!reportData || !reportData.data.length) && !isLoading;
  const hasReports = reportData && !!reportData.data.length && !isLoading;

  return (
    <div className={classes.reportsTableContainer}>
      {(isLoading || isDownloading) && <MUI.LinearProgress />}

      {hasNoReports && (
        <MUI.Paper id="no-reports-message">
          <MUI.Typography className={classes.noOptions}>No reports</MUI.Typography>
        </MUI.Paper>
      )}

      {hasReports && (
        <MUI.Paper className={classes.paper} style={{ height: wrapperHeight }}>
          <InfiniteLoadingTable
            isFilteringToolbarNeeded={false}
            onRowClick={handleRowClick}
            rowCount={rowCount}
            data={reportData.data}
            hasMoreRows={true}
            onDataRequired={getMoreRows}
            classes={{
              tableRow: classes.tableRow,
              rootContainer: classes.rootContainer
            }}
            tableProps={{
              sort: handleReportsSort,
              sortBy: searchCriteria.sortBy,
              sortDirection: searchCriteria.sortDirection,
              defaultOrder,
              cellClass: classes.tableCell,
              headerClass: classes.tableHead,
              headerHeight,
              rowHeight,
              gridStyle: { outline: "none" },
              headerStyle: { outline: "none" },
              rowStyle: { outline: "none" }
            }}
            infiniteLoaderProps={{
              minimumBatchSize: rowCount,
              threshold: 0
            }}
            columns={reportTableColumns}
          />
        </MUI.Paper>
      )}
    </div>
  );
}

export default ReportsTable;
