import React, { useState } from "react";
import { MUI, SideIconButton } from "@amps/material-ui";
import useStyles from "components/DashboardPage/styles/RequestsToolbar.style";
import ClosedRequestsForm from "components/DashboardPage/ClosedRequestsForm";
import { RequestsSearchCriteriaType } from "types";
import { Add, Search } from "@material-ui/icons";
import { useStoreActions } from "redux/reducers/hooks";
import { Modals } from "utils/modalUtils";

type Props = {
  isLoading: boolean;
  onlyMyRequests: boolean;
  onlyClosedRequests: boolean;
  onMyRequestsChange: (event: object, checked: boolean) => void;
  onClosedRequestsChange: (checked: boolean) => void;
  onSearchTextChange: (searchText: string) => void;
  onSearchClosedRequests: (newSearchCriteria: RequestsSearchCriteriaType) => Promise<void>;
};

function RequestsToolbar(props: Props) {
  const classes = useStyles();
  const {
    isLoading,
    onlyMyRequests,
    onlyClosedRequests,
    onMyRequestsChange,
    onClosedRequestsChange,
    onSearchTextChange,
    onSearchClosedRequests
  } = props;
  const [searchFilter, setSearchFilter] = useState("");
  const { openModal } = useStoreActions(actions => actions.modal);

  return (
    <div className={classes.toolbarContainer}>
      <div className={classes.toolbarButtons}>
        <div className={classes.rowItem}>
          <SideIconButton
            id="new-request-button"
            variant="contained"
            color="primary"
            Icon={Add}
            align="left"
            onClick={() => {
              openModal({ modalType: Modals.NewRequest });
            }}
          >
            New Request
          </SideIconButton>
          <div id="download-requests" className={classes.downloadRequestsRoot} />
        </div>
        <div className={classes.rowItem}>
          <MUI.FormControlLabel
            control={
              <MUI.Switch
                disabled={isLoading}
                name="closed-requests-control"
                color="primary"
                value={onlyClosedRequests}
                onChange={(event: object, checked: boolean) => {
                  onClosedRequestsChange(checked);
                  setSearchFilter("");
                }}
              />
            }
            label="Show Closed Requests"
            labelPlacement="start"
          />
        </div>
        {!onlyClosedRequests && (
          <>
            <div className={classes.rowItem}>
              <MUI.FormControlLabel
                control={
                  <MUI.Switch
                    disabled={isLoading}
                    name="my-requests-control"
                    color="primary"
                    value={onlyMyRequests}
                    onChange={onMyRequestsChange}
                  />
                }
                label="Show My Requests Only"
                labelPlacement="start"
              />
            </div>
            <div className={`${classes.rowItem}--right`}>
              <MUI.TextField
                disabled={isLoading}
                variant="outlined"
                fullWidth
                placeholder="Search"
                value={searchFilter}
                onChange={event => {
                  setSearchFilter(event.target.value);
                  onSearchTextChange(event.target.value);
                }}
                InputProps={{
                  classes: {
                    input: classes.textField
                  },
                  endAdornment: (
                    <MUI.InputAdornment position="start">
                      <Search />
                    </MUI.InputAdornment>
                  )
                }}
              />
            </div>
          </>
        )}
      </div>
      {onlyClosedRequests && (
        <div className={classes.toolbarFilters}>
          <ClosedRequestsForm onSearchClosedRequests={onSearchClosedRequests} />
        </div>
      )}
    </div>
  );
}

export default RequestsToolbar;
