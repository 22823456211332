import React from "react";
import { Button } from "@amps/material-ui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikTextField from "containers/FormikTextField";
import useStyles from "components/LoginPage/styles/LoginPage.style";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

export type LoginUserFormValues = {
  code: string;
  password: string;
};

export type LoginUserFormProps = {
  handleLogin: (values: LoginUserFormValues) => Promise<void>;
  isFormSubmitting: boolean;
};

const LoginFormSchema = Yup.object().shape({
  code: Yup.string()
    .required("Required")
    .min(2),
  password: Yup.string()
    .required("Required")
    .min(2)
});

export function LoginUserForm(props: LoginUserFormProps) {
  const { handleLogin, isFormSubmitting } = props;
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        code: "",
        password: ""
      }}
      validationSchema={LoginFormSchema}
      onSubmit={handleLogin}
    >
      {userForm => (
        <Form className={classes.form}>
          <Field
            name="code"
            component={FormikTextField}
            showHelperText={false}
            label="Username"
            variant="standard"
            margin="normal"
            placeholder="Enter your username"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />

          <Field
            name="password"
            type="password"
            component={FormikTextField}
            showHelperText={false}
            label="Password"
            variant="standard"
            margin="normal"
            placeholder="Enter your password"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <FormGroup className={classes.buttonContainer} row={true}>
            <Grid container justify="space-between">
              <Grid item />
              <Grid item>
                <div className={classes.progressWrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!userForm.dirty || !userForm.isValid || isFormSubmitting}
                    className={classes.submit}
                  >
                    Login
                  </Button>
                  {isFormSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </Grid>
            </Grid>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
}

export default LoginUserForm;
