import axios from "axios";
import ProductModel from "models/product.model";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { ProductReducerModel } from "types";

const setProducts: Action<ProductReducerModel, ProductModel[]> = action(
  (state, products) => {
    state.products = products;
  }
);

const getProductsByGroupId: Thunk<ProductReducerModel, string> = thunk(
  async (actions, groupId) => {
    const response: any = await axios.get(`/client-group/${groupId}/products`);

    return response;
  }
);

const ProductReducer: ProductReducerModel = {
  products: [],
  setProducts,
  getProductsByGroupId
};

export default ProductReducer;
