import React, { useState, useEffect } from "react";
import { MUI, Button } from "@amps/material-ui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { getPickerMinDate, getPickerMaxDate, isDateLess } from "utils/dateTimeUtils";
import { formatWithPattern } from "utils/numFormatUtils";
import useStyles from "components/RequestPage/styles/ProviderPushbackModal.style";
import PushbackType from "models/pushbackType.model";
import PatientStatus from "models/patientStatus.model";
import RequestReason from "models/requestReason.model";
import InteractionModel from "models/interaction.model";
import RequestModel from "models/request.model";
import FormikKeyboardDatePicker from "containers/FormikKeyboardDatePicker";
import NumberFormatField, { getMoneyValue } from "containers/NumberFormatField";
import FormikTextField from "containers/FormikTextField";
import FormikReactSelect from "containers/FormikReactSelect";
import FormikToggleButtonGroup from "containers/FormikToggleButtonGroup";
import TextMaskField from "containers/TextMaskField";
import ProviderPushbackMainTypes from "utils/constants/ProviderPushbackMainTypes";
import ProviderPushbackSubTypes from "utils/constants/ProviderPushbackSubTypes";
import { useStoreActions } from "redux/reducers/hooks";
import { Modals } from "utils/modalUtils";

interface ProviderPushbackModalProps {
  open: boolean;
  onClose: Function;
  onNewInteractionClose: Function;
  interaction: InteractionModel;
  request: RequestModel;
}

const getValidPhoneLength = (phone: string) => {
  if (!phone) return true;

  const validLength = phone[0] === "1" ? 11 : 10;

  return phone.length === validLength;
};

const ProviderPushbackFormSchema = Yup.object().shape({
  pushbackMainType: Yup.string().required("Required"),
  pushbackSubType: Yup.object()
    .nullable()
    .required("Required"),
  amount: Yup.string().when("pushbackSubType", {
    is: (type: any) => type && type.name === ProviderPushbackSubTypes.REQUIRES_PAYMENT,
    then: Yup.string().required("Required")
  }),
  requestReason: Yup.object()
    .nullable()
    .required("Required"),
  pushbackOccurredDate: Yup.date()
    .nullable()
    .required("Required")
    .typeError("Invalid field value")
    .test(
      "isValidOccurredDate",
      "Pushback Reported date cannot be a date prior to date when pushback occurred",
      function(pushbackOccurredDate: string) {
        return isDateLess(pushbackOccurredDate, this.parent.pushbackReportedDate);
      }
    ),
  pushbackReportedDate: Yup.date()
    .nullable()
    .required("Required")
    .typeError("Invalid field value")
    .test(
      "isValidReportedDate",
      "Pushback Reported date cannot be a date prior to date when pushback occurred",
      function(pushbackReportedDate: string) {
        return isDateLess(this.parent.pushbackOccurredDate, pushbackReportedDate);
      }
    ),
  pushbackReportedName: Yup.string()
    .required("Required")
    .max(2000, "Must be 2000 characters or less"),
  pushbackReportedPhoneNumber: Yup.string().test("isValidPhone", "Invalid Phone Number", getValidPhoneLength),
  hasOutreach: Yup.boolean()
    .nullable()
    .required("Required"),
  tpaLevelSpeakerName: Yup.string()
    .max(2000, "Must be 2000 characters or less")
    .when("hasOutreach", {
      is: true,
      then: Yup.string().required("Required")
    }),
  tpaLevelSpeakerPhoneNumber: Yup.string().when("hasOutreach", {
    is: true,
    then: Yup.string()
      .required("Required")
      .test("isValidPhone", "Invalid Phone Number", getValidPhoneLength)
  }),
  whoDiscussPushbackName: Yup.string()
    .max(2000, "Must be 2000 characters or less")
    .when("hasOutreach", {
      is: true,
      then: Yup.string().required("Required")
    }),
  whoDiscussPushbackPhoneNumber: Yup.string().when("hasOutreach", {
    is: true,
    then: Yup.string()
      .required("Required")
      .test("isValidPhone", "Invalid Phone Number", getValidPhoneLength)
  }),
  ampsContactName: Yup.string()
    .max(2000, "Must be 2000 characters or less")
    .when("hasOutreach", {
      is: true,
      then: Yup.string().required("Required")
    }),
  ampsContactPhoneNumber: Yup.string().when("hasOutreach", {
    is: true,
    then: Yup.string()
      .required("Required")
      .test("isValidPhone", "Invalid Phone Number", getValidPhoneLength)
  }),
  patientStatuses: Yup.object()
    .nullable()
    .when("pushbackMainType", {
      is: (val: string) => val === ProviderPushbackMainTypes.PRE_CARE,
      then: Yup.object().required("Required")
    }),
  providerRelationsNotes: Yup.string().max(2000, "Must be 2000 characters or less")
});

interface ProviderPushbackFormValues {
  pushbackMainType: string;
  pushbackSubType: PushbackType | null;
  amount: string;
  requestReason: RequestReason | null;
  pushbackOccurredDate: Date | string | null;
  pushbackReportedDate: Date | string | null;
  pushbackReportedName: string;
  pushbackReportedPhoneNumber?: string;
  hasOutreach: boolean | null;
  tpaLevelSpeakerName: string;
  tpaLevelSpeakerPhoneNumber: string;
  whoDiscussPushbackPhoneNumber: string;
  whoDiscussPushbackName: string;
  ampsContactName: string;
  ampsContactPhoneNumber: string;
  patientStatuses: PatientStatus | null;
  providerRelationsNotes: string;
}

const defaultPhoneFormat = "(###) ###-####";
const extraPhoneFormat = "#-(###) ###-####";
const alphaNumericMask = /[a-zA-Z0-9\s]/;

export function ProviderPushbackModal(props: ProviderPushbackModalProps) {
  const classes = useStyles();
  const { open, onClose, request, interaction, onNewInteractionClose } = props;

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [pushbackSubTypes, setPushbackSubTypes] = useState([]);
  const [requestReasons, setRequestreasons] = useState([]);
  const [patientStatuses, setPatientStatuses] = useState([]);
  const pickerMinDate = getPickerMinDate();
  const pickerMaxDate = getPickerMaxDate(new Date());
  const {
    request: { getRequestById },
    interaction: { createInteraction },
    provider: { getPushbackSubTypes, getRequestReasons, getPatientStatuses },
    modal: { openModal }
  } = useStoreActions(actions => actions);

  useEffect(() => {
    async function fetchData() {
      const requestReasons = await getRequestReasons();
      const patientStatuses = await getPatientStatuses();

      setRequestreasons(requestReasons);
      setPatientStatuses(patientStatuses);
    }

    fetchData();
  }, [getRequestReasons, getPatientStatuses]);

  const handleProviderPushback = async (values: ProviderPushbackFormValues) => {
    setIsFormSubmitting(true);

    try {
      const {
        pushbackSubType,
        amount,
        requestReason,
        pushbackOccurredDate,
        pushbackReportedDate,
        hasOutreach,
        pushbackReportedName,
        pushbackReportedPhoneNumber,
        tpaLevelSpeakerName,
        tpaLevelSpeakerPhoneNumber,
        whoDiscussPushbackPhoneNumber,
        whoDiscussPushbackName,
        ampsContactName,
        ampsContactPhoneNumber,
        patientStatuses,
        providerRelationsNotes
      } = values;

      const pushbackData: any = {};

      pushbackData.memberId = parseInt(request.memberId);
      pushbackSubType && (pushbackData.pushbackTypeId = parseInt(pushbackSubType.id));
      pushbackSubType &&
        parseInt(pushbackSubType.id) === 1 &&
        amount &&
        (pushbackData.pushbackAmount = parseFloat(amount));
      requestReason && (pushbackData.requestReasonId = parseInt(requestReason.id));
      pushbackOccurredDate && (pushbackData.pushbackOccurred = new Date(pushbackOccurredDate));
      pushbackReportedDate && (pushbackData.pushbackReported = new Date(pushbackReportedDate));
      pushbackData.tpaCompleted = hasOutreach;
      pushbackReportedName && (pushbackData.originalReporterName = pushbackReportedName);
      pushbackReportedPhoneNumber && (pushbackData.originalReporterPhoneNumber = pushbackReportedPhoneNumber);
      tpaLevelSpeakerName && (pushbackData.tpaName = tpaLevelSpeakerName);
      tpaLevelSpeakerPhoneNumber && (pushbackData.tpaPhoneNumber = tpaLevelSpeakerPhoneNumber);
      whoDiscussPushbackName && (pushbackData.providerName = whoDiscussPushbackName);
      whoDiscussPushbackPhoneNumber && (pushbackData.providerPhoneNumber = whoDiscussPushbackPhoneNumber);
      ampsContactName && (pushbackData.whoContactedName = ampsContactName);
      ampsContactPhoneNumber && (pushbackData.whoContactedPhoneNumber = ampsContactPhoneNumber);
      patientStatuses && (pushbackData.memberStatusId = parseInt(patientStatuses.id));
      providerRelationsNotes && (pushbackData.additionalDetails = providerRelationsNotes);

      const data: any = {
        ...interaction,
        pushbackDetails: {
          ...pushbackData
        }
      };

      await createInteraction(data);
      await getRequestById(request.id);

      onClose();
      onNewInteractionClose();
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const handlePushbackMainType = async (newTypeId: string, form: any) => {
    form.setFieldValue("pushbackSubType", null);
    form.setFieldValue("patientStatuses", null);

    if (!newTypeId) {
      setPushbackSubTypes([]);
      return;
    }

    const pushbackSubTypes = await getPushbackSubTypes(newTypeId);
    setPushbackSubTypes(pushbackSubTypes);
  };

  const handleCloseConfirmationModal = () => {
    onClose();
  };

  const isAmountAllowed = (values: any) => {
    const value = getMoneyValue(values);

    // maximum is 1 billion
    return !value || (value < 1000000000 && value > -1000000000);
  };

  const isAmountRequired = (type: any) => {
    if (!type) return;

    return type.name === ProviderPushbackSubTypes.REQUIRES_PAYMENT;
  };

  const getPhoneFormat = (value: string) => {
    const format = value[0] === "1" ? extraPhoneFormat : defaultPhoneFormat;

    return formatWithPattern(value, format);
  };

  const onCancelClick = () => {
    openModal({
      modalType: Modals.Confirmation,
      modalProps: { onOk: handleCloseConfirmationModal }
    });
  };

  return (
    <>
      <MUI.Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth={true}
        disableBackdropClick
      >
        <Formik
          initialValues={{
            pushbackMainType: "",
            pushbackSubType: null,
            amount: "0.00",
            requestReason: null,
            pushbackOccurredDate: null,
            pushbackReportedDate: null,
            hasOutreach: null,
            pushbackReportedName: "",
            pushbackReportedPhoneNumber: "",
            tpaLevelSpeakerName: "",
            tpaLevelSpeakerPhoneNumber: "",
            whoDiscussPushbackName: "",
            whoDiscussPushbackPhoneNumber: "",
            ampsContactName: "",
            ampsContactPhoneNumber: "",
            patientStatuses: null,
            providerRelationsNotes: ""
          }}
          validationSchema={ProviderPushbackFormSchema}
          onSubmit={handleProviderPushback}
        >
          {providerPushbackForm => (
            <Form>
              <MUI.DialogContent className={classes.dialogPaper}>
                <div className={classes.row}>
                  <MUI.Typography variant="h6" className={classes.dialogTitle}>
                    Record Provider Pushback
                  </MUI.Typography>
                </div>
                <div className={classes.row}>
                  <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                    What type of pushback are you reporting?
                  </MUI.Typography>
                  <Field
                    name="pushbackMainType"
                    component={FormikToggleButtonGroup}
                    options={[
                      {
                        value: "Pre_Care",
                        label: "Pre-Care",
                        name: "Pre-Care"
                      },
                      {
                        value: "Post_Care",
                        label: "Post-Care",
                        name: "Post-Care"
                      }
                    ]}
                    onChange={(newTypeId: string) => handlePushbackMainType(newTypeId, providerPushbackForm)}
                  />
                </div>
                <div className={classes.row}>
                  <MUI.Grid container spacing={3}>
                    <MUI.Grid item xs={isAmountRequired(providerPushbackForm.values.pushbackSubType) ? 4 : 6}>
                      <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                        Type of Pushback
                      </MUI.Typography>
                      <Field
                        name="pushbackSubType"
                        id="pushback-subtype-select"
                        component={FormikReactSelect}
                        options={pushbackSubTypes}
                        getOptionLabel={(option: PushbackType) => option.name}
                        getOptionValue={(option: PushbackType) => option.id}
                      />
                    </MUI.Grid>
                    {isAmountRequired(providerPushbackForm.values.pushbackSubType) && (
                      <MUI.Grid item xs={2}>
                        <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                          Amount
                        </MUI.Typography>
                        <Field
                          name="amount"
                          component={FormikTextField}
                          variant="outlined"
                          fullWidth
                          placeholder="00.00"
                          InputProps={{
                            startAdornment: <MUI.InputAdornment position="start">$</MUI.InputAdornment>,
                            classes: {
                              root: classes.bw,
                              input: classes.textField,
                              disabled: classes.disabledTextField
                            },
                            inputComponent: NumberFormatField
                          }}
                          // eslint-disable-next-line
                          inputProps={{
                            fieldType: "money",
                            isAllowed: isAmountAllowed
                          }}
                        />
                      </MUI.Grid>
                    )}
                    <MUI.Grid item xs={6}>
                      <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                        Request Originated Due To
                      </MUI.Typography>
                      <Field
                        id="request-reasons-select"
                        name="requestReason"
                        component={FormikReactSelect}
                        options={requestReasons}
                        getOptionLabel={(option: RequestReason) => option.name}
                        getOptionValue={(option: RequestReason) => option.id}
                      />
                    </MUI.Grid>
                  </MUI.Grid>
                </div>
                <div className={classes.row}>
                  <MUI.Grid container spacing={3}>
                    <MUI.Grid item xs={6}>
                      <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                        When did pushback occur?
                      </MUI.Typography>
                      <Field
                        id="pushback-occurred-date"
                        name="pushbackOccurredDate"
                        component={FormikKeyboardDatePicker}
                        fullWidth
                        minDate={pickerMinDate}
                        maxDate={pickerMaxDate}
                        minDateMessage="Invalid field value"
                        maxDateMessage="Future date is not allowed"
                      />
                    </MUI.Grid>
                    <MUI.Grid item xs={6}>
                      <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                        When was pushback reported?
                      </MUI.Typography>
                      <Field
                        id="pushback-reported-date"
                        name="pushbackReportedDate"
                        component={FormikKeyboardDatePicker}
                        fullWidth
                        minDate={pickerMinDate}
                        maxDate={pickerMaxDate}
                        minDateMessage="Invalid field value"
                        maxDateMessage="Future date is not allowed"
                      />
                    </MUI.Grid>
                  </MUI.Grid>
                </div>
                <div className={classes.row}>
                  <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                    Who originally reported the pushback to you?
                  </MUI.Typography>
                  <MUI.Grid container spacing={3}>
                    <MUI.Grid item xs={6}>
                      <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                        Name
                      </MUI.Typography>
                      <Field
                        name="pushbackReportedName"
                        component={FormikTextField}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textField
                          },
                          inputComponent: TextMaskField
                        }}
                        // eslint-disable-next-line
                        inputProps={{
                          mask: alphaNumericMask
                        }}
                      />
                    </MUI.Grid>
                    <MUI.Grid item xs={6}>
                      <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                        Phone number
                        <span className={classes.optionalLabel}>
                          {"  "}
                          Optional
                        </span>
                      </MUI.Typography>
                      <Field
                        name="pushbackReportedPhoneNumber"
                        component={FormikTextField}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textField
                          },
                          inputComponent: NumberFormatField
                        }}
                        // eslint-disable-next-line
                        inputProps={{
                          format: getPhoneFormat
                        }}
                      />
                    </MUI.Grid>
                  </MUI.Grid>
                </div>
                <div className={classes.row}>
                  <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                    Has outreach to the Provider’s office to explain the plan and benefits from the TPA level been
                    completed?
                  </MUI.Typography>
                  <Field
                    name="hasOutreach"
                    component={FormikToggleButtonGroup}
                    options={[
                      { value: true, label: "Yes", name: "hasOutreach" },
                      { value: false, label: "No", name: "noOutreach" }
                    ]}
                  />
                </div>
                {providerPushbackForm.values.hasOutreach && (
                  <div className={classes.formWrapper}>
                    <div className={classes.row}>
                      <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                        <span>Who at the TPA level spoke to the Provider’s office?</span>
                      </MUI.Typography>
                      <MUI.Grid container spacing={3}>
                        <MUI.Grid item xs={6}>
                          <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                            <span>Name</span>
                          </MUI.Typography>
                          <Field
                            name="tpaLevelSpeakerName"
                            component={FormikTextField}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.textField
                              },
                              inputComponent: TextMaskField
                            }}
                            // eslint-disable-next-line
                            inputProps={{
                              mask: alphaNumericMask
                            }}
                          />
                        </MUI.Grid>
                        <MUI.Grid item xs={6}>
                          <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                            <span>Phone number</span>
                          </MUI.Typography>
                          <Field
                            name="tpaLevelSpeakerPhoneNumber"
                            component={FormikTextField}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.textField
                              },
                              inputComponent: NumberFormatField
                            }}
                            // eslint-disable-next-line
                            inputProps={{
                              format: getPhoneFormat
                            }}
                          />
                        </MUI.Grid>
                      </MUI.Grid>
                    </div>
                    <div className={classes.row}>
                      <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                        <span>Who at the Provider’s office did they discuss pushback with?</span>
                      </MUI.Typography>
                      <MUI.Grid container spacing={3}>
                        <MUI.Grid item xs={6}>
                          <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                            <span>Name</span>
                          </MUI.Typography>
                          <Field
                            name="whoDiscussPushbackName"
                            component={FormikTextField}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.textField
                              },
                              inputComponent: TextMaskField
                            }}
                            // eslint-disable-next-line
                            inputProps={{
                              mask: alphaNumericMask
                            }}
                          />
                        </MUI.Grid>
                        <MUI.Grid item xs={6}>
                          <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                            <span>Phone number</span>
                          </MUI.Typography>
                          <Field
                            name="whoDiscussPushbackPhoneNumber"
                            component={FormikTextField}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.textField
                              },
                              inputComponent: NumberFormatField
                            }}
                            // eslint-disable-next-line
                            inputProps={{
                              format: getPhoneFormat
                            }}
                          />
                        </MUI.Grid>
                      </MUI.Grid>
                    </div>
                    <div className={classes.row}>
                      <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                        <span>Who should AMPS contact at the Provider’s office, if needed?</span>
                      </MUI.Typography>
                      <MUI.Grid container spacing={3}>
                        <MUI.Grid item xs={6}>
                          <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                            <span>Name</span>
                          </MUI.Typography>
                          <Field
                            name="ampsContactName"
                            component={FormikTextField}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.textField
                              },
                              inputComponent: TextMaskField
                            }}
                            // eslint-disable-next-line
                            inputProps={{
                              mask: alphaNumericMask
                            }}
                          />
                        </MUI.Grid>
                        <MUI.Grid item xs={6}>
                          <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                            <span>Phone number</span>
                          </MUI.Typography>
                          <Field
                            name="ampsContactPhoneNumber"
                            component={FormikTextField}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.textField
                              },
                              inputComponent: NumberFormatField
                            }}
                            // eslint-disable-next-line
                            inputProps={{
                              format: getPhoneFormat
                            }}
                          />
                        </MUI.Grid>
                      </MUI.Grid>
                    </div>
                  </div>
                )}
                {providerPushbackForm.values.pushbackMainType === ProviderPushbackMainTypes.PRE_CARE && (
                  <div className={classes.row}>
                    <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                      <span>Current Status of Patient</span>
                    </MUI.Typography>
                    <Field
                      id="patient-statuses-select"
                      name="patientStatuses"
                      component={FormikReactSelect}
                      options={patientStatuses}
                      getOptionLabel={(option: PatientStatus) => option.name}
                      getOptionValue={(option: PatientStatus) => option.id}
                    />
                  </div>
                )}
                <div className={classes.row}>
                  <MUI.Grid container spacing={3}>
                    <MUI.Grid item xs={12}>
                      <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                        Notes for provider relations
                        <span className={classes.optionalLabel}>
                          {"  "}
                          Optional
                        </span>
                      </MUI.Typography>
                      <Field
                        name="providerRelationsNotes"
                        component={FormikTextField}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        rowsMax={3}
                        InputProps={{
                          classes: {
                            root: classes.multilineRoot,
                            input: classes.textField
                          }
                        }}
                      />
                    </MUI.Grid>
                  </MUI.Grid>
                </div>
              </MUI.DialogContent>
              <MUI.DialogActions>
                <Button id="cancel-pushback-button" onClick={onCancelClick} color="primary">
                  Cancel
                </Button>
                <div className={classes.progressWrapper}>
                  <Button
                    id="submit-pushback-button"
                    type="submit"
                    variant={providerPushbackForm.isValid ? "contained" : "text"}
                    color="primary"
                    autoFocus
                    disabled={!providerPushbackForm.isValid || isFormSubmitting}
                  >
                    Save
                  </Button>
                  {isFormSubmitting && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </MUI.DialogActions>
            </Form>
          )}
        </Formik>
      </MUI.Dialog>
    </>
  );
}

export default ProviderPushbackModal;
