import axios from "axios";
import GroupModel from "models/group.model";
import { Action, action, Thunk, thunk } from "easy-peasy";
import { GroupReducerModel } from "types";

const setGroups: Action<GroupReducerModel, GroupModel[]> = action(
  (state, groups) => {
    state.groups = groups;
  }
);

const getGroupsByClientId: Thunk<GroupReducerModel, string> = thunk(
  async (actions, clientId) => {
    const response: any = await axios.get(`/clients/${clientId}/groups`, {
      params: {
        isActive: true
      }
    });

    return response;
  }
);

const getGroupById: Thunk<GroupReducerModel, string> = thunk(
  async (actions, groupId) => {
    const response: any = await axios.get(`/client-group/${groupId}`);

    return response;
  }
);

const GroupReducer: GroupReducerModel = {
  groups: [],
  setGroups,
  getGroupsByClientId,
  getGroupById
};

export default GroupReducer;
