import authenticationReducer from "redux/reducers/authenticationReducer";
import agentReducer from "./agentReducer";
import clientReducer from "./clientReducer";
import groupReducer from "./groupReducer";
import memberReducer from "./memberReducer";
import interactionReducer from "./interactionReducer";
import miscCodesReducer from "./miscCodesReducer";
import modalReducer from "./modalReducer";
import productReducer from "./productReducer";
import providerReducer from "./providerReducer";
import reportReducer from "./reportReducer";
import requestReducer from "./requestReducer";
import specialtyReducer from "./specialtyReducer";
import serviceReducer from "./serviceReducer";
import { StoreModel } from "types";

const rootReducer: StoreModel = {
  agent: agentReducer,
  auth: authenticationReducer,
  client: clientReducer,
  group: groupReducer,
  interaction: interactionReducer,
  member: memberReducer,
  miscCodes: miscCodesReducer,
  modal: modalReducer,
  product: productReducer,
  provider: providerReducer,
  report: reportReducer,
  request: requestReducer,
  service: serviceReducer,
  specialty: specialtyReducer
};

export default rootReducer;
