import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(theme =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: theme.spacing(5)
    },
    form: {
      width: "100%" // Fix IE 11 issue.
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    },
    logo: {
      width: 270,
      marginBottom: theme.spacing(5)
    },
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      overflowX: "hidden",
      overflowY: "scroll"
    },
    snackbar: {
      width: "100%",
      backgroundColor: theme.palette.error.dark
    },
    message: {
      display: "flex",
      alignItems: "center",
      color: "#FFF"
    },
    icon: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
      color: "#FFF"
    },
    forgotLink: {
      marginTop: theme.spacing(2),
      color: "gray"
    },
    progressWrapper: {
      position: "relative"
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      margin: "-8px 0 0 -12px"
    },
    box: {
      padding: "80px 60px 55px",
      margin: "0 70px 30px 70px",
      position: "relative"
    },
    root: {
      minWidth: 650,
      width: 600,
      padding: "10px",
      position: "relative",
      "& .trust-computer": {
        paddingTop: 20,
        marginBottom: -20
      },
      "& .note": {
        paddingTop: 20
      }
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between"
    },
    boxControls: {
      position: "absolute",
      top: 15,
      right: 12,
      display: "flex",
      justifyContent: "flex-end"
    },
    mfaField: {
      marginTop: 16
    },
    footer: {
      marginTop: 16
    }
  })
);
