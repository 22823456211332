import React from "react";
import { MUI } from "@amps/material-ui";

export default function FormikCheckbox(props: any) {
  const {
    field,
    form: { setFieldValue, setFieldTouched },
    classes,
    label,
    onChange,
    ...otherProps
  } = props;

  const handleChange = (event: any, value: any) => {
    setFieldValue(field.name, value);
    setFieldTouched(field.name, true);

    onChange && onChange(value);
  };

  return (
    <MUI.FormControlLabel
      control={<MUI.Checkbox {...otherProps} {...field} value={field.value} onChange={handleChange} color="primary" />}
      classes={classes}
      label={label}
    />
  );
}
