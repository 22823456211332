import axios from "axios";
import sortBy from "lodash/sortBy";
import { getGender, getAge } from "utils/memberUtils";
import MemberModel from "models/member.model";
import { Action, Thunk, action, thunk } from "easy-peasy";
import {
  MemberReducerModel,
  MemberSearchData,
  UpdateMemberPayload,
  UpdateMemberNotesPayload,
  AddNotePayload
} from "types";

const setMembers: Action<MemberReducerModel, MemberModel[]> = action((state, members) => {
  state.members = members;
});

const getMembers: Thunk<MemberReducerModel, MemberSearchData> = thunk(async (actions, payload) => {
  const response: any = await axios.get("/members", {
    params: payload
  });

  response.forEach((member: MemberModel) => {
    member.age = getAge(member);
  });

  const sortedMembers = sortBy(response, ["lastName", "firstName"]);

  return sortedMembers;
});

const updateMember: Thunk<MemberReducerModel, UpdateMemberPayload> = thunk(async (actions, payload) => {
  const response = axios.patch(`/members/${payload.memberId}`, payload.data);

  return response;
});

const updateMemberNotes: Thunk<MemberReducerModel, UpdateMemberNotesPayload> = thunk(async (actions, payload) => {
  const response: any = axios.patch(`/members/${payload.memberId}/note`, {
    notes: payload.notes
  });

  return response;
});

const addNote: Thunk<MemberReducerModel, AddNotePayload> = thunk(async (actions, payload) => {
  const response = axios.post(`/members/${payload.memberId}/note`, {
    body: payload.noteBody,
    title: payload.noteTitle
  });

  return response;
});

const getMemberById: Thunk<MemberReducerModel, string> = thunk(async (actions, memberId) => {
  const response: any = await axios.get(`/members/${memberId}`);

  response.age = getAge(response);
  response.gender = getGender(response);

  return response;
});

const MemberReducer: MemberReducerModel = {
  members: [],
  setMembers,
  getMembers,
  updateMember,
  updateMemberNotes,
  addNote,
  getMemberById
};

export default MemberReducer;
