import React, { useEffect, useState } from "react";
import { MUI, Button, Menu } from "@amps/material-ui";
import useStyles from "components/RequestPage/styles/ActivitiesLog.style";
import RequestModel from "models/request.model";
import ServiceModel from "models/service.model";
import MemberModel from "models/member.model";
import NewInteractionModal from "components/RequestPage/NewInteractionModal";
import ServiceView from "components/RequestPage/ServiceView";
import AttachFileModal from "components/RequestPage/AttachFileModal";
import { getAvailableInteractions } from "utils/constants/StageInteraction";
import { useStoreActions } from "redux/reducers/hooks";
import { Add, KeyboardArrowDown, Cancel } from "@material-ui/icons";
import { Modals } from "utils/modalUtils";
import PlanNotes from "./PlanNotes";
import { PlanNoteModel } from "models/planNote.model";

type Props = {
  request: RequestModel;
  member: MemberModel;
};

export function ActivitiesLog(props: Props) {
  const { request, member } = props;
  const [interactionModalType, setInteractionModalType] = useState("");
  const classes = useStyles();
  const currentService = request.services.find(service => service.isCurrent);
  const availableInteractions = currentService ? getAvailableInteractions(currentService) : [];
  const [isStageUpdating, setIsStageUpdating] = useState(false);
  const [openAttachFileModal, setOpenAttachFileModal] = useState(false);
  const [isCloseRequestSubmitting, setIsCloseRequestSubmitting] = useState(false);
  const {
    request: { getRequestById, closeRequest },
    modal: { openModal }
  } = useStoreActions(actions => actions);
  const [group, setGroup] = useState<PlanNoteModel[] | null>(null);
  const getClientById = useStoreActions(actions => actions.client.getClientById);
  const getGroupById = useStoreActions(actions => actions.group.getGroupById);
  const getProductsByGroupId = useStoreActions(actions => actions.product.getProductsByGroupId);
  const [isMemberProfileLoading, setIsMemberProfileLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (!member) return;

      setIsMemberProfileLoading(true);

      try {
        const group = await getGroupById(member.clientGroupId);
        setGroup(group.planNotes);
      } finally {
        setIsMemberProfileLoading(false);
      }
    }

    fetchData();
  }, [member, getClientById, getGroupById, getProductsByGroupId]);

  const handleNewInteractionModalClose = () => {
    setInteractionModalType("");
  };

  const openInteractionModal = (interactionMainType: string) => {
    setTimeout(() => {
      setInteractionModalType(interactionMainType);
    }, 0);
  };

  const hasActiveService = () => {
    const { services } = request;
    if (!services.length) return false;

    const activeService = services.find(service => !service.closingReason);

    return !!activeService;
  };

  const canAddNewInteraction = () => {
    if (isStageUpdating) return false;

    return hasActiveService();
  };

  const handleCloseRequest = async () => {
    setIsCloseRequestSubmitting(true);

    try {
      await closeRequest(request.id);
      await getRequestById(request.id);
    } finally {
      setIsCloseRequestSubmitting(false);
    }
  };

  const handleSetStageUpdating = (isUpdating: boolean) => {
    setIsStageUpdating(isUpdating);
  };

  const onNewServiceClick = () => {
    openModal({
      modalType: Modals.NewService,
      modalProps: { member, request, isFirstService: false }
    });
  };

  const onCloseRequestClick = () => {
    openModal({
      modalType: Modals.Confirmation,
      modalProps: {
        onOk: handleCloseRequest,
        isOkInProgress: isCloseRequestSubmitting,
        okButtonText: "Close Request"
      }
    });
  };

  const renderService = (service: ServiceModel) => {
    const currentServiceId = currentService && currentService.id;
    const isExpanded = service.id === currentServiceId;

    return (
      <ServiceView
        key={service.id}
        service={service}
        request={request}
        isDefaultExpanded={isExpanded}
        handleSetStageUpdating={handleSetStageUpdating}
        serviceId={Number(service.id)}
      />
    );
  };

  return (
    <>
      <NewInteractionModal
        open={!!interactionModalType}
        mainType={interactionModalType}
        request={request}
        member={member}
        onClose={handleNewInteractionModalClose}
      />
      {request.requestStatus && request.requestStatus.name !== "Closed" && (
        <div className={classes.row}>
          <MUI.Grid container spacing={0} justify="space-between" alignItems="center">
            <div>
              <Menu
                id="new-interaction-menu"
                buttonText="New Interaction"
                buttonProps={{
                  variant: "contained",
                  disabled: !canAddNewInteraction(),
                  color: "primary",
                  className: classes.newInteractionButton
                }}
                LeftIcon={Add}
                RightIcon={KeyboardArrowDown}
              >
                <MUI.MenuList>
                  {availableInteractions.map((interactionMainType: string, index: number) => (
                    <MUI.MenuItem
                      className="interaction-menu-item"
                      key={`${index}-${interactionMainType}`}
                      onClick={() => openInteractionModal(interactionMainType)}
                    >
                      {interactionMainType}
                    </MUI.MenuItem>
                  ))}
                </MUI.MenuList>
              </Menu>
              <Button
                id="new-service-button"
                className={classes.actionButton}
                variant="contained"
                disabled={hasActiveService() || request.services.length >= 15}
                onClick={onNewServiceClick}
                color="primary"
              >
                <Add className={classes.iconMargin} />
                New Service
              </Button>
              <Button
                id="attach-files-button"
                variant="contained"
                onClick={() => setOpenAttachFileModal(true)}
                color="primary"
              >
                <Add className={classes.iconMargin} />
                Attach File
              </Button>
              <div style={{ marginBottom: "20px" }} />
              <PlanNotes planNotes={group} isLoading={isMemberProfileLoading} />
              {openAttachFileModal && (
                <AttachFileModal
                  request={request}
                  open={openAttachFileModal}
                  onClose={() => setOpenAttachFileModal(false)}
                />
              )}
            </div>
            <div>
              {!hasActiveService() && (
                <>
                  <Button
                    id="close-request-button"
                    variant="contained"
                    onClick={onCloseRequestClick}
                    color="primary"
                    classes={{
                      root: classes.closeRequestButton
                    }}
                  >
                    <Cancel className={classes.iconMargin} />
                    Close Request
                  </Button>
                </>
              )}
            </div>
          </MUI.Grid>
        </div>
      )}
      <div>{request.services.map(service => renderService(service))}</div>
    </>
  );
}

export default ActivitiesLog;
