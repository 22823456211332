import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  historyLogPaper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "16px",
    padding: "16px",
    backgroundColor: "rgba(230,234,237,0.3)"
  },
  helperGrid: {
    maxHeight: "68px"
  },
  helperText: {
    fontStyle: "italic",
    fontSize: "12px"
  },
  helperBox: {
    textAlign: "right",
    height: "100%",
    display: "grid"
  },
  activityLabel: {
    fontSize: "0.845rem",
    color: "#545454"
  }
}));
