import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  panelTitle: {
    height: "36px",
    fontSize: "18px"
  },
  expPanel: {
    marginBottom: "16px",
    borderRadius: "4px",
    "&::before": {
      display: "none"
    }
  },
  noOptions: {
    width: "100%",
    marginTop: "15px",
    textAlign: "center"
  },
  noteText: {
    padding: 0,
    fontSize: "14px"
  }
}));
