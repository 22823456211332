import React from "react";
import { MUI } from "@amps/material-ui";
import useStyles from "components/shared/styles/PageTitle.style";

interface Props {
  title: string;
}

export function PageTitle(props: Props) {
  const { title } = props;
  const classes = useStyles();

  return (
    <div className={classes.pageTitle}>
      <MUI.Typography variant="h4">{title}</MUI.Typography>
    </div>
  );
}

export default PageTitle;
