import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  dialogTitle: {
    fontSize: "24px"
  },
  dialogPaper: {
    overflow: "visible",
    padding: "18px 24px 8px"
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: 600
  },
  row: {
    marginBottom: "16px",
    display: "grid"
  },
  rowSecondary: {
    marginBottom: "24px",
    display: "grid"
  },
  switchRow: {
    marginTop: "-8px",
    marginLeft: "-16px",
    justifyContent: "start"
  },
  sectionRow: {
    marginBottom: "16px"
  },
  rowTitle: {
    marginBottom: "8px",
    fontSize: "16px"
  },
  textField: {
    fontSize: "14px",
    padding: "12px",
    backgroundColor: "white"
  },
  toogleButton: {
    backgroundColor: "rgba(240, 80, 37, 0.08)!important"
  },
  paper: {
    padding: "16px",
    backgroundColor: "#e6eaed"
  },
  urgentCell: {
    textAlign: "right",
    paddingRight: "0px!important"
  },
  multilineRoot: {
    padding: 0
  },
  requiredLabel: {
    fontSize: "12px",
    verticalAlign: "middle",
    color: "#f44336"
  },
  optionalLabel: {
    fontSize: "12px",
    verticalAlign: "middle",
    color: "#7698cc"
  },
  checkBoxWrapper: {
    padding: "10px",
    backgroundColor: "#FCF8E3",
    border: "1px solid #CDD1D4"
  },
  careDenialCheckbox: {
    marginTop: "-8px"
  },
  providerCheckbox: {
    marginBottom: "10px"
  },
  helperText: {
    position: "absolute",
    fontSize: "14px",
    color: "rgba(240, 80, 37, 0.6)"
  },
  disabledCheckbox: {
    color: "#999999!important"
  },
  progressWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px"
  }
}));
