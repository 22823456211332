import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  container: {
    marginBottom: "4px"
  },
  gridItem: {
    paddingBottom: "4px!important"
  },
  productItemHeader: {
    paddingBottom: "0px!important"
  },
  productItem: {
    paddingBottom: "0px!important",
    paddingTop: "0px!important"
  },
  textItem: {
    overflowWrap: "break-word",
    fontWeight: 600
  },
  textField: {
    fontSize: "14px",
    padding: "12px",
    color: "#2b2b2b"
  },
  editButton: {
    margin: "0px 4px"
  },
  editRow: {
    display: "flex",
    alignItems: "center"
  },
  divider: {
    width: 1,
    height: 28
  },
  progressWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px"
  },
  starIcon: {
    color: "gold"
  },
}));
