import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  dialogTitle: {
    fontSize: "24px"
  },
  dialogPaper: {
    overflow: "visible",
    padding: "18px 24px 8px"
  },
  progressWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px"
  },
  paper: {
    padding: "16px 24px"
  },
  dropZone: {
    padding: "28px",
    textAlign: "center",
    backgroundColor: "#f0fdff",
    border: "1px solid #ddd",
    borderRadius: "4px",
    marginBottom: "8px",
    outline: 0,
    cursor: "pointer"
  },
  dropZoneTitle: {
    fontSize: "22px"
  },
  dropZoneTitleSeparator: {
    margin: "20px 0",
    fontSize: "18px"
  },
  dragOver: {
    backgroundColor: "#e1f9fc",
    border: "1px solid #c3f1f7"
  },
  drop: {
    backgroundColor: "#c3f1f7"
  },
  disabledZone: {
    backgroundColor: "#e8e8e8"
  },
  fileWrapper: {
    position: "relative",
    marginTop: "8px",
    padding: "16px 50px 16px 16px",
    borderRadius: "4px",
    backgroundColor: "#f7f7f7"
  },
  fileName: {
    fontWeight: 600,
    wordBreak: "break-all"
  },
  removeButton: {
    position: "absolute",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)"
  },
  helperText: {
    fontSize: "14px",
    color: "rgba(240, 80, 37, 0.6)"
  }
}));
