import React from "react";
import { MUI } from "@amps/material-ui";
import useStyles from "components/RequestPage/styles/RequestAttachments.style";
import RequestModel from "models/request.model";
import FileModel from "models/file.model";
import { ExpandMore } from "@material-ui/icons";

type Props = {
  request: RequestModel;
  onFetchFileHandler: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

function RequestAttachments(props: Props): React.ReactElement<Props> {
  const { request, onFetchFileHandler } = props;
  const classes = useStyles();

  return (
    <MUI.Accordion className={classes.expPanel}>
      <MUI.AccordionSummary expandIcon={<ExpandMore />}>
        <MUI.Grid container spacing={0} alignItems="center" className={classes.panelTitle}>
          <span>
            <b>Attachments</b>
          </span>
        </MUI.Grid>
      </MUI.AccordionSummary>
      <MUI.Divider variant="middle" />
      <MUI.AccordionDetails>
        <MUI.Grid container spacing={2}>
          {!!request.files &&
            !!request.files.length &&
            request.files.map((file: FileModel) => (
              <MUI.Grid item xs={12} key={file.id}>
                <MUI.Button
                  className={classes.downloadFileLink}
                  onClick={onFetchFileHandler}
                  name={file.originalName}
                  id={file.id}
                >
                  {file.originalName}
                </MUI.Button>
              </MUI.Grid>
            ))}

          {(!request.files || !request.files.length) && (
            <MUI.Typography className={classes.noOptions}>No Attachments</MUI.Typography>
          )}
        </MUI.Grid>
      </MUI.AccordionDetails>
    </MUI.Accordion>
  );
}

export default RequestAttachments;
