import axios from "axios";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { defaultSkip, defaultPageSize } from "utils/searchUtils";
import {
  defaultOrderBy,
  defaultOrder
} from "components/ReportsPage/reportsTableUtils";
import {
  ReportReducerModel,
  ReportsSearchCriteriaType,
  ReportResponse,
  FetchReportPayload,
  FetchCustomReportPayload,
  ReportState
} from "types";

const setReports: Action<ReportReducerModel, ReportResponse> = action(
  (state, reports) => {
    state.reports = reports;
  }
);

const setSearchCriteria: Action<
  ReportReducerModel,
  ReportsSearchCriteriaType
> = action((state, searchCriteria) => {
  state.searchCriteria = searchCriteria;
});

const getReports: Thunk<ReportReducerModel, ReportsSearchCriteriaType> = thunk(
  async (actions, searchData) => {
    const response: any = await axios.post("/report-files/search", {
      ...searchData
    });
    actions.setReports(response);
    return response;
  }
);

const getMoreReports: Thunk<
  ReportReducerModel,
  ReportsSearchCriteriaType
> = thunk(async (actions, searchData, { getState }) => {
  const { reports } = getState();
  const response: ReportResponse = await axios.post("/report-files/search", {
    ...searchData
  });
  const newReportData = [...reports.data, ...response.data];

  actions.setReports({
    data: newReportData,
    totalCount: response.totalCount
  });

  return response;
});

const fetchReport: Thunk<ReportReducerModel, FetchReportPayload> = thunk(
  async (actions, payload) => {
    return axios
      .get(`/report-files/${payload.fileId}`, { responseType: "arraybuffer" })
      .then((resp: any) => {
        return new Blob([resp]);
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        a.download = payload.name;
        document.body.appendChild(a);
        a.click();

        if (a.parentNode) {
          a.parentNode.removeChild(a);
        }
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error(error);
      });
  }
);

const getClientReport: Thunk<ReportReducerModel> = thunk(async actions => {
  await axios.get("/reports/client-reports");
});

const getFullReport: Thunk<ReportReducerModel> = thunk(async actions => {
  await axios.get("/reports/full-reports");
});

const fetchCustomReport: Thunk<
  ReportReducerModel,
  FetchCustomReportPayload
> = thunk(async (actions, payload) => {
  const { searchData, client, clientGroup } = payload;

  return axios
    .post("/reports", searchData, {
      responseType: "arraybuffer"
    })
    .then((resp: any) => {
      return new Blob([resp]);
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const fileName =
        client || clientGroup
          ? `${client ? `${client.code} ` : ""}${
              clientGroup ? `${clientGroup.name} ` : ""
            }- Connex Care Event Report.xlsx`
          : "Full Report - Connex Care Event Report.xlsx";

      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      if (a.parentNode) {
        a.parentNode.removeChild(a);
      }

      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error(error);
    });
});

export const initialState: ReportState = {
  reports: {
    data: [],
    totalCount: 0
  },
  searchCriteria: {
    skip: defaultSkip,
    take: defaultPageSize,
    sortBy: defaultOrderBy,
    sortDirection: defaultOrder,
    clientGroupName: null
  }
};

const ReportReducer: ReportReducerModel = {
  ...initialState,
  fetchCustomReport,
  fetchReport,
  getClientReport,
  getFullReport,
  getMoreReports,
  getReports,
  setReports,
  setSearchCriteria
};

export default ReportReducer;
