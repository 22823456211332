import React, { useState, useEffect, useRef } from "react";
import { MUI, Button } from "@amps/material-ui";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Formik, Form, Field } from "formik";
import addDays from "date-fns/add_days";
import axios from "axios";

import clsx from "clsx";
import useStyles from "components/RequestPage/styles/NewInteractionModal.style";

import ProviderSearchForm from "components/RequestPage/ProviderSearchForm";
import ProviderPushbackModal from "components/RequestPage/ProviderPushbackModal";
import MemberBenefits from "components/shared/MemberBenefits";
import RequestModel from "models/request.model";
import ServiceModel from "models/service.model";
import InteractionModel from "models/interaction.model";
import MemberModel from "models/member.model";

import getFormSettings from "utils/constants/InteractionFormSettings";
import { getFullInteractionType } from "utils/constants/StageInteraction";

import FormikTextField from "containers/FormikTextField";
import FormikToggleButtonGroup from "containers/FormikToggleButtonGroup";
import FormikKeyboardDatePicker from "containers/FormikKeyboardDatePicker";
import FormikSwitch from "containers/FormikSwitch";
import FormikCheckbox from "containers/FormikCheckbox";

import ProviderExperienceTypes from "utils/constants/ProviderExperienceTypes";
import { getInteractionWithNonClosedSCA } from "utils/serviceUtils";
import { getPickerMinDate, getPickerMaxDate } from "utils/dateTimeUtils";
import { useStoreActions, useStoreState } from "redux/reducers/hooks";
import { Modals } from "utils/modalUtils";
import { LoginModel } from "types";

interface NewInteractionModalProps {
  open: boolean;
  mainType: string;
  request: RequestModel;
  member: MemberModel;
  onClose: Function;
}

type InteractionType = {
  id: string;
  name: string;
};

export function NewInteractionModal(props: NewInteractionModalProps) {
  const classes = useStyles();
  const { open, mainType, onClose, request, member } = props;
  const [selectedSubType, setSelectedSubType] = useState("");
  const [interactionType, setInteractionType] = useState(null as InteractionType | null);
  const [initialValues, setInitialValues] = useState(null as any | null);
  const [validationSchema, setValidationSchema] = useState(null);
  const [isOptionalLabel, setIsOptionalLabel] = useState(false);
  const [formElements, setFormElements] = useState([] as string[]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [createdInteraction, setCreatedInteraction] = useState(null as InteractionModel | null);
  const [openProviderPushbackModal, setOpenProviderPushbackModal] = useState(false);
  const [currentService, setCurrentService] = useState(undefined as ServiceModel | undefined);
  const newInteractionFormik = useRef(null);
  const providerSearchFormik = useRef(null);
  const followUpPickerMinDate = getPickerMinDate(addDays(new Date(), 1));
  const contactPickerMinDate = getPickerMinDate();
  const followUpPickerMaxDate = getPickerMaxDate();
  const contactPickerMaxDate = getPickerMaxDate(new Date());
  const { portalSso } = useStoreActions(actions => actions.auth);
  const {
    request: { getRequestById },
    interaction: { createInteraction },
    provider: { getProviderExperienceTypes },
    service: { updateServiceDetails },
    modal: { openModal }
  } = useStoreActions(actions => actions);

  const providerExperienceTypes = useStoreState(state => state.provider.experienceTypes);
  const user = useStoreState(state => state.auth.user);

  useEffect(() => {
    const currentService = request.services.find(service => service.isCurrent);

    if (!currentService) return;

    setCurrentService(currentService);
  }, [request]);

  useEffect(() => {
    if (!initialValues) return;

    if (initialValues.hasOwnProperty("providerExperience") && !providerExperienceTypes.length) {
      getProviderExperienceTypes();
    }
  }, [initialValues, getProviderExperienceTypes, providerExperienceTypes]);

  const handleSelectInteractionType = (event: any, option: string) => {
    if (!currentService) return;

    setSelectedSubType(option);

    const interactionFullType = [mainType, option].join(" ");
    const interactionType = getFullInteractionType(interactionFullType);

    if (!interactionType) return;

    setInteractionType(interactionType);

    const formSettings = getFormSettings(currentService, interactionType.name);

    setIsOptionalLabel(!!formSettings.isOptionalLabel);
    setInitialValues(formSettings.initialValues);
    setValidationSchema(formSettings.validationSchema);
    setFormElements(formSettings.formElements || []);

    (newInteractionFormik.current as any).resetForm(formSettings.initialValues);
  };

  const handleNewInteraction = async (values: any) => {
    if (shouldConfirmationPopupRender(values.providerExperience)) {
      openModal({
        modalType: Modals.Confirmation,
        modalProps: {
          onOk: handleCloseConfirmationModal(values),
          cancelButtonText: "No",
          okButtonText: "Yes",
          messageContent: (
            <span>
              We will need to close the SCA request for <b>{providerName}</b>
            </span>
          )
        }
      });
      return;
    }

    setIsFormSubmitting(true);

    try {
      await submitNewInteraction(values);
      await updateTreatmentAndDetailsForm(values);

      handleCloseNewInteractionModal();

      await getRequestById(request.id);
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const submitNewInteraction = async (values: any) => {
    if (!currentService || !interactionType) return;

    const {
      externalNote,
      internalNote,
      followUpDate,
      isCompleted,
      providerId,
      providerExperience,
      careDenied,
      vendor,
      contactDate,
      pointOfContact,
      referenceNumber
    } = values;
    const data: any = {
      typeId: parseInt(interactionType.id),
      serviceId: parseInt(currentService.id)
    };
    const scaRequestInteraction = getInteractionWithNonClosedSCA(currentService);

    providerExperience &&
      parseInt(providerExperience) === ProviderExperienceTypes.SCA_NEEDED &&
      (data.memberId = parseInt(request.memberId));
    externalNote && (data.externalNote = externalNote);
    internalNote && (data.internalNote = internalNote);
    careDenied && (data.careDenied = careDenied);
    pointOfContact && (data.pointOfContact = pointOfContact);
    referenceNumber && (data.referenceNumber = referenceNumber);
    followUpDate && (data.followUp = new Date(followUpDate));
    contactDate && (data.contactDate = new Date(contactDate));
    isCompleted !== undefined && (data.isCompleted = isCompleted);
    providerId && (data.providerId = providerId);
    providerExperience && (data.providerExperienceTypeId = parseInt(providerExperience));
    vendor && (data.vendorName = vendor);
    scaRequestInteraction &&
      scaRequestInteraction.scaRequest &&
      parseInt(providerExperience) === ProviderExperienceTypes.PROVIDER_ACCEPTED &&
      (data.scaRequestIdToClose = parseInt(scaRequestInteraction.scaRequest.id));

    if (careDenied) {
      setCreatedInteraction(data);
      setOpenProviderPushbackModal(true);
      return;
    }

    await createInteraction(data);
  }
  const updateTreatmentAndDetailsForm = async (values: any) => {
    const isZipRadiusSpecialtyRequired = isProviderFieldRequired(values, "providerId") && isZipRadiusSpecialtyEnable();
    const isMemberBenefitsRequired = isMemberBenefitsEnable();

    if (!currentService || (!isZipRadiusSpecialtyRequired && !isMemberBenefitsRequired)) return;

    let data: any = {};

    if (isZipRadiusSpecialtyRequired) {
      const { zip, radius } = (providerSearchFormik.current as any).state.values;

      data.zipCode = zip || null;
      data.searchRadius = radius && (parseInt(radius.value) || null);
    }

    if (isMemberBenefitsRequired) {
      const { deductible, coPayAmount, deductibleMET, oop, oopMet, coInsurance, other } = values;

      data.copayAmount = coPayAmount ? parseFloat(coPayAmount) : null;
      data.deductible = deductible ? parseFloat(deductible) : null;
      data.deductibleMET = deductibleMET ? parseFloat(deductibleMET) : null;
      data.oop = oop ? parseFloat(oop) : null;
      data.oopMet = oopMet ? parseFloat(oopMet) : null;
      data.coInsurance = coInsurance || null;
      data.other = other || null;
    }

    await updateServiceDetails({ serviceId: currentService.id, data });
  };

  const handleCloseNewInteractionModal = () => {
    resetInteractionSettings();
    onClose();
  };

  const resetInteractionSettings = () => {
    setSelectedSubType("");
    setIsOptionalLabel(false);
    setInitialValues(null);
    setValidationSchema(null);
    setFormElements([]);
  };

  const handleCloseConfirmationModal = async (values: any) => {
    setIsFormSubmitting(true);

    try {
      await submitNewInteraction(values);
      await updateTreatmentAndDetailsForm(values);

      onClose();

      await getRequestById(request.id);
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const handleProviderPushbackClose = () => {
    setOpenProviderPushbackModal(false);
  };

  const onSetProvider = (providerId: number | null) => {
    (newInteractionFormik.current as any).setFieldValue("providerId", providerId);
  };

  const isCareDenialEnabled = () => {
    if (!currentService) return false;

    const providerAcceptedInteraction = currentService.interactions.find((interaction: InteractionModel) => {
      const { provider, providerExperienceType } = interaction;

      return (
        !!provider &&
        !!providerExperienceType &&
        parseInt(providerExperienceType.id) === ProviderExperienceTypes.PROVIDER_ACCEPTED
      );
    });

    return !!providerAcceptedInteraction;
  };

  const shouldConfirmationPopupRender = (type: string) => {
    if (!currentService) return false;

    if (parseInt(type) !== ProviderExperienceTypes.PROVIDER_ACCEPTED) return false;

    const scaRequestInteraction: InteractionModel | undefined = getInteractionWithNonClosedSCA(currentService);

    if (!scaRequestInteraction || !scaRequestInteraction.provider) return false;

    setProviderName(scaRequestInteraction.provider.name);

    return true;
  };

  const alreadyOpenSCA = () => {
    if (!currentService) return false;

    const scaRequestInteraction: InteractionModel | undefined = getInteractionWithNonClosedSCA(currentService);

    return !!scaRequestInteraction;
  };

  const isFormValid = (interactionForm: any) => {
    const { isValid, values } = interactionForm;

    return isValid && !(parseInt(values.providerExperience) === ProviderExperienceTypes.SCA_NEEDED && alreadyOpenSCA());
  };

  const isZipRadiusSpecialtyEnable = () => {
    return formElements.includes("zipRadiusSpecialty");
  };

  const isMemberBenefitsEnable = () => {
    return formElements.includes("memberBenefits");
  };

  const tpaCalloutAdditionalFieldsEnable = () => {
    return formElements.includes("tpaCalloutAdditionalFields");
  };

  const isProviderFieldRequired = (interactionFormValues: any, fieldName: string) => {
    const { providerToggle } = interactionFormValues;

    if (initialValues.hasOwnProperty("providerToggle") && initialValues.hasOwnProperty(fieldName))
      return providerToggle;

    return initialValues.hasOwnProperty(fieldName);
  };

  const handleProviderToggle = (checked: boolean, interactionForm: any) => {
    if (checked) return;

    interactionForm.setFieldValue("providerExperience", null);
    interactionForm.setFieldValue("providerId", "");
  };

  return (
    <>
      {openProviderPushbackModal && createdInteraction && (
        <ProviderPushbackModal
          request={request}
          interaction={createdInteraction}
          open={openProviderPushbackModal}
          onClose={handleProviderPushbackClose}
          onNewInteractionClose={handleCloseNewInteractionModal}
        />
      )}
      <MUI.Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={!!selectedSubType ? "md" : "xs"}
        fullWidth={true}
        disableBackdropClick
      >
        <Formik
          ref={newInteractionFormik}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleNewInteraction}
        >
          {interactionForm => (
            <>
              <Form>
                <MUI.DialogContent className={classes.dialogPaper}>
                  <div className={classes.row}>
                    <MUI.Typography variant="h6" className={classes.dialogTitle}>
                      {mainType} {selectedSubType}
                    </MUI.Typography>
                  </div>
                  <div className={classes.row}>
                    <ToggleButtonGroup
                      value={selectedSubType}
                      exclusive
                      onChange={handleSelectInteractionType}
                      size="small"
                    >
                      <ToggleButton
                        name="type-callout-button"
                        value="Callout"
                        disabled={!!selectedSubType}
                        classes={{
                          selected: classes.toogleButton
                        }}
                      >
                        <span>Callout</span>
                      </ToggleButton>
                      <ToggleButton
                        name="type-callback-button"
                        value="Callback"
                        disabled={!!selectedSubType}
                        classes={{
                          selected: classes.toogleButton
                        }}
                      >
                        <span>Callback</span>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  {initialValues && (
                    <>
                      {initialValues.hasOwnProperty("vendor") && (
                        <div className={classes.row}>
                          <MUI.Grid container spacing={3}>
                            <MUI.Grid item xs={12}>
                              <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                                Vendor Name
                                {!isOptionalLabel && <span className={classes.requiredLabel}> *Required</span>}
                              </MUI.Typography>
                              <Field
                                name="vendor"
                                component={FormikTextField}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  classes: {
                                    input: classes.textField
                                  }
                                }}
                              />
                            </MUI.Grid>
                          </MUI.Grid>
                        </div>
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          const response = await portalSso({userCode:user?.code, clientId:member.clientId, groupId:member.clientGroupId});
                          let url = ''
                          const path = window.location.href
                          if (path.indexOf("localhost") > -1) {
                            url = 'http://localhost:3000';
                          }
                          else if (path.indexOf("care-navigator-dev") > -1) {
                            url = 'https://portal-dev.advancedpricing.com';
                          }
                          else if (path.indexOf("care-navigator-qa") > -1) {
                            url = 'https://portal-qa.advancedpricing.com';
                          }
                          else if (path.indexOf("care-navigator-uat") > -1) {
                            url = 'https://portal-uat1.advancedpricing.com';
                          }
                          else if (path.indexOf("care-navigator") > -1) {
                            url = 'https://portal.advancedpricing.com';
                          }
                          window.open(`${url}/ssoLogin?uuid=${response.uuidCode}&code=${response.code}&clientid=${response.clientId}&clientgroupid=${response.groupId}`, "_blank")
                        }}>
                        Provider Finder
                      </Button>


                      <div className={classes.rowSecondary}>
                        <MUI.Grid container spacing={3}>
                          <MUI.Grid item xs={12}>
                            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                              Experience
                            </MUI.Typography>
                            <Field
                              name="providerExperience"
                              component={FormikToggleButtonGroup}
                              options={providerExperienceTypes.map(type => ({
                                value: type.id,
                                label: type.name,
                                name: type.name
                              }))}
                            />
                          </MUI.Grid>
                        </MUI.Grid>
                        {parseInt(interactionForm.values.providerExperience) ===
                          ProviderExperienceTypes.SCA_NEEDED && (
                            <MUI.Grid item xs={12}>
                              <MUI.Typography variant="body2" color="textSecondary" className={classes.helperText}>
                                {alreadyOpenSCA()
                                  ? "There is already an open SCA"
                                  : "Creating this interaction will submit an SCA request to provider relations"}
                              </MUI.Typography>
                            </MUI.Grid>
                          )}
                      </div>
                      {tpaCalloutAdditionalFieldsEnable() && (
                        <div className={classes.row}>
                          <MUI.Grid container spacing={3}>
                            {initialValues.hasOwnProperty("pointOfContact") && (
                              <MUI.Grid item xs={4}>
                                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                                  Point of Contact
                                  {isOptionalLabel && (
                                    <span className={classes.optionalLabel}>
                                      {"  "}
                                      Optional
                                    </span>
                                  )}
                                </MUI.Typography>
                                <Field
                                  name="pointOfContact"
                                  component={FormikTextField}
                                  variant="outlined"
                                  fullWidth
                                  InputProps={{
                                    classes: {
                                      input: classes.textField
                                    }
                                  }}
                                />
                              </MUI.Grid>
                            )}
                            {initialValues.hasOwnProperty("referenceNumber") && (
                              <MUI.Grid item xs={4}>
                                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                                  Reference #
                                  {isOptionalLabel && (
                                    <span className={classes.optionalLabel}>
                                      {"  "}
                                      Optional
                                    </span>
                                  )}
                                </MUI.Typography>
                                <Field
                                  name="referenceNumber"
                                  component={FormikTextField}
                                  variant="outlined"
                                  fullWidth
                                  InputProps={{
                                    classes: {
                                      input: classes.textField
                                    }
                                  }}
                                />
                              </MUI.Grid>
                            )}
                            {initialValues.hasOwnProperty("contactDate") && (
                              <MUI.Grid item xs={4}>
                                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                                  Contact Date
                                </MUI.Typography>
                                <Field
                                  name="contactDate"
                                  component={FormikKeyboardDatePicker}
                                  fullWidth
                                  minDate={contactPickerMinDate}
                                  maxDate={contactPickerMaxDate}
                                  minDateMessage="Invalid field value"
                                  maxDateMessage="Future date is not allowed"
                                />
                              </MUI.Grid>
                            )}
                          </MUI.Grid>
                        </div>
                      )}
                      {isMemberBenefitsEnable() && <MemberBenefits viewMode={false} form={interactionForm} />}
                      {initialValues.hasOwnProperty("externalNote") && (
                        <div className={classes.row}>
                          <MUI.Grid container spacing={3}>
                            <MUI.Grid item xs={12}>
                              <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                                External Notes
                                {!isOptionalLabel && <span className={classes.requiredLabel}> *Required</span>}
                              </MUI.Typography>
                              <Field
                                name="externalNote"
                                component={FormikTextField}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={2}
                                rowsMax={2}
                                InputProps={{
                                  classes: {
                                    root: classes.multilineRoot,
                                    input: classes.textField
                                  }
                                }}
                              />
                            </MUI.Grid>
                          </MUI.Grid>
                        </div>
                      )}
                      <div className={classes.row}>
                        <MUI.Paper className={classes.paper}>
                          {initialValues.hasOwnProperty("followUpDate") && (
                            <div className={classes.row}>
                              <MUI.Grid container spacing={3}>
                                <MUI.Grid item xs={4}>
                                  <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                                    If necessary, follow up by
                                    {isOptionalLabel && (
                                      <span className={classes.optionalLabel}>
                                        {"  "}
                                        Optional
                                      </span>
                                    )}
                                  </MUI.Typography>
                                  <Field
                                    name="followUpDate"
                                    component={FormikKeyboardDatePicker}
                                    minDate={followUpPickerMinDate}
                                    maxDate={followUpPickerMaxDate}
                                    helperText={"Invalid field value"}
                                  />
                                </MUI.Grid>
                              </MUI.Grid>
                            </div>
                          )}
                          {initialValues.hasOwnProperty("internalNote") && (
                            <div className={classes.row}>
                              <MUI.Grid container spacing={3}>
                                <MUI.Grid item xs={12}>
                                  <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                                    Internal Notes
                                    {isOptionalLabel && (
                                      <span className={classes.optionalLabel}>
                                        {"  "}
                                        Optional
                                      </span>
                                    )}
                                  </MUI.Typography>
                                  <Field
                                    name="internalNote"
                                    component={FormikTextField}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    rowsMax={2}
                                    InputProps={{
                                      classes: {
                                        root: classes.multilineRoot,
                                        input: classes.textField
                                      }
                                    }}
                                  />
                                </MUI.Grid>
                              </MUI.Grid>
                            </div>
                          )}
                        </MUI.Paper>
                      </div>
                      {initialValues.hasOwnProperty("isCompleted") && (
                        <div className={clsx(classes.row, classes.switchRow)}>
                          <Field
                            name="isCompleted"
                            component={FormikSwitch}
                            label="Stage Completed?"
                            labelPlacement="start"
                          />
                        </div>
                      )}
                      {initialValues.hasOwnProperty("careDenied") && (
                        <div className={clsx(classes.checkBoxWrapper, classes.careDenialCheckbox)}>
                          <Field
                            name="careDenied"
                            component={FormikCheckbox}
                            label="Care Denial"
                            classes={{
                              disabled: classes.disabledCheckbox
                            }}
                            disabled={!isCareDenialEnabled()}
                          />
                        </div>
                      )}
                    </>
                  )}
                </MUI.DialogContent>
                <MUI.DialogActions>
                  <Button onClick={handleCloseNewInteractionModal} color="primary">
                    Cancel
                  </Button>
                  <div className={classes.progressWrapper}>
                    <Button
                      id="submit-button"
                      type="submit"
                      variant={isFormValid(interactionForm) ? "contained" : "text"}
                      color="primary"
                      autoFocus
                      disabled={!isFormValid(interactionForm) || isFormSubmitting}
                    >
                      Save
                    </Button>
                    {isFormSubmitting && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </MUI.DialogActions>
              </Form>
            </>
          )}
        </Formik>
      </MUI.Dialog>
    </>
  );
}

export default NewInteractionModal;
