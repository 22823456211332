import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  paper: {
    maxHeight: "calc(100vh - 460px)",
    border: "0",
    boxShadow: "none",
    background: "transparent"
  },
  rootContainer: {
    borderBottom: "1px solid rgba(224,224,224,1)"
  },
  tableRow: {
    borderLeft: "1px solid rgba(224,224,224,1)",
    borderRight: "1px solid rgba(224,224,224,1)"
  },
  tableHead: {
    padding: "6px 24px 6px 16px",
    fontSize: "0.75rem",
    backgroundColor: "rgba(240, 80, 37, 0.08)"
  },
  tableCell: {
    fontSize: "0.875rem",
    padding: "6px 24px 6px 16px",
    overflowY: "auto"
  },
  noOptions: {
    textAlign: "center",
    backgroundColor: "#e6eaed"
  }
}));
