import axios from "axios";
import SpecialtyModel from "models/specialty.model";
import { Action, action, thunk, Thunk } from "easy-peasy";
import { SpecialtyReducerModel } from "types";

const setSpecialties: Action<SpecialtyReducerModel, SpecialtyModel[]> = action(
  (state, specialties) => {
    state.specialties = specialties;
  }
);

const getSpecialties: Thunk<SpecialtyReducerModel> = thunk(
  async (actions, payload, { getState }) => {
    const { specialties } = getState();

    if (!!specialties.length) return;
    const response: any = await axios.get("/provider-taxonomy", {
      params: {
        shouldHaveProviders: true
      }
    });

    actions.setSpecialties(response.data);
  }
);

const SpecialtyReducer: SpecialtyReducerModel = {
  specialties: [],
  setSpecialties,
  getSpecialties
};

export default SpecialtyReducer;
