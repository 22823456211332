import differenceInYears from "date-fns/difference_in_years";
import { getFormattedDate } from "utils/dateTimeUtils";
import MemberModel from "models/member.model";

const getGender = (member: MemberModel) => {
  switch (member.gender) {
    case "F":
      return "Female";
    case "M":
      return "Male";
    default:
      return "";
  }
};

const getAge = (member: MemberModel) => {
  if (!member.dateOfBirth) return undefined;

  const dateOfBirth = new Date(member.dateOfBirth);
  return differenceInYears(new Date(), dateOfBirth);
};

const getMemberEligibility = (member: MemberModel) => {
  const { eligibility } = member;

  if (!eligibility || !eligibility.start) return "no data";

  const { start, end } = eligibility;

  return start && end
    ? `${getFormattedDate(start, "MM/DD/YYYY", "UTC")} - ${getFormattedDate(
        end,
        "MM/DD/YYYY",
        "UTC"
      )}`
    : getFormattedDate(start, "MM/DD/YYYY", "UTC");
};

export { getGender, getAge, getMemberEligibility };
