import fp from "fingerprintjs2";

let fingerprint: string | undefined = undefined;

const getFingerprint = (): Promise<string> => {
  return new Promise(resolve => {
    const options = {
      excludes: {
        userAgent: true,
        webdriver: true,
        language: true,
        colorDepth: true,
        deviceMemory: true,
        pixelRatio: true,
        hardwareConcurrency: true,
        screenResolution: true,
        availableScreenResolution: true,
        timezoneOffset: true,
        timezone: true,
        sessionStorage: true,
        localStorage: true,
        indexedDb: true,
        addBehavior: true,
        openDatabase: true,
        cpuClass: true,
        platform: true,
        doNotTrack: true,
        plugins: true,
        canvas: false,
        webgl: false,
        webglVendorAndRenderer: true,
        adBlock: true,
        hasLiedLanguages: true,
        hasLiedResolution: true,
        hasLiedOs: true,
        hasLiedBrowser: true,
        touchSupport: true,
        fonts: true,
        fontsFlash: true,
        audio: true,
        enumerateDevices: true
      }
    };

    if (fingerprint) return resolve(fingerprint);

    const fpGet = () => {
      fp.get(options, components => {
        const values = components.map(component => component.value);
        fingerprint = fp.x64hash128(values.join(""), 31);

        resolve(fingerprint);
      });
    };

    const { requestIdleCallback } = window as any;
    requestIdleCallback ? requestIdleCallback(fpGet) : setTimeout(fpGet, 500);
  });
};

export { getFingerprint };
