import axios from "axios";
import ClientModel from "models/client.model";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { ClientReducerModel } from "types";

const setClients: Action<ClientReducerModel, ClientModel[]> = action(
  (state, clients) => {
    state.clients = clients;
  }
);

const getClients: Thunk<ClientReducerModel> = thunk(
  async (actions, payload, { getState }) => {
    const { clients } = getState();

    if (!!clients.length) return;

    const response: ClientModel[] = await axios.get("/clients", {
      params: {
        isActive: true
      }
    });

    actions.setClients(response);
  }
);

const getClientById: Thunk<ClientReducerModel, string> = thunk(
  async (actions, clientId) => {
    const response: any = await axios.get(`/clients/${clientId}`);

    return response;
  }
);

const ClientReducer: ClientReducerModel = {
  clients: [],
  setClients,
  getClients,
  getClientById
};

export default ClientReducer;
