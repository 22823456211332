import * as React from "react";
import { Route, RouteProps } from "react-router-dom";
import AuthenticatedPage from "containers/AuthenticatedPage";
import { isTokenValid, isMFAToken } from "redux/reducers/authenticationReducer";
import { useStoreActions } from "redux/reducers/hooks";

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const logout = useStoreActions(actions => actions.auth.logout);
  const isValid = isTokenValid();
  const isMFA = isMFAToken();

  if (!isValid || isMFA) {
    logout(true);
  }

  return (
    <Route
      {...rest}
      render={routeProps =>
        isValid ? (
          <AuthenticatedPage>
            <Component {...routeProps} />
          </AuthenticatedPage>
        ) : null
      }
    />
  );
};

export default PrivateRoute;
