import React, { useState, useEffect, useRef } from "react";
import { MUI, ExpandText } from "@amps/material-ui";
import clsx from "clsx";
import { Formik, Form, Field, FormikProps } from "formik";
import * as Yup from "yup";
import addDays from "date-fns/add_days";
import useStyles from "components/RequestPage/styles/InteractionView.style";
import InteractionModel from "models/interaction.model";
import RequestModel from "models/request.model";
import ServiceModel from "models/service.model";
import InteractionTypes from "utils/constants/InteractionTypes";
import Stages from "utils/constants/Stages";
import { getAddressString } from "redux/reducers/providerReducer";

import {
  getDateTimeString,
  getDateString,
  getYupMinDate,
  getYupMaxDate,
  getPickerMinDate,
  getPickerMaxDate
} from "utils/dateTimeUtils";
import { hasEditorPermission } from "utils/permissionUtils";
import { getUserFullName } from "utils/userUtils";
import FormikTextField from "containers/FormikTextField";
import FormikKeyboardDatePicker from "containers/FormikKeyboardDatePicker";
import InteractionLabel from "components/RequestPage/InteractionLabel";
import { useStoreState, useStoreActions } from "redux/reducers/hooks";
import {
  Close,
  Accessibility,
  SupervisorAccount,
  Portrait,
  LocalHospital,
  PhonelinkRing,
  PhoneEnabled,
  Edit,
  Delete,
  Check
} from "@material-ui/icons";

interface InteractionViewProps {
  interaction: InteractionModel;
  request: RequestModel;
  service: ServiceModel;
}

interface ExternalNoteFormValues {
  externalNote: string;
}

interface InternalNoteFormValues {
  internalNote: string | undefined;
}

interface FollowUpFormValues {
  followUpDate?: Date | string | null;
}

const ExternalNoteFormSchema = Yup.object().shape({
  externalNote: Yup.string()
    .required("Required")
    .max(2000, "Must be 2000 characters or less")
});

const InternalNoteFormSchema = Yup.object().shape({
  internalNote: Yup.string().max(2000, "Must be 2000 characters or less")
});

export function InteractionView(props: InteractionViewProps) {
  const { interaction, request, service } = props;
  const classes = useStyles();
  const [isExternalNoteEditing, setIsExternalNoteEditing] = useState(false);
  const [isInternalNoteEditing, setIsInternalNoteEditing] = useState(false);
  const [isFollowUpEditing, setIsFollowUpEditing] = useState(false);
  const [isFollowUpSubmitting, setIsFollowupSubmitting] = useState(false);
  const [isExternalNoteSubmitting, setIsExternalNoteSubmitting] = useState(false);
  const [isInternalNoteSubmitting, setIsInternalNoteSubmitting] = useState(false);
  const getRequestById = useStoreActions(actions => actions.request.getRequestById);
  const updateInteraction = useStoreActions(actions => actions.interaction.updateInteraction);
  const loggedInUser = useStoreState(state => state.auth.user);

  const externalNoteFormRef = useRef(null);
  const internalNoteFormRef = useRef(null);
  const followUpFormRef = useRef(null);

  const followUpMinDate = addDays(new Date(), 1);
  const followUpYupMinDate = getYupMinDate(followUpMinDate);
  const followUpPickerMinDate = getPickerMinDate(followUpMinDate);
  const followUpYupMaxDate = getYupMaxDate();
  const followUpPickerMaxDate = getPickerMaxDate();
  const FollowUpFormSchema = Yup.object().shape({
    followUpDate: Yup.date()
      .nullable()
      .min(followUpYupMinDate)
      .max(followUpYupMaxDate)
  });
  const permissions = useStoreState(state => state.auth.permissions);

  useEffect(() => {
    if (!service.closingReason) return;

    (externalNoteFormRef.current as any).resetForm();
    (internalNoteFormRef.current as any).resetForm();
    followUpFormRef && followUpFormRef.current && (followUpFormRef.current as any).resetForm();

    setIsExternalNoteEditing(false);
    setIsInternalNoteEditing(false);
    setIsFollowUpEditing(false);
  }, [service.closingReason]);

  const getIconByType = (type: string) => {
    switch (type) {
      case "member":
        return <Accessibility fontSize="small" />;
      case "tpa":
        return <SupervisorAccount fontSize="small" />;
      case "vendor":
        return <Portrait fontSize="small" />;
      case "provider":
        return <LocalHospital fontSize="small" />;
      case "called":
        return <PhonelinkRing fontSize="small" />;
      default:
        return <PhoneEnabled fontSize="small" />;
    }
  };

  const renderInteractionIcons = () => {
    const type = interaction.type.name.toLowerCase();
    const [typeName, typeAction] = type.split(" ");

    return (
      <>
        {getIconByType(typeName)}
        {getIconByType(typeAction)}
      </>
    );
  };

  const canEditExternalNote = () => {
    if (!loggedInUser) return false;

    return loggedInUser.id.toString() === interaction.externalNoteCreatedBy.id.toString();
  };

  const canEditInternalNote = () => {
    if (!loggedInUser) return false;

    if (!interaction.internalNoteCreatedBy) return true;

    return loggedInUser.id.toString() === interaction.internalNoteCreatedBy.id.toString();
  };

  const renderEditExternalNoteButton = () =>
    !isExternalNoteEditing && (!service.closingReason || hasEditorPermission(permissions));

  const renderEditInternalNoteButton = () =>
    !isInternalNoteEditing && (!service.closingReason || hasEditorPermission(permissions));

  const renderDeleteInternalNoteButton = () =>
    interaction.internalNote && (!service.closingReason || hasEditorPermission(permissions));

  const getExternalNoteEditorPermission = () => canEditExternalNote() || hasEditorPermission(permissions);

  const getInternalNoteEditorPermission = () => canEditInternalNote() || hasEditorPermission(permissions);

  const getBackgroundColorClass = () => {
    const stage = interaction.serviceStage.name;

    switch (stage) {
      case Stages.GATHERING_INFORMATION:
        return classes.gatheringInfoPaper;
      case Stages.LOCATING_FACILITY:
        return classes.locatingFacilityPaper;
      case Stages.CREATING_APPOINTMENT:
        return classes.creatingAppointmentPaper;
      case Stages.FINAL_FOLLOWUP:
        return classes.finalFollowupPaper;
    }
  };

  const resetExternalNoteEdit = (externalNoteForm: FormikProps<ExternalNoteFormValues>) => {
    externalNoteForm.resetForm();
    setIsExternalNoteEditing(false);
  };

  const handleExternalNoteEdit = async (values: ExternalNoteFormValues) => {
    setIsExternalNoteSubmitting(true);

    try {
      await updateInteraction({ id: interaction.id, data: values });
      await getRequestById(request.id);

      setIsExternalNoteEditing(false);
    } finally {
      setIsExternalNoteSubmitting(false);
    }
  };

  const resetInternalNoteEdit = (internalNoteForm: FormikProps<InternalNoteFormValues>) => {
    internalNoteForm.resetForm();
    setIsInternalNoteEditing(false);
  };

  const resetFollowUpEdit = (followUpForm: FormikProps<FollowUpFormValues>) => {
    followUpForm.resetForm();
    setIsFollowUpEditing(false);
  };

  const handleInternalNoteEdit = async (values: InternalNoteFormValues) => {
    setIsInternalNoteSubmitting(true);

    try {
      const { internalNote } = values;
      const data = {
        internalNote: internalNote || null
      };

      await updateInteraction({ id: interaction.id, data });
      await getRequestById(request.id);

      setIsInternalNoteEditing(false);
    } finally {
      setIsInternalNoteSubmitting(false);
    }
  };

  const handleFollowUpEdit = async (values: FollowUpFormValues) => {
    setIsFollowupSubmitting(true);

    try {
      const { followUpDate } = values;

      const data = {
        followUp: followUpDate ? new Date(followUpDate) : null
      };

      await updateInteraction({ id: interaction.id, data });
      await getRequestById(request.id);

      setIsFollowUpEditing(false);
    } finally {
      setIsFollowupSubmitting(false);
    }
  };

  const handleRemoveInternalNote = async () => {
    const data = {
      internalNote: null
    };
    await updateInteraction({ id: interaction.id, data });
    await getRequestById(request.id);

    setIsInternalNoteEditing(false);
  };

  const handleRemoveFollowUp = async () => {
    const data = {
      followUp: null
    };
    await updateInteraction({ id: interaction.id, data });
    await getRequestById(request.id);

    setIsFollowUpEditing(false);
  };

  const areTpaFieldsRequired = () => {
    return (
      interaction.type.name === InteractionTypes.TPA_CALLOUT &&
      interaction.serviceStage.name === Stages.GATHERING_INFORMATION
    );
  };

  const areProviderFieldsRequired = () => {
    const interactionType = interaction.type.name;

    return (
      interaction.serviceStage.name === Stages.LOCATING_FACILITY &&
      (interactionType === InteractionTypes.PROVIDER_CALLOUT ||
        interactionType === InteractionTypes.PROVIDER_CALLBACK ||
        interactionType === InteractionTypes.MEMBER_CALLOUT ||
        interactionType === InteractionTypes.MEMBER_CALLBACK)
    );
  };

  const isVendorProviderOnlyRequired = () => {
    const interactionType = interaction.type.name;

    return (
      interaction.serviceStage.name === Stages.CREATING_APPOINTMENT &&
      (interactionType === InteractionTypes.VENDOR_CALLOUT || interactionType === InteractionTypes.VENDOR_CALLBACK)
    );
  };

  const isProviderRequired = () => {
    return areProviderFieldsRequired() || isVendorProviderOnlyRequired();
  };

  return (
    <MUI.Paper className={clsx(classes.paper, getBackgroundColorClass())}>
      <MUI.Grid container spacing={2}>
        <MUI.Grid item xs={7}>
          <MUI.Grid container spacing={0} justify="space-between" alignItems="center">
            <div className={classes.titleWrapper}>
              {renderInteractionIcons()}
              <b className={classes.title}> {interaction.type.name.toUpperCase()}</b>
            </div>
            {interaction.label && <InteractionLabel interaction={interaction} />}
          </MUI.Grid>
          <div>
            Stage:{" "}
            <MUI.Chip
              label={`${interaction.serviceStage.id}. ${interaction.serviceStage.name}`}
              size="small"
              className={classes.chip}
            />
          </div>
        </MUI.Grid>
        <MUI.Grid item xs={5} className={classes.helperGrid}>
          <div className={clsx(classes.helperBox, classes.helperText)}>
            <span>Created on {getDateTimeString(interaction.createdOn)}</span>
            <div>By agent: {getUserFullName(interaction.createdBy)}</div>
          </div>
        </MUI.Grid>
        {isProviderRequired() && (
          <MUI.Grid item xs={12}>
            <MUI.Typography variant="body2" color="textSecondary">
              Provider:
              <span className={classes.regularText}> {interaction.provider ? interaction.provider.name : "—"}</span>
            </MUI.Typography>
            <MUI.Typography variant="body2" color="textSecondary">
              Provider Type name:
              <span className={classes.regularText}>
                {" "}
                {interaction.taxonomy && interaction.taxonomy.typeName ? interaction.taxonomy.typeName : "—"}
              </span>
            </MUI.Typography>
            <MUI.Typography variant="body2" color="textSecondary">
              Classification:
              <span className={classes.regularText}>
                {" "}
                {interaction.taxonomy && interaction.taxonomy.classification
                  ? interaction.taxonomy.classification
                  : "—"}
              </span>
            </MUI.Typography>
            <MUI.Typography variant="body2" color="textSecondary">
              Specialization:
              <span className={classes.regularText}>
                {" "}
                {interaction.taxonomy && interaction.taxonomy.specialization
                  ? interaction.taxonomy.specialization
                  : "—"}
              </span>
            </MUI.Typography>
            <MUI.Typography variant="body2" color="textSecondary">
              Address:
              <span className={classes.regularText}>
                {" "}
                {interaction.provider ? getAddressString(interaction.provider) : "—"}
              </span>
            </MUI.Typography>
            {!isVendorProviderOnlyRequired() && (
              <MUI.Typography variant="body2" color="textSecondary">
                Experience:
                <span className={classes.regularText}>
                  {" "}
                  {interaction.providerExperienceType ? interaction.providerExperienceType.name : "—"}
                </span>
              </MUI.Typography>
            )}
            {interaction.scaRequest && (
              <>
                <MUI.Typography variant="body2" color="textSecondary">
                  SCA Request Status:
                  <span className={classes.regularText}>
                    {" "}
                    {interaction.scaRequest.zohoStatus ? interaction.scaRequest.zohoStatus.name : "—"}
                  </span>
                </MUI.Typography>
                <MUI.Typography variant="body2" color="textSecondary">
                  SCA Request Resolution Explanation:
                  <span className={classes.regularText}> {interaction.scaRequest.resolutionExplanation || "—"}</span>
                </MUI.Typography>
                {interaction.scaRequest.crmLink && (
                  <MUI.Typography variant="body2">
                    <MUI.Link href={interaction.scaRequest.crmLink} target="_blank" rel="noreferrer">
                      View SCA Provider Request
                    </MUI.Link>
                  </MUI.Typography>
                )}
              </>
            )}
          </MUI.Grid>
        )}

        {areTpaFieldsRequired() && (
          <MUI.Grid item xs={12}>
            <MUI.Typography variant="body2" color="textSecondary">
              Point of Contact:
              <span className={classes.regularText}> {interaction.pointOfContact || "—"}</span>
            </MUI.Typography>
            <MUI.Typography variant="body2" color="textSecondary">
              Reference Number:
              <span className={classes.regularText}> {interaction.referenceNumber || "—"}</span>
            </MUI.Typography>
            <MUI.Typography variant="body2" color="textSecondary">
              Contact Date:
              <span className={classes.regularText}>
                {" "}
                {interaction.contactDate ? getDateString(interaction.contactDate) : "—"}
              </span>
            </MUI.Typography>
          </MUI.Grid>
        )}

        {interaction.vendorName && (
          <MUI.Grid item xs={12}>
            <MUI.Typography variant="body2" color="textSecondary">
              Vendor:
              <span className={classes.regularText}> {interaction.vendorName}</span>
            </MUI.Typography>
          </MUI.Grid>
        )}

        {interaction.serviceStage.name !== Stages.FINAL_FOLLOWUP && (
          <MUI.Grid item xs={12}>
            <MUI.Typography variant="body2" color="textSecondary">
              If necessary, follow up by
            </MUI.Typography>
            {!interaction.followUp && !isFollowUpEditing && (
              <MUI.Grid container spacing={0} justify="flex-start" alignItems="center">
                <b>—</b>
                {!service.closingReason && (
                  <MUI.IconButton
                    name="edit-followup-button"
                    className={classes.editButton}
                    size="small"
                    onClick={() => setIsFollowUpEditing(true)}
                  >
                    <Edit />
                  </MUI.IconButton>
                )}
              </MUI.Grid>
            )}
            {(interaction.followUp || isFollowUpEditing) && (
              <>
                <Formik
                  enableReinitialize
                  initialValues={{
                    followUpDate: interaction.followUp || null
                  }}
                  isInitialValid={true}
                  validationSchema={FollowUpFormSchema}
                  onSubmit={handleFollowUpEdit}
                  ref={followUpFormRef}
                >
                  {followUpForm => (
                    <Form>
                      <MUI.Grid container spacing={0} justify="flex-start" alignItems="center">
                        <Field
                          name="followUpDate"
                          component={FormikKeyboardDatePicker}
                          minDate={followUpPickerMinDate}
                          maxDate={followUpPickerMaxDate}
                          disabled={!isFollowUpEditing || isFollowUpSubmitting}
                          helperText={"Invalid field value"}
                        />
                        {!isFollowUpEditing && !service.closingReason && (
                          <>
                            <MUI.IconButton
                              name="edit-followup-button"
                              className={classes.editButton}
                              size="small"
                              onClick={() => setIsFollowUpEditing(true)}
                            >
                              <Edit />
                            </MUI.IconButton>
                            <MUI.Divider className={classes.divider} />
                            <MUI.IconButton
                              name="delete-followup-button"
                              className={classes.editButton}
                              size="small"
                              onClick={handleRemoveFollowUp}
                            >
                              <Delete />
                            </MUI.IconButton>
                          </>
                        )}
                        {isFollowUpEditing && (
                          <>
                            <div className={classes.progressWrapper}>
                              <MUI.IconButton
                                className={classes.editButton}
                                size="small"
                                onClick={() => {
                                  followUpForm.isValid && followUpForm.submitForm();
                                }}
                                disabled={isFollowUpSubmitting}
                              >
                                <Check />
                              </MUI.IconButton>
                              {isFollowUpSubmitting && (
                                <MUI.CircularProgress size={24} className={classes.buttonProgress} />
                              )}
                            </div>
                            <MUI.Divider className={classes.divider} />
                            <MUI.IconButton
                              size="small"
                              className={classes.editButton}
                              onClick={() => resetFollowUpEdit(followUpForm)}
                            >
                              <Close />
                            </MUI.IconButton>
                          </>
                        )}
                      </MUI.Grid>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </MUI.Grid>
        )}

        <MUI.Grid item xs={6}>
          <Formik
            enableReinitialize
            initialValues={{
              externalNote: interaction.externalNote
            }}
            validationSchema={ExternalNoteFormSchema}
            onSubmit={handleExternalNoteEdit}
            ref={externalNoteFormRef}
          >
            {externalNoteForm => (
              <Form>
                <MUI.Grid container spacing={0} alignItems="center">
                  <MUI.Typography variant="body2" color="textSecondary">
                    <b>External Notes</b>
                  </MUI.Typography>
                  {renderEditExternalNoteButton() && (
                    <>
                      <MUI.Tooltip
                        title={getExternalNoteEditorPermission() ? "" : "Editable only by author of the note"}
                        placement="top"
                      >
                        <span>
                          <MUI.IconButton
                            name="edit-external-note-button"
                            className={classes.editButton}
                            size="small"
                            onClick={() => setIsExternalNoteEditing(true)}
                            disabled={!getExternalNoteEditorPermission()}
                          >
                            <Edit />
                          </MUI.IconButton>
                        </span>
                      </MUI.Tooltip>
                    </>
                  )}
                  {isExternalNoteEditing && (
                    <>
                      <div className={classes.progressWrapper}>
                        <MUI.IconButton
                          className={classes.editButton}
                          type="submit"
                          size="small"
                          name="submit-external-note-button"
                          disabled={isExternalNoteSubmitting}
                        >
                          <Check />
                        </MUI.IconButton>
                        {isExternalNoteSubmitting && (
                          <MUI.CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                      </div>
                      <MUI.Divider className={classes.divider} />
                      <MUI.IconButton
                        className={classes.editButton}
                        size="small"
                        onClick={() => resetExternalNoteEdit(externalNoteForm)}
                      >
                        <Close />
                      </MUI.IconButton>
                    </>
                  )}
                </MUI.Grid>

                {!isExternalNoteEditing && (
                  <ExpandText
                    lines={2}
                    text={interaction.externalNote}
                    containerClasses={classes.expandNoteContainer}
                  />
                )}

                {isExternalNoteEditing && (
                  <Field
                    name="externalNote"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    multiline
                    disabled={!isExternalNoteEditing || isExternalNoteSubmitting}
                    InputProps={{
                      classes: {
                        root: classes.multilineRoot,
                        input: classes.textField,
                        disabled: classes.disabledTextField
                      }
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </MUI.Grid>

        <MUI.Grid item xs={6}>
          <Formik
            enableReinitialize
            initialValues={{
              internalNote: interaction.internalNote || undefined
            }}
            validationSchema={InternalNoteFormSchema}
            onSubmit={handleInternalNoteEdit}
            ref={internalNoteFormRef}
          >
            {internalNoteForm => (
              <Form>
                <MUI.Grid container spacing={0} alignItems="center">
                  <MUI.Typography variant="body2" color="textSecondary">
                    <b>Internal Notes</b>
                  </MUI.Typography>
                  {renderEditInternalNoteButton() && (
                    <>
                      <MUI.Tooltip
                        title={getInternalNoteEditorPermission() ? "" : "Editable only by author of the note"}
                        placement="top"
                      >
                        <span>
                          <MUI.IconButton
                            name="edit-internal-note-button"
                            className={classes.editButton}
                            size="small"
                            onClick={() => setIsInternalNoteEditing(true)}
                            disabled={!getInternalNoteEditorPermission()}
                          >
                            <Edit />
                          </MUI.IconButton>
                        </span>
                      </MUI.Tooltip>
                      {renderDeleteInternalNoteButton() && (
                        <>
                          <MUI.Divider className={classes.divider} />
                          <MUI.Tooltip
                            title={getInternalNoteEditorPermission() ? "" : "Editable only by author of the note"}
                            placement="top"
                          >
                            <span>
                              <MUI.IconButton
                                name="delete-internal-note-button"
                                className={classes.editButton}
                                size="small"
                                onClick={handleRemoveInternalNote}
                                disabled={!getInternalNoteEditorPermission()}
                              >
                                <Delete />
                              </MUI.IconButton>
                            </span>
                          </MUI.Tooltip>
                        </>
                      )}
                    </>
                  )}
                  {isInternalNoteEditing && (
                    <>
                      <div className={classes.progressWrapper}>
                        <MUI.IconButton
                          className={classes.editButton}
                          type="submit"
                          size="small"
                          disabled={isInternalNoteSubmitting}
                        >
                          <Check />
                        </MUI.IconButton>
                        {isInternalNoteSubmitting && (
                          <MUI.CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                      </div>
                      <MUI.Divider className={classes.divider} />
                      <MUI.IconButton
                        className={classes.editButton}
                        size="small"
                        onClick={() => resetInternalNoteEdit(internalNoteForm)}
                      >
                        <Close />
                      </MUI.IconButton>
                    </>
                  )}
                </MUI.Grid>

                {!isInternalNoteEditing && (
                  <>
                    {!interaction.internalNote ? (
                      <b>—</b>
                    ) : (
                      <>
                        <ExpandText
                          lines={2}
                          text={interaction.internalNote}
                          containerClasses={classes.expandNoteContainer}
                        />
                      </>
                    )}
                  </>
                )}

                {isInternalNoteEditing && (
                  <Field
                    name="internalNote"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    multiline
                    disabled={!isInternalNoteEditing || isInternalNoteSubmitting}
                    InputProps={{
                      classes: {
                        root: classes.multilineRoot,
                        input: classes.textField,
                        disabled: classes.disabledTextField
                      }
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </MUI.Grid>
      </MUI.Grid>
    </MUI.Paper>
  );
}

export default InteractionView;
