import React, { useState, useRef, useEffect } from "react";
import { MUI, SideIconButton } from "@amps/material-ui";
import * as Yup from "yup";
import MemberModel from "models/member.model";
import MemberNoteModel from "models/memberNote.model";
import MemberNote from "components/RequestPage/MemberNote";
import useStyles from "components/RequestPage/styles/MemberProfileNotes.style";
import { Formik, Form, Field, FormikProps } from "formik";
import FormikTextField from "containers/FormikTextField";
import { useStoreActions } from "redux/reducers/hooks";
import { ExpandMore, NoteAdd, Check, Close } from "@material-ui/icons";

interface NewNoteFormValues {
  noteBody: string;
  noteTitle: string;
}

const NewNoteFormSchema = Yup.object().shape({
  noteBody: Yup.string()
    .required("Required")
    .max(2000, "Must be 2000 characters or less"),
  noteTitle: Yup.string()
    .required("Required")
    .max(2000, "Must be 2000 characters or less")
});

type Props = {
  member: MemberModel;
};

export function MemberProfileNotes(props: Props) {
  const { member } = props;
  const classes = useStyles();
  const [memberNotes, setMemberNotes] = useState([] as MemberNoteModel[]);
  const [isAddingMode, setIsAddingMode] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { addNote, getMemberById, updateMemberNotes } = useStoreActions(actions => actions.member);
  const newNoteFormRef = useRef(null);

  useEffect(() => {
    if (isExpanded) return;

    setIsAddingMode(false);
    if (newNoteFormRef.current) (newNoteFormRef.current as any).resetForm();
  }, [isExpanded]);

  useEffect(() => {
    const { notes } = member;

    if (!notes || !notes.length) return;

    setMemberNotes(notes);
  }, [member]);

  const resetNewNote = (newNoteForm: FormikProps<NewNoteFormValues>) => {
    newNoteForm.resetForm();
    setIsAddingMode(false);
  };

  const handleNewNote = async (values: NewNoteFormValues, newNoteForm: any) => {
    setIsFormSubmitting(true);
    setAreButtonsDisabled(true);

    try {
      await addNote({ memberId: member.id, noteBody: values.noteBody, noteTitle: values.noteTitle });
      const response = await getMemberById(member.id);

      setMemberNotes(response.notes);
      resetNewNote(newNoteForm);
    } finally {
      setIsFormSubmitting(false);
      setAreButtonsDisabled(false);
    }
  };

  const handleUpdateMemberNotes = async (noteIndex: number, newNote?: NewNoteFormValues) => {
    if (!newNote) {
      removeMemberNote(noteIndex);
      return;
    }

    setAreButtonsDisabled(true);

    try {
      const updatedMemberNotes = memberNotes.map((memberNote: any, index: number) => {
        if (index === noteIndex) {
          return {
            ...memberNote,
            body: newNote.noteBody,
            title: newNote.noteTitle,
            isUpdated: true
          };
        }

        return memberNote;
      });
      await updateMemberNotes({
        memberId: member.id,
        notes: updatedMemberNotes
      });
      const response = await getMemberById(member.id);

      setMemberNotes(response.notes);
    } finally {
      setAreButtonsDisabled(false);
    }
  };

  const removeMemberNote = async (noteIndex: number) => {
    setAreButtonsDisabled(true);

    try {
      const updatedMemberNotes = memberNotes.filter(
        (memberNote: MemberNoteModel, index: number) => index !== noteIndex
      );

      const response = await updateMemberNotes({
        memberId: member.id,
        notes: updatedMemberNotes
      });

      setMemberNotes(response);
    } finally {
      setAreButtonsDisabled(false);
    }
  };

  return (
    <>
      <MUI.Accordion
        classes={{
          root: classes.expPanel
        }}
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
      >
        <MUI.AccordionSummary expandIcon={<ExpandMore />}>
          <MUI.Grid container spacing={0} alignItems="center" className={classes.panelTitle}>
            <span>
              <b>Member Notes</b>
            </span>
          </MUI.Grid>
        </MUI.AccordionSummary>
        <MUI.Divider variant="middle" />
        <MUI.AccordionDetails>
          <MUI.Grid container spacing={0} alignItems="center">
            <MUI.Grid item xs={12} className={classes.newNoteWrapper}>
              <Formik
                enableReinitialize
                initialValues={{
                  noteBody: "",
                  noteTitle: ""
                }}
                validationSchema={NewNoteFormSchema}
                onSubmit={handleNewNote}
                ref={newNoteFormRef}
              >
                {newNoteForm => (
                  <Form>
                    <MUI.Grid container spacing={0} alignItems="center">
                      {!isAddingMode && (
                        <MUI.Grid item xs={12}>
                          <SideIconButton
                            id="new-note-button"
                            variant="contained"
                            color="primary"
                            Icon={NoteAdd}
                            align="left"
                            onClick={() => setIsAddingMode(true)}
                          >
                            New Note
                          </SideIconButton>
                        </MUI.Grid>
                      )}
                    </MUI.Grid>

                    {isAddingMode && (
                      <MUI.Grid container spacing={0} alignItems="center">
                        <MUI.Grid item xs={12}>
                          <Field
                            name="noteTitle"
                            component={FormikTextField}
                            variant="outlined"
                            fullWidth
                            rows={2}
                            placeholder="Note Title"
                            rowsMax={1}
                            disabled={isFormSubmitting}
                            InputProps={{
                              classes: {
                                root: classes.multilineRoot,
                                input: classes.textField,
                                disabled: classes.disabledTextField
                              }
                            }}
                          />
                        </MUI.Grid>
                        <MUI.Grid item xs={12}>
                          <Field
                            name="noteBody"
                            component={FormikTextField}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={2}
                            placeholder="Note Body"
                            rowsMax={2}
                            disabled={isFormSubmitting}
                            InputProps={{
                              classes: {
                                root: classes.multilineRoot,
                                input: classes.textField,
                                disabled: classes.disabledTextField
                              }
                            }}
                          />
                        </MUI.Grid>
                        <MUI.Grid item xs={12}>
                          <div className={classes.addButtons}>
                            <div className={classes.progressWrapper}>
                              <MUI.IconButton
                                id="submit-note-button"
                                className={classes.editButton}
                                type="submit"
                                size="small"
                                disabled={isFormSubmitting || areButtonsDisabled}
                              >
                                <Check />
                              </MUI.IconButton>
                              {isFormSubmitting && (
                                <MUI.CircularProgress size={24} className={classes.buttonProgress} />
                              )}
                            </div>
                            <MUI.Divider className={classes.divider} />
                            <MUI.IconButton
                              className={classes.editButton}
                              size="small"
                              disabled={areButtonsDisabled}
                              onClick={() => resetNewNote(newNoteForm)}
                            >
                              <Close />
                            </MUI.IconButton>
                          </div>
                        </MUI.Grid>
                      </MUI.Grid>
                    )}
                  </Form>
                )}
              </Formik>
            </MUI.Grid>
            <MUI.Grid item xs={12} className={classes.notesWrapper}>
              {!!memberNotes.length &&
                memberNotes.map((note, index) => (
                  <MemberNote
                    note={note}
                    noteIndex={index}
                    areButtonsDisabled={areButtonsDisabled}
                    updateMemberNotes={handleUpdateMemberNotes}
                    key={`member${member.id}-note${note.id}`}
                  />
                ))}
            </MUI.Grid>
          </MUI.Grid>
        </MUI.AccordionDetails>
      </MUI.Accordion>
    </>
  );
}

export default MemberProfileNotes;
