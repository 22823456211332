import { Action, Thunk, action, thunk } from "easy-peasy";
import axios from "axios";
import UserModel from "models/user.model";
import { authChannel } from "utils/broadcastAuthChannel";
import jwt_decode from "jwt-decode";
import { history } from "Router";
import { AuthenticationReducerModel, TokenModel, LoginModel } from "types";
import { MFAModel, portalSSoModel } from "types/AuthenticationTypes";

export const isTokenValid = (): boolean => {
  const jwtToken = sessionStorage.getItem("jwtToken");

  if (!jwtToken) return false;

  const user: UserModel = jwt_decode(jwtToken);
  const safeShift = 15000; // 15 sec
  const currentTime = Date.now();

  return currentTime < user.exp * 1000 - safeShift;
};

export const isMFAToken = (): boolean => {
  const jwtToken = sessionStorage.getItem("jwtToken");

  if (!jwtToken) return false;

  const user: UserModel = jwt_decode(jwtToken);

  return user.isMfa ? user.isMfa : false;
};

const validateSessionStorage = () => {
  // https://advancedpricing.atlassian.net/browse/DA-790
  // clear session after redirecting back from another website
  const lastUnload = sessionStorage.getItem("lastUnload");
  const timeShift = 10000; // 10 sec
  const currentTime = Date.now();

  if (lastUnload && currentTime > parseInt(lastUnload) + timeShift) {
    sessionStorage.removeItem("jwtToken");
  }
};
validateSessionStorage();

const getMfaToken: Thunk<AuthenticationReducerModel, LoginModel> = thunk(async (actions, payload) => {
  const response: TokenModel = await axios.put("/auth", {
    ...payload
  });

  if (response && response.jwtToken) {
    actions.setMFAToken(response.jwtToken);
  }
});

const setToken: Action<AuthenticationReducerModel, string> = action((state, jwtToken) => {
  state.user = jwt_decode(jwtToken);

  authChannel.postMessage({
    key: "JWT_TOKEN",
    data: jwtToken
  });

  sessionStorage.setItem("jwtToken", jwtToken);
});

const setMFAToken: Action<AuthenticationReducerModel, string> = action((state, jwtToken) => {
  authChannel.postMessage({
    key: "JWT_TOKEN",
    data: jwtToken
  });

  sessionStorage.setItem("jwtToken", jwtToken);
});

const removeToken: Action<AuthenticationReducerModel> = action((state, payload) => {
  // notify about logging out
  authChannel.postMessage({
    key: "LOGOUT"
  });

  sessionStorage.removeItem("jwtToken");
  state.user = null;
  state.permissions = [];
});

const setPermissions: Action<AuthenticationReducerModel, string[]> = action((state, payload) => {
  state.permissions = payload;
});

const login: Thunk<AuthenticationReducerModel, MFAModel> = thunk(async (actions, payload) => {
  const {
    location: { state },
    push
  } = history;
  const redirectUrl = (state && state.referrer) || "/";
  const response: TokenModel = await axios.put("/auth/mfa", {
    ...payload
  });

  if (response && response.jwtToken) {
    actions.setToken(response.jwtToken);
    actions.getPermissions();
    push(redirectUrl);
  }
});


const portalSso: Thunk<AuthenticationReducerModel, portalSSoModel> = thunk(async (actions, payload) => {
  const {
    location: { state },
    push
  } = history;
  const redirectUrl = (state && state.referrer) || "/";
  const response: any = await axios.post("/auth/portalLogin", {
    code: payload.userCode,
    clientId:payload.clientId,
    groupId:payload.groupId
  });
  return response
});

const logout: Thunk<AuthenticationReducerModel, boolean> = thunk((actions, redirect) => {
  actions.removeToken();

  const state = redirect ? { referrer: window.location.pathname } : null;

  history.push("/login", state);
});

const getPermissions: Thunk<AuthenticationReducerModel, void> = thunk(async (actions, payload, { getState }) => {
  const { user } = getState();
  let userId = null;

  if (user) {
    userId = user.id;
  } else if (!isMFAToken()) {
    const jwtToken = sessionStorage.getItem("jwtToken");
    const userFromToken: UserModel | null = jwtToken ? jwt_decode(jwtToken) : null;

    if (userFromToken) userId = userFromToken.id;
  }

  if (!userId) return;

  const response: any = await axios.get("/users", {
    params: {
      userIds: [userId]
    }
  });
  actions.setPermissions(response.data[0].permissions);
});

const renew: Thunk<AuthenticationReducerModel> = thunk(async actions => {
  try {
    const response: TokenModel = await axios.put("/auth/renew", {
      refreshToken: sessionStorage.getItem("jwtToken")
    });

    if (response && response.jwtToken) {
      actions.setToken(response.jwtToken);
    }
  } catch (error) {
    actions.logout(false);
    throw error;
  }
});

const updateToken: Thunk<AuthenticationReducerModel, string> = thunk(async (actions, jwtToken) => {
  const sessionToken = sessionStorage.getItem("jwtToken");

  if (sessionToken) actions.setToken(jwtToken);
});

const resendCode: Thunk<AuthenticationReducerModel, void> = thunk(async actions => {
  await axios.put("/auth/resend");
});

let jwtToken = sessionStorage.getItem("jwtToken");

const AuthenticationReducer: AuthenticationReducerModel = {
  user: jwtToken && !isMFAToken() ? jwt_decode(jwtToken) : null,
  permissions: [],
  getMfaToken,
  getPermissions,
  login,
  logout,
  resendCode,
  removeToken,
  renew,
  setMFAToken,
  setToken,
  setPermissions,
  updateToken,
  portalSso
};

export default AuthenticationReducer;
