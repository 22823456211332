import React from "react";
import { MUI } from "@amps/material-ui";

import InteractionModel from "models/interaction.model";

const useStyles = MUI.makeStyles(theme => ({
  interactionLabel: {
    fontWeight: 600,
    borderRadius: "4px",
    backgroundColor: "rgba(161,35,16,0.25)"
  }
}));

interface Props {
  interaction: InteractionModel;
}

function InteractionLabel(props: Props) {
  const classes = useStyles();

  if (!props.interaction.label) return null;

  const {
    interaction: {
      label: { name }
    }
  } = props;

  return <MUI.Chip label={name} size="small" className={classes.interactionLabel} />;
}

export default InteractionLabel;
