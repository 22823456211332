import React, { useEffect, useState } from "react";
import { MUI } from "@amps/material-ui";
import clsx from "clsx";

import useStyles from "components/DashboardPage/styles/RequestsTable.style";

type Header = {
  id: string;
  align: "left" | "right";
  label: string;
};

const headers: Header[] = [
  { id: "Priority", align: "left", label: "Priority" },
  { id: "id", align: "left", label: "Request ID" },
  { id: "status", align: "left", label: "Status" },
  { id: "service", align: "left", label: "Service" },
  { id: "currentStage", align: "left", label: "Current Stage" },
  {
    id: "nearestFollowUp",
    align: "left",
    label: "Followup Date"
  },
  {
    id: "vip",
    align: "left",
    label: "VIP"
  },
  { id: "member", align: "left", label: "Member Name" },
  { id: "client", align: "left", label: "Client Group" },
  {
    id: "updatedBy",
    align: "left",
    label: "Updated By"
  }
];

type TableHeadProps = {
  order: "asc" | "desc";
  defaultOrder: "asc" | "desc";
  orderBy: string;
  onRequestSort: Function;
};

export default function RequestsTableHead(props: TableHeadProps) {
  const classes = useStyles();
  const { order, defaultOrder, orderBy, onRequestSort } = props;
  const [topPosition, setTopPosition] = useState("0");
  const [hideSortIcon, setHideSortIcon] = useState(false);

  useEffect(() => {
    // Fix for IE
    setTopPosition("1px");

    setTimeout(() => {
      setTopPosition("0");
    }, 0);

    setHideSortIcon(!orderBy);
  }, [order, orderBy]);

  const handleMouseLeave = () => {
    setTimeout(() => {
      setHideSortIcon(false);
    }, 150);
  };

  return (
    <MUI.TableHead>
      <MUI.TableRow>
        {headers.map(header => (
          <MUI.TableCell
            key={header.id}
            align={header.align}
            sortDirection={orderBy === header.id ? order : false}
            classes={{ root: clsx(classes.cell, classes.headCell) }}
            style={{ top: topPosition }}
          >
            <MUI.TableSortLabel
              active={orderBy === header.id}
              direction={orderBy === header.id ? order : defaultOrder}
              onClick={(event: any) => onRequestSort(event, header.id)}
              onMouseLeave={handleMouseLeave}
              classes={{
                icon: clsx(hideSortIcon && classes.noSortIcon)
              }}
            >
              {header.label}
            </MUI.TableSortLabel>
          </MUI.TableCell>
        ))}
      </MUI.TableRow>
    </MUI.TableHead>
  );
}
