import React from "react";
import { MUI } from "@amps/material-ui";
import clsx from "clsx";

import useStyles from "components/shared/styles/ServiceProgressBar.style";
import ServiceModel from "models/service.model";
import StageModel from "models/stage.model";
import { useStoreState } from "redux/reducers/hooks";

type Props = {
  service: ServiceModel;
};

export function ServiceProgressBar(props: Props) {
  const { service } = props;
  const stages = useStoreState(state => state.service.stages);
  const classes = useStyles();
  const stageId = service.stage.id;
  const isCompleted = service.isCompleted;
  const currentStageIndex = stages.findIndex((stage: StageModel) => stage.id === stageId);

  const renderCompletedBar = () => {
    return (
      <>
        {stages.map((stage: StageModel, index: number) => (
          <MUI.Tooltip title={stage.name} key={stage.id} placement="top">
            <span className={clsx(classes.span, classes.completed)}>
              {index === stages.length - 1 ? `${index + 1}. ${stage.name}` : `${index + 1}`}
            </span>
          </MUI.Tooltip>
        ))}
      </>
    );
  };

  if (isCompleted) return renderCompletedBar();

  return (
    <>
      {stages.map((stage: StageModel, index: number) => (
        <MUI.Tooltip title={stage.name} key={stage.id} placement="top">
          <span
            className={clsx(
              classes.span,
              index === currentStageIndex && classes.active,
              index < currentStageIndex && classes.completed
            )}
          >
            {index === currentStageIndex ? `${index + 1}. ${stage.name}` : `${index + 1}`}
          </span>
        </MUI.Tooltip>
      ))}
    </>
  );
}

export default ServiceProgressBar;
