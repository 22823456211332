import React from "react";
import { MUI } from "@amps/material-ui";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

const useStyles = MUI.makeStyles(theme => ({
  toogleButton: {
    backgroundColor: "rgba(240, 80, 37, 0.08)!important"
  }
}));

export default function FormikToggleButtonGroup(props: any) {
  const {
    field,
    onChange,
    form: { setFieldValue, setFieldTouched },
    disabled = false,
    options
  } = props;
  const classes = useStyles();

  const handleChange = (event: any, value: boolean) => {
    setFieldValue(field.name, value);
    setFieldTouched(field.name, true);

    onChange && onChange(value);
  };

  return (
    <ToggleButtonGroup value={field.value} exclusive onChange={handleChange} size="small">
      {options.map((option: any) => (
        <ToggleButton
          key={option.value}
          value={option.value}
          classes={{
            selected: classes.toogleButton
          }}
          disabled={disabled}
          name={option.name}
        >
          <span>{option.label}</span>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
