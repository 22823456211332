import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  panelTitle: {
    height: "36px",
    fontSize: "18px"
  },
  editButton: {
    margin: "0px 4px"
  },
  divider: {
    width: 1,
    height: 25,
    margi: "2px"
  },
  addButtons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "6px"
  },
  textField: {
    fontSize: "14px",
    padding: "8px 12px"
  },
  disabledTextField: {
    color: "black"
  },
  multilineRoot: {
    padding: 0
  },
  expPanel: {
    marginBottom: "16px",
    borderRadius: "4px",
    "&::before": {
      display: "none"
    }
  },
  notesWrapper: {
    marginTop: "-14px!important"
  },
  progressWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px"
  },
  newNoteWrapper: {
    marginBottom: "8px"
  }
}));
