import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  dialogTitle: {
    fontSize: "24px"
  },
  formWrapper: {
    padding: "20px",
    marginBottom: "10px",
    borderRadius: "2px",
    boxShadow: "0 0 3px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#f5f5f5",
    border: "0"
  },
  dialogPaper: {
    overflow: "visible",
    padding: "18px 24px 8px"
  },
  row: {
    marginBottom: "16px",
    display: "grid"
  },
  rowTitle: {
    marginBottom: "8px",
    fontSize: "16px"
  },
  textField: {
    fontSize: "14px",
    padding: "12px",
    backgroundColor: "white"
  },
  optionalLabel: {
    fontSize: "12px",
    verticalAlign: "middle",
    color: "#7698cc"
  },
  bw: {
    backgroundColor: "white"
  },
  disabledTextField: {
    color: "rgb(153, 153, 153)"
  },
  multilineRoot: {
    padding: 0
  },
  progressWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px"
  }
}));
