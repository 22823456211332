import React, { Fragment, ReactNode } from "react";
import { MUI } from "@amps/material-ui";

import Header from "components/shared/Header";

type Props = {
  children: ReactNode;
};

function Layout(props: Props) {
  const { children } = props;

  return (
    <Fragment>
      <MUI.CssBaseline />
      <Header />
      <main>{children}</main>
    </Fragment>
  );
}

export default Layout;
