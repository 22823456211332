import BroadcastChannel from "broadcast-channel";

type BroadCastMessage = {
  key: string;
  data?: any;
};

export const authChannel: BroadcastChannel<
  BroadCastMessage
> = new BroadcastChannel("auth");

authChannel.postMessage({
  key: "NEW_TAB"
});

authChannel.onmessage = message => {
  const token = sessionStorage.getItem("jwtToken");
  switch (message.key) {
    case "NEW_TAB":
      token &&
        authChannel.postMessage({
          key: "JWT_TOKEN",
          data: token
        });
      break;

    case "JWT_TOKEN":
      token !== message.data &&
        sessionStorage.setItem("jwtToken", message.data);
      break;

    case "LOGOUT":
      sessionStorage.removeItem("jwtToken");
      break;
  }
};
