import React, { useState } from "react";
import { withRouter } from "react-router";
import { MUI, SideIconButton } from "@amps/material-ui";
import * as Yup from "yup";
import { getUserFullName } from "utils/userUtils";
import useStyles from "components/DashboardPage/styles/ClosedRequestsForm.style";
import { Field, Formik, Form } from "formik";
import FormikReactSelectAsync from "containers/FormikReactSelectAsync";
import FormikReactSelectTrigger from "containers/FormikReactSelectTrigger";
import FormikKeyboardDatePicker from "containers/FormikKeyboardDatePicker";
import FormikReactSelect from "containers/FormikReactSelect";
import { MemberSearchData, RequestsSearchCriteriaType } from "types";
import MemberModel from "models/member.model";
import UserModel from "models/user.model";
import ClientModel from "models/client.model";
import GroupModel from "models/group.model";
import { getPickerMinDate, getPickerMaxDate, isDateLess } from "utils/dateTimeUtils";
import { useStoreActions, useStoreState } from "redux/reducers/hooks";
import { RouteComponentProps } from "react-router-dom";
import { defaultSkip, defaultPageSize } from "utils/searchUtils";
import { Search } from "@material-ui/icons";
import {IconButton,Tooltip} from '@material-ui/core'
import clsx from "clsx";

type ClosedRequestsFormValues = {
  client: ClientModel | null;
  group: GroupModel | null;
  members: MemberModel[];
  createdByAgents: UserModel[];
  updatedByAgents: UserModel[];
  requestCreatedStartDate: string | null;
  requestCreatedEndDate: string | null;
  requestUpdatedEndDate: string | null;
  requestUpdatedStartDate: string | null;
};

const ClosedRequestsFormSchema = Yup.object()
  .shape({
    client: Yup.object().nullable(),
    group: Yup.object().nullable(),
    members: Yup.array().nullable(),
    createdByAgents: Yup.array().nullable(),
    updatedByAgents: Yup.array().nullable(),
    requestCreatedStartDate: Yup.date()
      .nullable()
      .typeError("Invalid field value")
      .test("isValidCreatedStartDate", "Invalid date range", function (requestCreatedStartDate: string) {
        return isDateLess(requestCreatedStartDate, this.parent.requestCreatedEndDate);
      }),
    requestCreatedEndDate: Yup.date()
      .nullable()
      .typeError("Invalid field value")
      .test("isValidCraetedEndDate", "Invalid date range", function (requestCreatedEndDate: string) {
        return isDateLess(this.parent.requestCreatedStartDate, requestCreatedEndDate);
      }),
    requestUpdatedStartDate: Yup.date()
      .nullable()
      .typeError("Invalid field value")
      .test("isValidUpdatedStartDate", "Invalid date range", function (requestUpdatedStartDate: string) {
        return isDateLess(requestUpdatedStartDate, this.parent.requestUpdatedEndDate);
      }),
    requestUpdatedEndDate: Yup.date()
      .nullable()
      .typeError("Invalid field value")
      .test("isValidUpdatedEndDate", "Invalid date range", function (requestUpdatedEndDate: string) {
        return isDateLess(this.parent.requestUpdatedStartDate, requestUpdatedEndDate);
      })
  })
  .test("at least one field is not empty", "provide at least one field", (values: any) => {
    const {
      client,
      group,
      members,
      createdByAgents,
      updatedByAgents,
      requestCreatedStartDate,
      requestCreatedEndDate,
      requestUpdatedStartDate,
      requestUpdatedEndDate
    } = values;

    return !!(
      (members ? members.length > 0 : members) ||
      (createdByAgents ? createdByAgents.length > 0 : createdByAgents) ||
      (updatedByAgents ? updatedByAgents.length > 0 : updatedByAgents) ||
      requestCreatedStartDate ||
      requestCreatedEndDate ||
      requestUpdatedStartDate ||
      requestUpdatedEndDate ||
      client ||
      group
    );
  });

type ClosedRequestsFormProps = {
  onSearchClosedRequests: (newSearchCriteria: RequestsSearchCriteriaType) => Promise<void>;
};

type Props = ClosedRequestsFormProps & RouteComponentProps<any>;

export function ClosedRequestsForm(props: Props) {
  const classes = useStyles();
  const { onSearchClosedRequests } = props;
  const [isSearching, setIsSearching] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [groups, setGroups] = useState([] as GroupModel[]);
  const getAgents = useStoreActions(store => store.agent.getAgents);
  const getMembers = useStoreActions(actions => actions.member.getMembers);
  const getGroupsByClientId = useStoreActions(actions => actions.group.getGroupsByClientId);
  const clients = useStoreState(actions => actions.client.clients);

  const pickerMinDate = getPickerMinDate();
  const pickerMaxDate = getPickerMaxDate(new Date());

  const loadMembers = async (memberLastName: string, formValues: ClosedRequestsFormValues) => {
    const { group, client } = formValues;

    const data: MemberSearchData = {};

    memberLastName && (data.lastName = memberLastName);
    client && (data.clientId = parseInt(client.id));
    group && (data.clientGroupId = parseInt(group.id));

    return getMembers(data);
  };

  const loadAgents = async (agentLastName: string) => {
    return getAgents(agentLastName);
  };

  const getMemberFullNameLabel = (member: MemberModel) => {
    return `${member.lastName}, ${member.firstName}`;
  };

  const getAgentFullNameLabel = (user: UserModel) => {
    return `${user.code}: ${getUserFullName(user)}`;
  };

  const handleSelectClient = async (client: ClientModel, form: any) => {
    form.setFieldValue("members", []);
    form.setFieldValue("group", null);
    setGroups([]);

    if (!client) return;

    const { id } = client;
    const groups = await getGroupsByClientId(id);
    setGroups(groups);
  };

  const handleSelectGroup = async (group: GroupModel, form: any) => {
    form.setFieldValue("members", []);
  };

  const handleSearchWithRequestId = async (value: string, form: any) => {
    if (value && value.length > 0) {
      setIsSearching(true);
      try {
        const {
          client,
          group,
          members,
          createdByAgents,
          updatedByAgents,
          requestCreatedStartDate,
          requestCreatedEndDate,
          requestUpdatedEndDate,
          requestUpdatedStartDate
        } = form.values;

        const data: RequestsSearchCriteriaType = {
          clientId: client ? parseInt(client.id) : null,
          clientGroupId: group ? parseInt(group.id) : null,
          members: members && members.length ? members.map((member: MemberModel) => parseInt(member.id)) : null,
          createdByAgents:
            createdByAgents && createdByAgents.length
              ? createdByAgents.map((agent: UserModel) => parseInt(agent.id))
              : null,
          updatedByAgents:
            updatedByAgents && updatedByAgents.length
              ? updatedByAgents.map((agent: UserModel) => parseInt(agent.id))
              : null,
          requestCreatedStart: requestCreatedStartDate ? new Date(requestCreatedStartDate) : null,
          requestCreatedEnd: requestCreatedEndDate ? new Date(`${requestCreatedEndDate} 23:59:59`) : null,
          requestUpdatedEnd: requestUpdatedEndDate ? new Date(`${requestUpdatedEndDate} 23:59:59`) : null,
          requestUpdatedStart: requestUpdatedStartDate ? new Date(requestUpdatedStartDate) : null,
          skip: defaultSkip,
          take: defaultPageSize,
          openOnly: false,
          search: value
        };

        await onSearchClosedRequests(data);
      } finally {
        setIsSearching(false);
      }
    }
  }
  const handleRequestSearch = async (values: ClosedRequestsFormValues) => {
    setIsSearching(true);

    try {
      const {
        client,
        group,
        members,
        createdByAgents,
        updatedByAgents,
        requestCreatedStartDate,
        requestCreatedEndDate,
        requestUpdatedEndDate,
        requestUpdatedStartDate
      } = values;

      const data: RequestsSearchCriteriaType = {
        clientId: client ? parseInt(client.id) : null,
        clientGroupId: group ? parseInt(group.id) : null,
        members: members && members.length ? members.map((member: MemberModel) => parseInt(member.id)) : null,
        createdByAgents:
          createdByAgents && createdByAgents.length
            ? createdByAgents.map((agent: UserModel) => parseInt(agent.id))
            : null,
        updatedByAgents:
          updatedByAgents && updatedByAgents.length
            ? updatedByAgents.map((agent: UserModel) => parseInt(agent.id))
            : null,
        requestCreatedStart: requestCreatedStartDate ? new Date(requestCreatedStartDate) : null,
        requestCreatedEnd: requestCreatedEndDate ? new Date(`${requestCreatedEndDate} 23:59:59`) : null,
        requestUpdatedEnd: requestUpdatedEndDate ? new Date(`${requestUpdatedEndDate} 23:59:59`) : null,
        requestUpdatedStart: requestUpdatedStartDate ? new Date(requestUpdatedStartDate) : null,
        skip: defaultSkip,
        take: defaultPageSize,
        openOnly: false,
        search: requestId
      };

      await onSearchClosedRequests(data);
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <Formik
      initialValues={{
        client: null,
        group: null,
        members: [],
        createdByAgents: [],
        updatedByAgents: [],
        requestCreatedStartDate: null,
        requestCreatedEndDate: null,
        requestUpdatedEndDate: null,
        requestUpdatedStartDate: null
      }}
      validationSchema={ClosedRequestsFormSchema}
      onSubmit={handleRequestSearch}
    >
      {closedRequestForm => (
        <Form><div className={`${classes.rowItems}--right`}>
           <Tooltip title="Search by Request ID or Member LastName" placement="bottom">
          <MUI.TextField
            variant="outlined"
            placeholder="Search "
            value={requestId}
            onChange={(event) => {
              setRequestId(event.target.value)
            }}
            InputProps={{
              classes: {
                input: classes.textField
              },
              endAdornment: (
                <MUI.InputAdornment position="end">
                  <IconButton  
                  style={{color:requestId.length > 0 ?'#f05025':''}}
                  aria-label="toggle password visibility"
                  onClick={()=>{
                    setRequestId(requestId)
                    handleSearchWithRequestId(requestId, closedRequestForm)
                  }}
                  edge="end">
                  <Search />
                  </IconButton>
                </MUI.InputAdornment>
              )
            }}
          />
          </Tooltip>
        </div>
          <div className={classes.container}>
            <div className={classes.row}>
              <div className={classes.rowItem}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  Client
                </MUI.Typography>
                <Field
                  name="client"
                  id="client-select"
                  isClearable
                  options={clients}
                  component={FormikReactSelect}
                  getOptionLabel={(option: ClientModel) => `${option.code} - ${option.name}`}
                  getOptionValue={(option: ClientModel) => option.id}
                  onChange={(client: ClientModel) => handleSelectClient(client, closedRequestForm)}
                />
              </div>
              <div className={classes.rowItem}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  Group
                </MUI.Typography>
                <Field
                  name="group"
                  isClearable
                  options={groups}
                  component={FormikReactSelect}
                  isDisabled={!closedRequestForm.values.client}
                  getOptionLabel={(option: GroupModel) => `${option.code} - ${option.name}`}
                  getOptionValue={(option: GroupModel) => option.id}
                  onChange={(group: GroupModel) => handleSelectGroup(group, closedRequestForm)}
                />
              </div>
              <div className={classes.rowItem}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  Member Name
                </MUI.Typography>
                <Field
                  id="members-select"
                  name="members"
                  component={FormikReactSelectTrigger}
                  components={{ DropdownIndicator: null }}
                  placeholder="Search by entire Last Name"
                  loadOptions={(value: string) => loadMembers(value, closedRequestForm.values)}
                  getOptionLabel={(option: MemberModel) => getMemberFullNameLabel(option)}
                  getOptionValue={(option: MemberModel) => option.id}
                  isMulti
                  maxOptionCount={10}
                  limitReachedText="10 members limit reached"
                />
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.rowItem}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  Date Request Created
                </MUI.Typography>
                <div className={classes.row}>
                  <div className={clsx(classes.rowItem, classes.dateWrapper)}>
                    <MUI.Typography variant="body2" color="textSecondary" className={classes.subRowTitle}>
                      From
                    </MUI.Typography>
                    <Field
                      name="requestCreatedStartDate"
                      component={FormikKeyboardDatePicker}
                      fullWidth
                      minDate={pickerMinDate}
                      maxDate={pickerMaxDate}
                      minDateMessage="Invalid field value"
                      maxDateMessage="Future date is not allowed"
                    />
                  </div>
                  <div className={clsx(classes.rowItem, classes.dateWrapper)}>
                    <MUI.Typography variant="body2" color="textSecondary" className={classes.subRowTitle}>
                      To
                    </MUI.Typography>
                    <Field
                      name="requestCreatedEndDate"
                      component={FormikKeyboardDatePicker}
                      fullWidth
                      minDate={pickerMinDate}
                      maxDate={pickerMaxDate}
                      minDateMessage="Invalid field value"
                      maxDateMessage="Future date is not allowed"
                    />
                  </div>
                </div>
              </div>
              <div className={classes.rowItem}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  Date Request Updated
                </MUI.Typography>
                <div className={classes.row}>
                  <div className={clsx(classes.rowItem, classes.dateWrapper)}>
                    <MUI.Typography variant="body2" color="textSecondary" className={classes.subRowTitle}>
                      From
                    </MUI.Typography>
                    <Field
                      name="requestUpdatedStartDate"
                      component={FormikKeyboardDatePicker}
                      fullWidth
                      minDate={pickerMinDate}
                      maxDate={pickerMaxDate}
                      minDateMessage="Invalid field value"
                      maxDateMessage="Future date is not allowed"
                    />
                  </div>
                  <div className={clsx(classes.rowItem, classes.dateWrapper)}>
                    <MUI.Typography variant="body2" color="textSecondary" className={classes.subRowTitle}>
                      To
                    </MUI.Typography>
                    <Field
                      name="requestUpdatedEndDate"
                      component={FormikKeyboardDatePicker}
                      fullWidth
                      minDate={pickerMinDate}
                      maxDate={pickerMaxDate}
                      minDateMessage="Invalid field value"
                      maxDateMessage="Future date is not allowed"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.rowItem}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  Created By
                </MUI.Typography>
                <Field
                  id="createdby-agents-select"
                  name="createdByAgents"
                  component={FormikReactSelectAsync}
                  components={{ DropdownIndicator: null }}
                  placeholder="Search by Last Name, select by typing at least 3 characters"
                  loadOptions={loadAgents}
                  getOptionLabel={(option: UserModel) => getAgentFullNameLabel(option)}
                  getOptionValue={(option: UserModel) => option.id}
                  isMulti
                  maxOptionCount={10}
                  limitReachedText="10 agents limit reached"
                />
              </div>
              <div className={classes.rowItem}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  Updated By
                </MUI.Typography>
                <Field
                  id="updatedby-agents-select"
                  name="updatedByAgents"
                  component={FormikReactSelectAsync}
                  components={{ DropdownIndicator: null }}
                  placeholder="Search by Last Name, select by typing at least 3 characters"
                  loadOptions={loadAgents}
                  getOptionLabel={(option: UserModel) => getAgentFullNameLabel(option)}
                  getOptionValue={(option: UserModel) => option.id}
                  isMulti
                  maxOptionCount={10}
                  limitReachedText="10 agents limit reached"
                />
              </div>
              <div className={classes.searchWrapper}>
                <div className={classes.progressWrapper}>
                  <SideIconButton
                    id="search-closed-requests-btn"
                    variant="contained"
                    className={classes.searchButton}
                    Icon={Search}
                    align="left"
                    type="submit"
                    color="primary"
                    autoFocus
                    disabled={!closedRequestForm.isValid || isSearching}
                    fullWidth
                  >
                    Search
                  </SideIconButton>
                  {isSearching && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default withRouter(ClosedRequestsForm);
