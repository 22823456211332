import React, { useState } from "react";
import { getIn } from "formik";
import { MUI } from "@amps/material-ui";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getISO8601DateString, LuxonUtils } from "utils/dateTimeUtils";

const useStyles = MUI.makeStyles(theme => ({
  helperText: {
    position: "absolute",
    margin: 0,
    top: "100%"
  },
  textField: {
    fontSize: "14px",
    padding: "12px"
  },
  textFieldRoot: {
    backgroundColor: "white",
    paddingRight: 0
  },
  disabled: {
    color: "rgb(153, 153, 153)"
  }
}));

const TextFieldWithTooltip = (props: any) => {
  const { tooltipText = "", onBlur, ...other } = props;
  const [isFocused, setIsFocused] = useState(false);

  return (
    <MUI.Tooltip title={isFocused ? "" : tooltipText || ""} placement="top">
      <MUI.TextField
        onBlur={() => {
          setIsFocused(false);
          onBlur && onBlur();
        }}
        onFocus={() => setIsFocused(true)}
        {...other}
      />
    </MUI.Tooltip>
  );
};

export default function FormikKeyboardDatePicker(props: any) {
  const {
    field,
    helperText,
    ignorePickerErrors = false,
    form: { touched, errors, setFieldValue, setFieldTouched, setFieldError },
    ...other
  } = props;
  const message = getIn(touched, field.name) && getIn(errors, field.name);
  const isTouched = getIn(touched, field.name);
  const formikError = getIn(errors, field.name);
  const isInvalid = !!(formikError && isTouched);
  const classes = useStyles();
  const value = field.value && getISO8601DateString(field.value);

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <KeyboardDatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        name={field.name}
        value={value}
        placeholder="mm/dd/yyyy"
        format="MM/dd/yyyy"
        helperText={isInvalid && (helperText || message)}
        error={isInvalid}
        onError={(error: any) => !formikError && !ignorePickerErrors && setFieldError(field.name, error)}
        onChange={(date, value) => setFieldValue(field.name, value || null)}
        onBlur={() => setFieldTouched(field.name, true)}
        onAccept={() => setFieldTouched(field.name, true)}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText
          }
        }}
        InputProps={{
          classes: {
            root: classes.textFieldRoot,
            input: classes.textField,
            disabled: classes.disabled
          }
        }}
        TextFieldComponent={TextFieldWithTooltip}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
}
