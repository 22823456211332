import React, { useState, useRef } from "react";
import { getIn } from "formik";
import { MUI } from "@amps/material-ui";
import Select, { components } from "react-select";
import clsx from "clsx";
import { useId } from "react-id-generator";
import calculateSize from "calculate-size";
import { Search } from "@material-ui/icons";

const useStyles = MUI.makeStyles(theme => ({
  helperText: {
    position: "absolute",
    top: "100%",
    margin: 0,
    color: "#f44336",
    fontSize: "0.75rem"
  },
  container: {
    position: "relative"
  },
  popper: {
    whiteSpace: "pre-line"
  },
  indicatorWrapper: {
    padding: "4px!important"
  },
  searchIcon: {
    color: "#f05025"
  }
}));

const selectTheme = (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#f05025"
  }
});

const getStyles = (isValid: boolean) => {
  const generalStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minHeight: "40.63px",
      backgroundColor: "white",
      borderColor: state.isFocused ? "#f44336" : "rgba(0, 0, 0, 0.26)",
      "&:hover": { borderColor: state.isFocused ? "#f44336" : "#2b2b2b" }
    }),
    placeholder: (base: any, state: any) => ({
      ...base,
      maxWidth: "calc(100% - 8px)",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      opacity: state.isDisabled ? "0.5" : "0.8"
    }),
    clearIndicator: (base: any) => ({
      ...base,
      "&:hover": { cursor: "pointer" }
    }),
    loadingIndicator: (base: any) => ({
      ...base,
      width: "36px",
      padding: 0,
      margin: 0
    }),
    multiValue: (base: any) => ({
      ...base,
      maxWidth: "97%"
    }),
    // Fix for Edge
    input: (base: any, state: any) => {
      const inputSize = calculateSize(state.value, {
        font: "Arial",
        fontSize: "18px"
      });

      return {
        ...base,
        "& input": {
          width: `${inputSize.width}px !important`,
          minWidth: "2px"
        }
      };
    }
  };

  if (isValid) return generalStyles;

  return {
    ...generalStyles,
    control: (base: any, state: any) => ({
      ...base,
      minHeight: "40.63px",
      borderColor: "#f44336",
      "&:hover": { borderColor: "#f44336" }
    })
  };
};

export default function FormikReactSelectTrigger(props: any) {
  const {
    field,
    maxOptionCount,
    isMulti,
    limitReachedText,
    tooltipText = "",
    form: { touched, errors, setFieldValue, setFieldTouched },
    maxMenuHeight = 140,
    loadOptions,
    ...otherProps
  } = props;
  const message = getIn(touched, field.name) && getIn(errors, field.name);
  const classes = useStyles();
  const styles = getStyles(!message);
  const [isFocused, setIsFocused] = useState(false);
  const [options, setOptions] = useState(undefined as [] | undefined);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const selectRef = useRef(null);
  const [searchButtonId] = useId();
  const selectedOptionCount = isMulti && field && field.value ? field.value.length : 0;

  const handleSearch = async () => {
    if (!inputValue || isMaxCountReached()) return;

    (selectRef.current as any).focus();
    setIsLoading(true);
    setOptions(undefined);

    try {
      const result = await loadOptions(inputValue);

      setOptions(result);
    } finally {
      setIsLoading(false);
    }
  };

  const onInputChange = (inputValue: string, payload: any) => {
    const { action } = payload;

    switch (action) {
      case "input-change":
        setInputValue(inputValue);
        setOptions(undefined);
        return;
      case "set-value":
        setInputValue("");
        setOptions(undefined);
        return;
      default:
        return;
    }
  };

  const handleChange = (option: any) => {
    setOptions(undefined);
    setFieldValue(field.name, option);
  };

  const handleBlur = (event: any) => {
    if (event.relatedTarget && event.relatedTarget.id === searchButtonId) return;

    setInputValue("");
    setIsFocused(false);
    setOptions(undefined);
    setFieldTouched(field.name, true);
  };

  const handleFocus = (event: any) => {
    setIsFocused(true);
  };

  const handleKeyDown = (event: any) => {
    if (inputValue && "Enter" === event.key && !(options && options.length)) {
      handleSearch();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const isMaxCountReached = () => {
    return isMulti && maxOptionCount <= selectedOptionCount;
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props} className={classes.indicatorWrapper}>
        <MUI.IconButton
          id={searchButtonId}
          size="small"
          onClick={handleSearch}
          classes={{
            root: classes.searchIcon
          }}
          disabled={isLoading || inputValue.length === 0 || isMaxCountReached()}
        >
          <Search />
        </MUI.IconButton>
      </components.DropdownIndicator>
    );
  };

  return (
    <MUI.Tooltip title={isFocused ? "" : tooltipText || ""} placement="top" classes={{ popper: classes.popper }}>
      <div className={classes.container}>
        <Select
          {...field}
          {...otherProps}
          components={{ DropdownIndicator }}
          value={field.value}
          onChange={handleChange}
          ref={selectRef}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          theme={selectTheme}
          styles={styles}
          isMulti={isMulti}
          isLoading={isLoading}
          menuIsOpen={isLoading || !!options || (inputValue && isMaxCountReached())}
          maxMenuHeight={maxMenuHeight}
          options={options}
          isClearable={true}
          onInputChange={onInputChange}
          inputValue={inputValue}
          noOptionsMessage={() => (isMaxCountReached() && limitReachedText ? limitReachedText : "No Options")}
        />
        {message && <p className={clsx("error-msg", classes.helperText)}>{message}</p>}
      </div>
    </MUI.Tooltip>
  );
}
