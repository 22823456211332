// TODO: temporary on the FE, migrate this mapping to the BE
// to get available interactions from stage
// mapping from https://advancedpricing.atlassian.net/browse/DA-444
// from file Stage_Interaction_Func mapping_v2.xlsx

import ServiceModel from "models/service.model";
import InteractionTypes from "utils/constants/InteractionTypes";
import Stages from "utils/constants/Stages";

const fullInteractionsType = [
  {
    id: "2",
    name: InteractionTypes.MEMBER_CALLOUT
  },
  {
    id: "3",
    name: InteractionTypes.MEMBER_CALLBACK
  },
  {
    id: "7",
    name: InteractionTypes.TPA_CALLOUT
  },
  {
    id: "8",
    name: InteractionTypes.TPA_CALLBACK
  },
  {
    id: "4",
    name: InteractionTypes.PROVIDER_CALLOUT
  },
  {
    id: "5",
    name: InteractionTypes.PROVIDER_CALLBACK
  },
  {
    id: "6",
    name: InteractionTypes.VENDOR_CALLOUT
  },
  {
    id: "9",
    name: InteractionTypes.VENDOR_CALLBACK
  },
  {
    id: "10",
    name: InteractionTypes.INTERNAL_CALLOUT
  },
  {
    id: "11",
    name: InteractionTypes.INTERNAL_CALLBACK
  }
];

const interactionMainTypes = ["Member", "TPA", "Vendor", "Provider", "Internal"];

const StageInteraction: any = {
  [Stages.GATHERING_INFORMATION]: [...interactionMainTypes.slice(0, 2), "Internal"],
  [Stages.LOCATING_FACILITY]: interactionMainTypes,
  [Stages.CREATING_APPOINTMENT]: interactionMainTypes,
  [Stages.FINAL_FOLLOWUP]: [...interactionMainTypes.slice(0, 2), "Internal"]
};

const getAvailableInteractions = (service: ServiceModel) => {
  const {
    stage: { name: stageName }
  } = service;

  return StageInteraction[stageName];
};

const getFullInteractionType = (type: string) => {
  return fullInteractionsType.find(interactionType => interactionType.name === type);
};

export { getAvailableInteractions, getFullInteractionType };
