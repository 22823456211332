import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  paper: {
    width: "100%",
    padding: "16px",
    marginBottom: "16px"
  },
  gatheringInfoPaper: {
    backgroundColor: "rgba(129,211,232,0.1)"
  },
  locatingFacilityPaper: {
    backgroundColor: "rgba(255, 235, 235, 0.7)"
  },
  creatingAppointmentPaper: {
    backgroundColor: "rgba(255,255,0,0.1)"
  },
  finalFollowupPaper: {
    backgroundColor: "rgba(0,255,0,0.1)"
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    marginLeft: "4px"
  },
  row: {
    marginBottom: "16px"
  },
  helperGrid: {
    maxHeight: "68px"
  },
  helperText: {
    fontStyle: "italic",
    fontSize: "12px"
  },
  helperBox: {
    textAlign: "right",
    height: "100%",
    display: "grid"
  },
  regularText: {
    color: "black"
  },
  chip: {
    fontSize: "11px",
    backgroundColor: "rgba(129,211,233,0.35)",
    fontWeight: 600,
    borderRadius: "4px"
  },
  textField: {
    fontSize: "14px",
    padding: "8px 12px"
  },
  multilineRoot: {
    padding: 0
  },
  disabledTextField: {
    color: "black"
  },
  expandNoteContainer: {
    padding: "7px 11px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px"
  },
  editButton: {
    margin: "0px 4px"
  },
  divider: {
    width: 1,
    height: 20
  },
  progressWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px"
  }
}));
