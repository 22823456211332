import React, { useState } from "react";
import { getIn } from "formik";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { MUI } from "@amps/material-ui";

const useStyles = makeStyles(() =>
  createStyles({
    popper: {
      whiteSpace: "pre-line"
    },
    helperText: {
      position: "absolute",
      margin: 0,
      top: "100%"
    }
  })
);

export default function FormikTextField(props: any) {
  const {
    field,
    onChange,
    form: { touched, errors },
    helperText,
    tooltipText = "",
    showHelperText = true,
    ...otherProps
  } = props;
  const message = getIn(touched, field.name) && getIn(errors, field.name);
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <MUI.Tooltip classes={{ popper: classes.popper }} title={isFocused ? "" : tooltipText || ""} placement="top">
      <MUI.TextField
        {...otherProps}
        {...field}
        error={showHelperText && Boolean(message)}
        helperText={showHelperText && (message || helperText)}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText
          }
        }}
        onChange={(event: any) => {
          field.onChange(event);
          onChange && onChange(event.target.value);
        }}
        onBlur={(event: any) => {
          setIsFocused(false);
          field.onBlur(event);
        }}
        onFocus={() => setIsFocused(true)}
      />
    </MUI.Tooltip>
  );
}
