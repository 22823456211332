import React, { useState, useEffect } from "react";
import { MUI, Menu, SideIconButton } from "@amps/material-ui";
import { getDateString, getDateTimeString } from "utils/dateTimeUtils";
import { getUserFullName } from "utils/userUtils";
import { getServiceStartIntentions } from "utils/serviceUtils";
import useStyles from "components/RequestPage/styles/ServiceView.style";
import ServiceModel from "models/service.model";
import RequestModel from "models/request.model";
import StageModel from "models/stage.model";
import ActivityLogModel from "models/activityLog.model";
import InteractionModel from "models/interaction.model";
import ServiceProgressBar from "components/shared/ServiceProgressBar";
import InteractionView from "components/RequestPage/InteractionView";
import ServiceStatusLabel from "components/RequestPage/ServiceStatusLabel";
import TreatmentPanel from "components/RequestPage/TreatmentPanel";
import CloseServiceModal from "components/RequestPage/CloseServiceModal";
import ActionHistoryLog from "components/RequestPage/ActionHistoryLog";
import { useStoreActions, useStoreState } from "redux/reducers/hooks";
import { KeyboardArrowUp, KeyboardArrowDown, Edit, ReportProblem, Add } from "@material-ui/icons";
import { Modals } from "utils/modalUtils";
import { hasReOpenClosedRequestPermission } from "utils/permissionUtils";

interface ServiceViewProps {
  service: ServiceModel;
  request: RequestModel;
  isDefaultExpanded: boolean;
  handleSetStageUpdating: Function;
  serviceId: number;
}

export function ServiceView(props: ServiceViewProps) {
  const { service, request, isDefaultExpanded, handleSetStageUpdating } = props;
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);
  const getRequestById = useStoreActions(actions => actions.request.getRequestById);
  const updateService = useStoreActions(actions => actions.service.updateService);
  const stages = useStoreState(state => state.service.stages);
  const [isReOpenClosedService, setIsReOpenClosedService] = useState(false);
  const permissions = useStoreState(state => state.auth.permissions);

  const {
    service: { reOpenService },
    modal: { openModal }
  } = useStoreActions(actions => actions);

  const classes = useStyles();

  useEffect(() => {
    setIsExpanded(isDefaultExpanded);
  }, [isDefaultExpanded]);

  const handleStageUpdate = async (event: any, newStage: StageModel) => {
    handleSetStageUpdating(true);

    try {
      const data = {
        serviceId: parseInt(service.id),
        stageId: parseInt(newStage.id)
      };

      await updateService(data);
      await getRequestById(request.id);
    } finally {
      handleSetStageUpdating(false);
    }
  };

  const onReOpenClosedServiceClick = () => {
    openModal({
      modalType: Modals.Confirmation,
      modalProps: {
        onOk: handleReOpenClosedService,
        isOkInProgress: isReOpenClosedService,
        okButtonText: "Reopen Service"
      }
    });
  };

  const handleReOpenClosedService = async () => {
    setIsReOpenClosedService(true);
    try {
      await reOpenService(Number(service.id));
      await getRequestById(request.id);
    } finally {
      setIsReOpenClosedService(false);
    }
  };
  const getActivities = (service: ServiceModel) => {
    const activities: (InteractionModel | ActivityLogModel)[] = [...service.interactions, ...service.activityLogs];

    return activities.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
  };

  const isActivityLog = (activity: InteractionModel | ActivityLogModel): activity is ActivityLogModel => {
    return (activity as ActivityLogModel).createdById !== undefined;
  };

  const activities = getActivities(service);

  return (
    <div className={classes.row}>
      <MUI.Accordion expanded={isExpanded}>
        <MUI.AccordionSummary expandIcon={null} classes={{ root: classes.expansionPanel }}>
          <MUI.Grid container spacing={0} alignItems="center">
            <MUI.Grid item xs={6}>
              <span className={classes.panelTitle}>
                <b className="service-name">SERVICE: {service.type.name}</b>
              </span>
            </MUI.Grid>
            <MUI.Grid item xs={6}>
              <MUI.Grid container spacing={0} justify="flex-end" alignItems="center">

                <ServiceStatusLabel status={service.status} />

                {hasReOpenClosedRequestPermission(permissions) &&
                  service.status.name === "Closed" && (
                    <MUI.Grid className={classes.reOpenButton}>
                      <SideIconButton
                        disabled={request.requestStatus && request.requestStatus.name === "Closed"}
                        id="re-open-button"
                        variant="contained"
                        color="secondary"
                        Icon={Add}
                        align="left"
                        onClick={onReOpenClosedServiceClick}
                      >
                        Reopen Service
                      </SideIconButton>
                    </MUI.Grid>
                  )}

                <MUI.Fab
                  size="small"
                  color="secondary"
                  className={classes.expandButton}
                  onClick={(e: any) => setIsExpanded(!isExpanded)}
                >

                  {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </MUI.Fab>
              </MUI.Grid>
            </MUI.Grid>

            {service.startIntentions && (
              <MUI.Grid item xs={12} className={classes.rowTitle}>
                <span>What service the member is looking to receive: {getServiceStartIntentions(service)}</span>
              </MUI.Grid>
            )}

            <MUI.Grid item xs={service.closingReason ? 6 : 9}>
              <MUI.Grid container spacing={0} justify="space-between" alignItems="center">
                <div className={classes.barContainer}>
                  <span>Stage: </span>
                  <ServiceProgressBar service={service} />
                </div>
                {!service.closingReason && (
                  <div className={classes.prButtons}>
                    <Menu
                      id="update-stage-menu"
                      buttonProps={{
                        size: "small",
                        className: classes.menuButton
                      }}
                      buttonText="Update Stage"
                      LeftIcon={Edit}
                    >
                      <MUI.MenuList>
                        {stages.map((stage: any, index: number) => (
                          <MUI.MenuItem key={stage.id} onClick={e => handleStageUpdate(e, stage)}>
                            {index + 1}. {stage.name}
                          </MUI.MenuItem>
                        ))}
                      </MUI.MenuList>
                    </Menu>
                    <MUI.Divider className={classes.divider} />
                    <CloseServiceModal service={service} request={request} />
                  </div>
                )}
              </MUI.Grid>
            </MUI.Grid>

            {!service.closingReason && (
              <MUI.Grid item xs={3}>
                {service.urgent && (
                  <MUI.Grid container spacing={0} justify="flex-end" alignItems="center" className={classes.pr}>
                    <ReportProblem color="error" className={classes.emergencyIcon} />
                    <span className={classes.urgentText}>
                      {" "}
                      <b>Urgent/Emergency</b>
                    </span>
                  </MUI.Grid>
                )}
              </MUI.Grid>
            )}

            {service.closingReason && (
              <MUI.Grid item xs={6}>
                <MUI.Grid container spacing={0} justify="flex-end" alignItems="center" className={classes.pr}>
                  <b>{service.closingReason.name}</b>
                </MUI.Grid>
              </MUI.Grid>
            )}

            <MUI.Grid item xs={8}>
              <span className={classes.helperText}>
                Service Updated on {getDateTimeString(service.updatedOn)}
                <span> by {getUserFullName(service.updatedBy)}</span>
              </span>
            </MUI.Grid>
            {service.nearestFollowUp && !service.closingReason && (
              <MUI.Grid item xs={4} className={classes.pr}>
                <MUI.Grid container spacing={0} justify="flex-end" alignItems="center" className={classes.urgentText}>
                  Follow up by:
                  <b>&nbsp;{getDateString(service.nearestFollowUp)}</b>
                </MUI.Grid>
              </MUI.Grid>
            )}
          </MUI.Grid>
        </MUI.AccordionSummary>
        <MUI.Divider variant="middle" />
        <MUI.AccordionDetails className={classes.panelDetails}>
          <TreatmentPanel service={service} request={request} />
          {activities.map((activity: ActivityLogModel | InteractionModel) =>
            isActivityLog(activity) ? (
              <ActionHistoryLog key={activity.createdOn} activityLog={activity as ActivityLogModel} />
            ) : (
              <InteractionView
                key={(activity as InteractionModel).id}
                interaction={activity as InteractionModel}
                request={request}
                service={service}
              />
            )
          )}
        </MUI.AccordionDetails>
      </MUI.Accordion>
    </div>
  );
}

export default ServiceView;
