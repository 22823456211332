import React, { useState, useEffect } from "react";
import { MUI } from "@amps/material-ui";
import { Formik, Form, Field, FormikProps } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

import { getDateTimeString } from "utils/dateTimeUtils";
import { hasEditorPermission } from "utils/permissionUtils";
import useStyles from "components/RequestPage/styles/MemberNote.style";
import MemberNoteModel from "models/memberNote.model";
import FormikTextField from "containers/FormikTextField";
import { useStoreState, useStoreActions } from "redux/reducers/hooks";
import { Edit, Delete, Check, Close } from "@material-ui/icons";

type MemberNoteProps = {
  note: MemberNoteModel;
  noteIndex: number;
  areButtonsDisabled: boolean;
  updateMemberNotes: Function;
};

interface MemberNotesFormValues {
  memberNoteBody: string;
  memberNoteTitle: string;
}

const MemberNoteFormSchema = Yup.object().shape({
  memberNoteBody: Yup.string().max(2000, "Must be 2000 characters or less"),
  memberNoteTitle: Yup.string().max(2000, "Must be 2000 characters or less")
});

function MemberNote(props: MemberNoteProps) {
  const { note, noteIndex, areButtonsDisabled, updateMemberNotes } = props;
  const [isMemberNoteEditing, setIsMemberNoteEditing] = useState(false);
  const [isMemberNoteSubmitting, setIsMemberNoteSubmitting] = useState(false);
  const [noteOwner, setNoteOwner] = useState({ lastName: "", firstName: "" });
  const permissions = useStoreState(state => state.auth.permissions);
  const classes = useStyles();
  const { getAgentById } = useStoreActions(actions => actions.agent);

  useEffect(() => {
    async function getAgentData() {
      const agent = await getAgentById(note.userId);
      setNoteOwner(agent);
    }
    getAgentData();
  }, [note.userId, getAgentById]);

  const handleMemberNoteEdit = async (values: MemberNotesFormValues) => {
    setIsMemberNoteSubmitting(true);

    try {
      const { memberNoteBody, memberNoteTitle } = values;
      const updatedNote = { noteBody: memberNoteBody, noteTitle: memberNoteTitle };
      await updateMemberNotes(noteIndex, updatedNote);

      setIsMemberNoteEditing(false);
    } finally {
      setIsMemberNoteSubmitting(false);
    }
  };

  const resetMemberNoteEdit = (memberNoteForm: FormikProps<MemberNotesFormValues>) => {
    memberNoteForm.resetForm();
    setIsMemberNoteEditing(false);
  };

  const handleRemoveMemberNote = async () => {
    await updateMemberNotes(noteIndex);

    setIsMemberNoteEditing(false);
  };

  return (
    <>
      <div>
        <MUI.Divider className={classes.noteDivider} />

        <Formik
          enableReinitialize
          initialValues={{
            memberNoteTitle: note.title,
            memberNoteBody: note.body
          }}
          isInitialValid={true}
          validationSchema={MemberNoteFormSchema}
          onSubmit={handleMemberNoteEdit}
        >
          {memberNoteForm => (
            <Form>
              <MUI.Grid container spacing={0} justify="flex-start" alignItems="center">
                {hasEditorPermission(permissions) && (
                  <Field
                    name="memberNoteTitle"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    multiline
                    placeholder="Note title"
                    disabled={!isMemberNoteEditing || isMemberNoteSubmitting}
                    InputProps={{
                      classes: {
                        root: classes.multilineRoot,
                        input: classes.textField,
                        disabled: classes.disabledTextField
                      }
                    }}
                  />
                )}
                {hasEditorPermission(permissions) && (
                  <Field
                    name="memberNoteBody"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    multiline
                    disabled={!isMemberNoteEditing || isMemberNoteSubmitting}
                    InputProps={{
                      classes: {
                        root: classes.multilineRoot,
                        input: classes.textField,
                        disabled: classes.disabledTextField
                      }
                    }}
                  />
                )}
                {!hasEditorPermission(permissions) && (
                  <MUI.InputBase
                    className={clsx("note-title", classes.noteTitle)}
                    defaultValue={note.title}
                    fullWidth
                    inputProps={{ "aria-label": "naked" }}
                  />
                )}
                {!hasEditorPermission(permissions) && (
                  <MUI.InputBase
                    className={clsx("note-text", classes.noteText)}
                    defaultValue={note.body}
                    fullWidth
                    multiline
                    inputProps={{ "aria-label": "naked" }}
                  />
                )}
                {!isMemberNoteEditing && hasEditorPermission(permissions) && (
                  <div className={classes.actionButtons}>
                    <MUI.IconButton
                      name="edit-member-note-button"
                      className={classes.editButton}
                      size="small"
                      disabled={areButtonsDisabled}
                      onClick={() => setIsMemberNoteEditing(true)}
                    >
                      <Edit />
                    </MUI.IconButton>
                    <MUI.Divider className={classes.divider} />
                    <MUI.IconButton
                      name="delete-member-note-button"
                      className={classes.editButton}
                      size="small"
                      disabled={areButtonsDisabled}
                      onClick={handleRemoveMemberNote}
                    >
                      <Delete />
                    </MUI.IconButton>
                  </div>
                )}
                {isMemberNoteEditing && (
                  <div className={classes.actionButtons}>
                    <div className={classes.progressWrapper}>
                      <MUI.IconButton
                        className={classes.editButton}
                        type="submit"
                        size="small"
                        name="submit-member-note-button"
                        disabled={isMemberNoteSubmitting || areButtonsDisabled}
                      >
                        <Check />
                      </MUI.IconButton>
                      {isMemberNoteSubmitting && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    <MUI.Divider className={classes.divider} />
                    <MUI.IconButton
                      size="small"
                      className={classes.editButton}
                      disabled={areButtonsDisabled}
                      onClick={() => resetMemberNoteEdit(memberNoteForm)}
                    >
                      <Close />
                    </MUI.IconButton>
                  </div>
                )}
              </MUI.Grid>
            </Form>
          )}
        </Formik>
        <div className={classes.helperText}>
          {note.updatedOn ? `Updated on ${getDateTimeString(note.updatedOn)}` : getDateTimeString(note.createdOn)} by{" "}
          {noteOwner.lastName}, {noteOwner.firstName}
        </div>
      </div>
    </>
  );
}

export default MemberNote;
