import ProductModel from "models/product.model";

export const sortedProductList = [
    "MBR",
    "RBR",
    "Network Script",
    "Secondary Market",
    "Intelligent Pricing",
    "Enhanced Advocacy",
    "Focused Package",
    "Balanced Package",
    "Core Package",
    "Care Navigation",
    "Center Of Excellence",
    "AMPS America",
    "AMPS Connect",
    "AMPS Connect Find Provider",
    "AMPS Connect Favorites",
    "AMPS Connect Balance Billing",
    "Apply Rule Set",
    "ASC Bundling",
    "Best Price: Send Requests",
    "Best Price: Select Best Price",
    "Check Matching",
    "Doctor Review",
    "Direct Contract",
    "Grouper",
    "Group Eligibility Load Check",
    "IPU",
    "Multiple Policy Runout",
    "Pharmacy",
    "Patient Eligibility Check",
    "Pre-Negotiation",
    "Prime Health Lab",
    "Patient Consolidation",
    "Process Duplicates",
    "PreService Request",
    "Radiology",
    "Run Script",
    "Telemed",
    "Workers Comp",
  ]
  export function sortProducts(products: ProductModel[]) {
    return products.sort(function (a: ProductModel, b: ProductModel) {
      const product = sortedProductList.indexOf(a.name)
      const productname = sortedProductList.indexOf(b.name)
  
      if (product < productname) { return -1; }
  
      if (product > productname) { return 1; }
      return 0
    })
  }