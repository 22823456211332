import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2)
  },
  pageTitle: {
    display: "flex",
    marginTop: "16px",
    marginBottom: "32px"
  },
  titleText: {
    marginLeft: "8px"
  },
  row: {
    marginBottom: "8px"
  },
  sectionRow: {
    marginBottom: "16px"
  },
  paper: {
    padding: "16px 24px"
  },
  closedRequestPaper: {
    padding: "16px 24px",
    backgroundColor: "#F0FBFF",
    borderRadius: 0,
    border: "1px solid #b9dff9",
    display: "flex",
    alignItems: "center"
  },
  infoIcon: {
    color: "#00B6F0"
  },
  infoMainText: {
    fontSize: "20px",
    fontWeight: "bold",
    marginLeft: "8px"
  },
  infoHelperText: {
    fontSize: "17px",
    color: "#57646E",
    marginLeft: "6px",
    fontWeight: "bold"
  },
  emergencyIcon: {
    verticalAlign: "middle"
  },
  mt: {
    marginTop: "8px"
  },
  expPanel: {
    borderRadius: "4px",
    marginBottom: "16px"
  },
  longTitle: {
    wordBreak: "break-all"
  },
  starIcon: {
    color: "gold"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-24px 0 0 -24px"
  },
  reOpenButton: {
   position:"absolute",
   left:"75%"
  }
}));
