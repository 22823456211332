import React, { useState } from "react";
import LoginHeader from "components/LoginPage/LoginHeader";
import useStyles from "components/LoginPage/styles/LoginPage.style";
import { useStoreActions } from "redux/reducers/hooks";
import { isMFAToken, isTokenValid } from "redux/reducers/authenticationReducer";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { LoginUserForm, LoginUserFormValues } from "./LoginUserForm";
import LoginCodeForm, { LoginMFAFormValues } from "./LoginMFAForm";
import { Redirect } from "react-router-dom";
import Alert from "@material-ui/lab/Alert/Alert";
import LoginTheme from "components/shared/LoginTheme";
import Collapse from "@material-ui/core/Collapse";

export enum LOGIN_STEPS {
  USER_LOGIN,
  MFA_CODE
}

export const SCREEN_TITLES = {
  [LOGIN_STEPS.USER_LOGIN]: "AMPS Care Navigator Sign In",
  [LOGIN_STEPS.MFA_CODE]: "Enter Secure Code"
};

export type ShowAlertProps = {
  message: string;
  severity?: "error" | "success" | "info" | "warning" | undefined;
};

export function LoginPage() {
  const classes = useStyles();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [currentStep, setCurrentLoginStep] = useState(LOGIN_STEPS.USER_LOGIN);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState<ShowAlertProps>({ message: "" });
  const { login, getMfaToken, resendCode, removeToken } = useStoreActions(actions => actions.auth);
  const isValid = isTokenValid();
  const isMfaToken = isMFAToken();

  if (isValid && !isMfaToken) {
    return <Redirect to="/" />;
  }

  const handleLogin = async (values: LoginUserFormValues) => {
    const { code, password } = values;

    setIsFormSubmitting(true);
    handleAlertClose();

    try {
      await getMfaToken({ code, password });
      setCurrentLoginStep(LOGIN_STEPS.MFA_CODE);
    } catch (error) {
      let message: string =
        error.response && error.response.data.message === "Account Locked"
          ? "Your account is currently locked for 15 minutes due to too many failed login attempts."
          : "Authentication Failed. Please try again.";
      showAlertMessage({ message, severity: "error" });
      setIsFormSubmitting(false);
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const handleMFALogin = async (values: LoginMFAFormValues) => {
    const { mfaCode,trusted } = values;

    setIsFormSubmitting(true);
    handleAlertClose();

    try {
      await login({ mfaCode: mfaCode.length ? parseInt(mfaCode) : 0, trusted });
    } catch (error) {
      let message: string = "Invalid secure code.";
      if (error.response && error.response.data && error.response.data.message === "Account Locked") {
        message = "Your account is currently locked for 15 minutes due to too many failed login attempts.";
        setCurrentLoginStep(LOGIN_STEPS.USER_LOGIN);
        removeToken();
      }
      showAlertMessage({ message, severity: "error" });
      setIsFormSubmitting(false);
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const handleResendToken = async () => {
    handleAlertClose();

    try {
      await resendCode();
      showAlertMessage({ message: "New access code sent successfully.", severity: "success" });
    } catch (error) {
      if (error.response && error.response.status === 429)
        showAlertMessage({ message: error.response.data.message, severity: "error" });
    }
  };

  const showAlertMessage = (showAlertProps: ShowAlertProps) => {
    setAlertContent(showAlertProps);
    setIsAlertOpen(true);
  };

  const handleAlertClose = async () => {
    setIsAlertOpen(false);
  };

  const currentScreen = () => {
    switch (currentStep) {
      case LOGIN_STEPS.USER_LOGIN:
        return <LoginUserForm handleLogin={handleLogin} isFormSubmitting={isFormSubmitting} />;
      case LOGIN_STEPS.MFA_CODE:
        return (
          <LoginCodeForm
            handleMFALogin={handleMFALogin}
            handleResendToken={handleResendToken}
            isFormSubmitting={isFormSubmitting}
          />
        );
      default:
        return <LoginUserForm handleLogin={handleLogin} isFormSubmitting={isFormSubmitting} />;
    }
  };

  const title = SCREEN_TITLES[currentStep];

  return (
    <LoginTheme>
      <Paper elevation={0} className={classes.mainContainer}>
        <CssBaseline />
        <Paper elevation={0} className={classes.root}>
          <Paper elevation={5} className={classes.box}>
            <div className="top-box" />
            <div className="text-center">
              <LoginHeader themeStyle="light" title={title} />
              {currentScreen()}
            </div>
          </Paper>
          <Collapse in={isAlertOpen}>
            <Alert onClose={handleAlertClose} severity={alertContent.severity}>
              {alertContent.message}
            </Alert>
          </Collapse>

          <div className={classes.footer}>
            <Typography variant="body1" display="block" align="justify">
              This system contains Protected Health Information as defined under 45 CFR 160.103 ("Protected
              Information"). By logging onto this system, I acknowledge that I will use or disclose such Protected
              Information only for the purposes of performing services as a staff member, consultant or client of
              Advanced Medical Pricing Solutions and for such other purposes as expressly provided in 45 CFR
              164.504(e)(4)
            </Typography>
          </div>
        </Paper>
      </Paper>
    </LoginTheme>
  );
}

export default LoginPage;
