import { Action, Thunk, action, thunk } from "easy-peasy";
import sortBy from "lodash/sortBy";
import axios from "axios";
import UserModel from "models/user.model";
import { AgentReducerModel } from "types";

const setAgents: Action<AgentReducerModel, UserModel[]> = action((state, agents) => {
  state.agents = agents;
});

const getAgents: Thunk<AgentReducerModel, string> = thunk(async (actions, lastName) => {
  const response: any = await axios.get("/users", {
    params: {
      lastName,
      isEmployee: true,
      take: 25
    }
  });

  const sortedAgents = sortBy(response.data, ["lastName", "firstName"]);
  actions.setAgents(sortedAgents);
  return sortedAgents;
});

const getAgentById: Thunk<AgentReducerModel, number> = thunk(async (actions, id) => {
  const response: any = await axios.get("/users", {
    params: {
      userIds: [id],
      take: 1
    }
  });
  return response && response.data ? response.data[0] : null;
});

const AgentReducer: AgentReducerModel = {
  agents: [],
  setAgents,
  getAgents,
  getAgentById
};

export default AgentReducer;
