import React from "react";
import { MUI } from "@amps/material-ui";
import clsx from "clsx";

import RequestStatuses from "utils/constants/RequestStatuses";
import RequestStatusModel from "models/requestStatus.model";

const useStyles = MUI.makeStyles(theme => ({
  chip: {
    height: "auto",
    minHeight: "24px",
    fontWeight: 600,
    borderRadius: "4px"
  },
  actionRequired: {
    backgroundColor: "rgba(161,35,16,0.25)"
  },
  open: {
    backgroundColor: "rgba(161,35,16,0.25)"
  },
  pending: {
    backgroundColor: "rgba(255,213,121,0.35)"
  },
  closed: {
    backgroundColor: "rgba(58,219,118,0.3)"
  },
  label: {
    whiteSpace: "normal"
  }
}));

interface Props {
  status: RequestStatusModel | string;
  additionalStatus?: string | null;
}

function RequestStatusLabel(props: Props) {
  const { status, additionalStatus } = props;
  const classes = useStyles();

  const getColorClass = (status: RequestStatusModel | string) => {
    const statusName = typeof status === "string" ? status : status.name;

    switch (statusName) {
      case RequestStatuses.ACTION_REQUIRED:
        return classes.actionRequired;
      case RequestStatuses.CLOSED:
        return classes.closed;
      case RequestStatuses.OPEN:
        return classes.open;
      case RequestStatuses.PENDING:
      case RequestStatuses.PENDING_SCA:
        return classes.pending;
    }
  };

  const colorClass = getColorClass(status);
  const label =
    typeof status === "string" ? status : additionalStatus ? `${status.name} - ${additionalStatus}` : status.name;

  return (
    <MUI.Chip
      label={label}
      size="small"
      className={clsx(classes.chip, colorClass)}
      classes={{
        label: classes.label
      }}
    />
  );
}

export default RequestStatusLabel;
