import UserModel from "models/user.model";

const getUserFullName = (user: UserModel) => {
  const { id, lastName, firstName } = user;

  if (parseInt(id) < 0 && firstName) return firstName;

  return [lastName, firstName]
    .filter((name?: string | null) => !!name)
    .join(", ");
};

export { getUserFullName };
