import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { MUI, Button } from "@amps/material-ui";
import clsx from "clsx";
import useStyles from "components/RequestPage/styles/AttachFileModal.style";
import RequestModel from "models/request.model";
import { useStoreActions } from "redux/reducers/hooks";
import { Close } from "@material-ui/icons";

interface AttachFileModalProps {
  request: RequestModel;
  open: boolean;
  onClose: Function;
}

const maxFilesCount = 20;

export function AttachFileModal(props: AttachFileModalProps) {
  const { request, open, onClose } = props;
  const [isDragOver, setIsDragOver] = useState(false);
  const [isDropped, setIsDropped] = useState(false);
  const [files, setFiles] = useState([] as any);
  const [areFilesUploading, setAreFilesUploading] = useState(false);
  const classes = useStyles();
  const requestFilesLength = request.files ? request.files.length : 0;
  const isMaxCountReached = requestFilesLength + files.length >= maxFilesCount;
  const isDisabled = isMaxCountReached || areFilesUploading;
  const { getRequestById, uploadRequestFiles } = useStoreActions(actions => actions.request);

  const onDrop = useCallback(
    acceptedFiles => {
      setIsDragOver(false);

      if (isMaxCountReached) return;

      const filteredFilesBySize = acceptedFiles.filter(
        (file: any) => file.size <= 5242880 // 5 MB
      );

      if (!filteredFilesBySize.length) return;

      setIsDropped(true);
      setFiles([...files, ...filteredFilesBySize].slice(0, maxFilesCount - requestFilesLength));
    },
    [files, isMaxCountReached, requestFilesLength]
  );

  const { getRootProps, getInputProps, inputRef, open: openFileDialog } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true
  });

  const onDragOver = () => {
    if ((inputRef.current as any).disabled) return;

    setIsDropped(false);
    setIsDragOver(true);
  };

  const onDragLeave = () => {
    setIsDragOver(false);
  };

  const removeFileFromArray = (fileIndex: number) => {
    const filteredArray = files.filter((file: any, index: number) => index !== fileIndex);
    setFiles(filteredArray);
  };

  const handleFilesUpload = async () => {
    setAreFilesUploading(true);

    try {
      const { id } = request;
      const formData = new FormData();

      files.forEach((file: any) => {
        formData.append("file", file);
      });

      await uploadRequestFiles({ requestId: id, filesFormData: formData });
      await getRequestById(request.id);

      onClose();
    } finally {
      setAreFilesUploading(false);
    }
  };

  return (
    <MUI.Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth={true}
      disableBackdropClick
    >
      <MUI.DialogContent className={classes.dialogPaper}>
        <div
          {...getRootProps({
            onDragOver,
            onDragLeave
          })}
          className={clsx(
            classes.dropZone,
            isDragOver && classes.dragOver,
            isDropped && !!files.length && classes.drop,
            isDisabled && classes.disabledZone
          )}
        >
          <input {...getInputProps()} disabled={isDisabled} />
          <div className={classes.dropZoneTitle}>
            {isMaxCountReached && `${maxFilesCount} files limit reached`}
            {!isMaxCountReached && (
              <>
                Drag&Drop files here
                <div className={classes.dropZoneTitleSeparator}>or</div>
              </>
            )}
          </div>
          {!isMaxCountReached && (
            <Button
              onClick={openFileDialog}
              variant={!isDisabled ? "contained" : "text"}
              color="primary"
              autoFocus
              disabled={isDisabled}
            >
              Select Files
            </Button>
          )}
        </div>
        <MUI.Typography variant="body2" className={classes.helperText}>
          You cannot upload a file larger than 5MB
        </MUI.Typography>
        {!!files.length && (
          <MUI.Grid container spacing={0}>
            {files.map((file: any, index: number) => {
              return (
                <MUI.Grid item xs={12} key={`${file.name}-${index}`} className={classes.fileWrapper}>
                  <MUI.Typography variant="body2" color="textSecondary" className={classes.fileName}>
                    {file.name}
                  </MUI.Typography>
                  <MUI.IconButton
                    className={classes.removeButton}
                    size="small"
                    onClick={() => removeFileFromArray(index)}
                  >
                    <Close />
                  </MUI.IconButton>
                </MUI.Grid>
              );
            })}
          </MUI.Grid>
        )}
      </MUI.DialogContent>
      <MUI.DialogActions>
        <Button id="cancel-attach-file-button" onClick={onClose} color="primary">
          Cancel
        </Button>
        <div className={classes.progressWrapper}>
          <Button
            id="submit-files-button"
            variant={!!files.length ? "contained" : "text"}
            color="primary"
            autoFocus
            disabled={!files.length || areFilesUploading}
            onClick={handleFilesUpload}
          >
            Upload
          </Button>
          {areFilesUploading && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
}

export default AttachFileModal;
