import React, { useState, useEffect } from "react";
import { MUI } from "@amps/material-ui";
import { getDateString } from "utils/dateTimeUtils";

import useStyles from "components/DashboardPage/styles/MemberSearchTable.style";
import MemberModel from "models/member.model";
import { StarRate } from "@material-ui/icons";

type Props = {
  members: MemberModel[] | null;
  onSelectMember: Function;
};

export function MemberSearchTable(props: Props) {
  const { members, onSelectMember } = props;
  const [selectedMember, setSelectedMember] = useState(null as MemberModel | null);
  const classes = useStyles();

  useEffect(() => {
    // clear selected member when members have been changed
    setSelectedMember(null);
  }, [members]);

  const handleSelectMember = (member: MemberModel) => {
    setSelectedMember(member);
    onSelectMember(member);
  };

  const renderDateOfBirth = (member: MemberModel) => {
    if (!member.dateOfBirth) return null;

    return (
      <span className="date-cell">
        {getDateString(member.dateOfBirth, "UTC")}
        <b> ({member.age}y)</b>
      </span>
    );
  };

  if (!members) return null;

  if (!members.length) {
    return (
      <MUI.Paper className={classes.paper}>
        <MUI.Typography className={classes.noOptions}>No member with this name or ID</MUI.Typography>
      </MUI.Paper>
    );
  }
  return (
    <MUI.Paper className={classes.paper}>
      <MUI.Table className={classes.table} size="small">
        <MUI.TableHead className={classes.tableHead}>
          <MUI.TableRow>
            <MUI.TableCell>Name</MUI.TableCell>
            <MUI.TableCell>Date of Birth (Age)</MUI.TableCell>
            <MUI.TableCell>ID</MUI.TableCell>
          </MUI.TableRow>
        </MUI.TableHead>
        <MUI.TableBody>
          {members.map(member => (
            <MUI.TableRow
              key={member.id}
              onClick={() => handleSelectMember(member)}
              hover
              classes={{
                hover: classes.hover
              }}
              selected={!!selectedMember && selectedMember.id === member.id}
            >
              <MUI.TableCell component="th" scope="row" className={classes.cell}>
                {member.isVip ? <StarRate className={classes.name} /> : ""}
                {member.lastName}, {member.firstName}
              </MUI.TableCell>
              <MUI.TableCell>{renderDateOfBirth(member)}</MUI.TableCell>
              <MUI.TableCell>{member.memberNumber}</MUI.TableCell>
            </MUI.TableRow>
          ))}
        </MUI.TableBody>
      </MUI.Table>
    </MUI.Paper>
  );
}

export default MemberSearchTable;
