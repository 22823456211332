import React from "react";
import { MUI } from "@amps/material-ui";
import clsx from "clsx";
import { getDateTimeString, getDateString } from "utils/dateTimeUtils";
import useStyles from "components/RequestPage/styles/ActionHistoryLog.style";

import ActivityLogModel from "models/activityLog.model";
import ActivityLogTypes from "utils/constants/ActivityLogTypes";
import ServiceStatusLabel from "components/RequestPage/ServiceStatusLabel";
import RequestStatusLabel from "components/shared/RequestStatusLabel";

type ActionHistoryLogProps = {
  activityLog: ActivityLogModel;
};

export function ActionHistoryLog(props: ActionHistoryLogProps) {
  const { activityLog } = props;
  const classes = useStyles();

  const getActivityLabel = (activityLog: ActivityLogModel) => {
    const { data, type } = activityLog;

    switch (type) {
      case ActivityLogTypes.TREATMENT_AND_DETAILS_FORM_UPDATING:
        return "Treatment and Details updated";
      case ActivityLogTypes.SERVICE_COMPLETED:
        return `${data.stage} stage has been completed`;
      case ActivityLogTypes.SERVICE_STAGE_UPDATING:
        return `Stage Changed to ${data.changedTo}`;
      case ActivityLogTypes.SERVICE_STATUS_UPDATING:
        return (
          <>
            Service Status updated from <ServiceStatusLabel status={data.changedFrom} /> to{" "}
            <ServiceStatusLabel status={data.changedTo} />
          </>
        );
      case ActivityLogTypes.SERVICE_CLOSING:
        return `Service closed. Closing reason is ${data.serviceClosingReason}`;
      case ActivityLogTypes.REQUEST_STATUS_UPDATING:
        if (data.changedFrom === "Open" || data.changedTo === "Open") {
          return (
            <>
              Request Status updated from <RequestStatusLabel status={data.changedFrom} /> to{" "}
              <RequestStatusLabel status={data.changedTo} />
            </>
          );
        } else return null;
      case ActivityLogTypes.REQUEST_CLOSING:
        return "Request completed";
      case ActivityLogTypes.FOLLOWUP_DATE_ADDING:
        return `${data.name}, ${data.stage}: Followup date due ${getDateString(data.followUp)}`;
      case ActivityLogTypes.FOLLOWUP_DATE_UPDATING:
        return `${data.name}, ${data.stage}: Followup date updated ${getDateString(data.followUp)}`;
      case ActivityLogTypes.FOLLOWUP_DATE_REMOVING:
        return `${data.name}, ${data.stage}: Followup date removed`;
      case ActivityLogTypes.INTERNAL_NOTES_ADDING:
        return `${data.name}, ${data.stage}: Internal notes added`;
      case ActivityLogTypes.INTERNAL_NOTES_UPDATING:
        return `${data.name}, ${data.stage}: Internal notes updated`;
      case ActivityLogTypes.INTERNAL_NOTES_REMOVING:
        return `${data.name}, ${data.stage}: Internal notes removed`;
      case ActivityLogTypes.EXTERNAL_NOTES_UPDATING:
        return `${data.name}, ${data.stage}: External notes updated`;
      case ActivityLogTypes.SCA_REQUEST_CREATION:
        return "Zoho provider request created";
      case ActivityLogTypes.SCA_REQUEST_STATUS_CHANGING:
        return `Zoho provider request status updated to ${data.status}`;
      default:
        return null;
    }
  };

  const activityLabel = getActivityLabel(activityLog);

  if (!activityLabel) return null;

  return (
    <MUI.Paper className={classes.historyLogPaper}>
      <MUI.Grid item xs={8}>
        <div className={classes.activityLabel}>
          <b>{activityLabel}</b>
        </div>
      </MUI.Grid>
      <MUI.Grid item xs={4} className={classes.helperGrid}>
        <div className={clsx(classes.helperBox, classes.helperText)}>
          <span>{getDateTimeString(activityLog.createdOn)}</span>
          <div>{activityLog.createdByName}</div>
        </div>
      </MUI.Grid>
    </MUI.Paper>
  );
}

export default ActionHistoryLog;
