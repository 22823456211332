import React from "react";
import MaskedInput from "react-text-mask";

const maskFunction = (maskRegEx: RegExp) => (rawValue: string) => {
  const mask = new Array(rawValue.length);
  mask.fill(maskRegEx);
  return mask;
};

export default function TextMaskField(props: any) {
  const { inputRef, mask, ...other } = props;

  return (
    <MaskedInput
      {...other}
      guide={false}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={maskFunction(mask)}
    />
  );
}
