import { Action, action } from "easy-peasy";
import { ModalManagerReducerModel, ModalType } from "types";

const openModal: Action<ModalManagerReducerModel, ModalType> = action(
  (state, modalPayload) => {
    state.modals = state.modals.concat(modalPayload);
  }
);

const closeModal: Action<ModalManagerReducerModel, string> = action(
  (state, modalType) => {
    state.modals = state.modals.filter(modal => modal.modalType !== modalType);
  }
);

const ModalReducer: ModalManagerReducerModel = {
  modals: [],
  openModal,
  closeModal
};

export default ModalReducer;
