import React, { Fragment } from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Logo from "components/shared/Logo";
import useStyles from "components/LoginPage/styles/LoginHeader.style";

type Props = {
  title: string;
  themeStyle: "light" | "dark" | undefined;
};

export const LoginHeader = (props: Props) => {
  const { themeStyle, title } = props;
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.root}>
        <Logo className={classes.logoSection} themeStyle={themeStyle} />
        <Divider
          variant="middle"
          orientation="vertical"
          className={classes.divider}
        />
        <Typography
          variant="h6"
          component="h6"
          className={classes.titleSection}
        >
          {title}
        </Typography>
      </div>
      <Divider variant="middle" />
    </Fragment>
  );
};

export default LoginHeader;
