import React, { ReactNode } from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import merge from "lodash/merge";

type Props = {
  children: ReactNode;
};

const CareNavigatorTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      // Related to https://advancedpricing.atlassian.net/browse/DA-724
      input: {
        "&::-ms-clear": {
          display: "none"
        }
      }
    }
  }
});

export default (props: Props) => {
  const { children } = props;

  return (
    <MuiThemeProvider
      theme={outerTheme => merge(CareNavigatorTheme, outerTheme)}
    >
      {children}
    </MuiThemeProvider>
  );
};
