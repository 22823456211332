import React from "react";
import { MUI } from "@amps/material-ui";

import useStyles from "components/RequestPage/styles/PlanNotes.style";

import { ExpandMore } from "@material-ui/icons";
import { Paper, TableCell, Typography } from "@material-ui/core";
import { PlanNoteModel } from "models/planNote.model";
import FileIcon from "@material-ui/icons/InsertDriveFile";

interface PlanNotesProps {
  planNotes: PlanNoteModel[] | null;
  isLoading: boolean;
}

const planNoteTypeMapping: { [key: string]: string } = {
  "1": "Add-Pay/Authority Request",
  "2": "Care Navigation Incentives",
  "3": "Claim Pricing/Exception",
  "4": "Client Detail",
  "5": "Contact/Info",
  "6": "Dispute Threshold",
  "7": "Network Information",
  "8": "Other",
  "9": "Termination/Run-out",
  "10": "TPA Name History",
  "11": "TPA/Client Note",
  "-1": "Original"
};

export default function PlanNotes(props: PlanNotesProps) {
  const { planNotes, isLoading } = props;
  const classes = useStyles();

  return (
    <MUI.Accordion className={classes.expPanel}>
      <MUI.AccordionSummary expandIcon={<ExpandMore />}>
        <MUI.Grid container spacing={0} alignItems="center" className={classes.panelTitle}>
          <span>
            <b>Plan notes</b>
          </span>
        </MUI.Grid>
      </MUI.AccordionSummary>
      <MUI.Divider variant="middle" />
      <MUI.AccordionDetails>
        {isLoading ? (
          <MUI.Grid item xs={12}>
            <MUI.LinearProgress />
          </MUI.Grid>
        ) : (
          <MUI.Grid container spacing={2}>
            {planNotes && planNotes.length > 0 ? (
              <MUI.TableContainer component={Paper}>
                <MUI.Table>
                  <MUI.TableHead>
                    <MUI.TableRow>
                      {["Type", "Attached File", "Note", "Date Created", "User"].map(header => (
                        <MUI.TableCell key={header} style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                          {header}
                        </MUI.TableCell>
                      ))}
                    </MUI.TableRow>
                  </MUI.TableHead>
                  <MUI.TableBody>
                    {planNotes.map(note => (
                      <MUI.TableRow key={note.id}>
                        <TableCell>{planNoteTypeMapping[note.planNoteTypeId] || "N/A"}</TableCell>
                        <TableCell>{note.attachedFilename ? <FileIcon /> : "None"}</TableCell>
                        <TableCell>{note.note || "N/A"}</TableCell>
                        <TableCell>{new Date(note.createTs).toLocaleDateString()}</TableCell>
                        <TableCell>{note.createdUser.code || "N/A"}</TableCell>
                      </MUI.TableRow>
                    ))}
                  </MUI.TableBody>
                </MUI.Table>
              </MUI.TableContainer>
            ) : (
              <Typography className={classes.noOptions}>No Plan Notes</Typography>
            )}
          </MUI.Grid>
        )}
      </MUI.AccordionDetails>
    </MUI.Accordion>
  );
}
