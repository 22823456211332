import React from "react";
import { MUI, Button } from "@amps/material-ui";
import { Modals } from "utils/modalUtils";
import { useStoreActions } from "redux/reducers/hooks";

const useStyles = MUI.makeStyles(theme => ({
  progressWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px"
  }
}));

type Props = {
  onCancel?: Function;
  onOk?: Function;
  cancelButtonText?: string;
  okButtonText?: string;
  messageText?: string;
  isOkInProgress?: boolean;
  messageContent?: JSX.Element;
};

export function ConfirmationModal(props: Props) {
  const classes = useStyles();
  const {
    onCancel,
    onOk,
    messageText,
    messageContent,
    isOkInProgress,
    cancelButtonText = "Back",
    okButtonText = "Discard Changes and Proceed"
  } = props;
  const { closeModal } = useStoreActions(actions => actions.modal);

  const onOkClick = () => {
    closeModal(Modals.Confirmation);
    if (onOk) onOk();
  };

  const onCancelClick = () => {
    closeModal(Modals.Confirmation);
    if (onCancel) onCancel();
  };

  return (
    <MUI.Dialog open={true} disableBackdropClick>
      <MUI.DialogContent id="confirmation-dialog">
        <MUI.Typography variant="h6">Are You Sure?</MUI.Typography>
        {messageText && <MUI.Typography>{messageText}</MUI.Typography>}
        {messageContent && <MUI.Typography>{messageContent}</MUI.Typography>}
      </MUI.DialogContent>
      <MUI.DialogActions>
        <Button id="confirmation-back-button" onClick={onCancelClick} color="primary">
          {cancelButtonText}
        </Button>

        <div className={classes.progressWrapper}>
          <Button
            id="confirmation-ok-button"
            variant="contained"
            color="primary"
            onClick={onOkClick}
            disabled={isOkInProgress}
          >
            {okButtonText}
          </Button>
          {isOkInProgress && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
}

export default ConfirmationModal;
