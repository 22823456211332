const formatWithPattern = (
  numStr: string,
  format: string,
  mask: string = "_"
) => {
  let hashCount = 0;
  const formattedNumberArray = format.split("");
  for (let i = 0; i < format.length; i++) {
    if (format[i] === "#") {
      formattedNumberArray[i] = numStr[hashCount] || mask;
      hashCount += 1;
    }
  }
  return formattedNumberArray.join("");
};

export { formatWithPattern };
