import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  dialogSubtitle: {
    marginTop: 16
  },
  dialogPaper: {
    overflow: "visible",
    padding: "18px 24px 8px",
    minHeight: "320px"
  },
  row: {
    marginBottom: "16px",
    display: "grid"
  },
  rowTitle: {
    marginBottom: "8px",
    fontSize: "16px"
  },
  subRowTitle: {
    fontSize: "16px",
    marginRight: "15px"
  },
  paperWrapper: {
    padding: 16,
    backgroundColor: "#e6eaed"
  },
  paperTitle: {
    marginBottom: "20px",
    fontSize: "16px"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: 3,
    marginLeft: -12
  },
  loadingPaper: {
    backgroundColor: "#e6eaed4c",
    position: "absolute",
    height: "100%",
    width: "100%"
  },
  noOptionsPaper: {
    maxHeight: "165px",
    overflow: "auto",
    marginBottom: "16px"
  },
  noOptions: {
    textAlign: "center",
    backgroundColor: "#e6eaed"
  }
}));
