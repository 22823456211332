import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(() => ({
  toolbarContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    marginTop: "17px"
  },
  toolbarButtons: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  toolbarFilters: {
    width: "100%",
    marginTop: 16
  },
  buttons: {
    width: "33.33%"
  },
  rowItem: {
    flexGrow: 0,
    marginRight: 20,
    "&--right": {
      marginLeft: "auto",
      marginRight: 0
    }
  },
  textField: {
    fontSize: "14px",
    padding: "12px",
    flexWrap: "wrap"
  },
  downloadRequestsRoot: {
    display: "inline-block"
  }
}));
