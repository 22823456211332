import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import LoginPage from "./components/LoginPage/LoginPage";
import DashboardPage from "./components/DashboardPage/DashboardPage";
import RequestPage from "./components/RequestPage/RequestPage";
import ReportsPage from "./components/ReportsPage/ReportsPage";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import PrivateRoute from "components/HOC/PrivateRoute";
export const history = createBrowserHistory();

export default function AppRouter() {
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute path="/" exact component={DashboardPage} />
        <PrivateRoute path="/request/:id" exact component={RequestPage} />
        <PrivateRoute path="/reports" exact component={ReportsPage} />
        <Route path="/login" component={LoginPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}
