import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  completed: {
    backgroundColor: "rgba(58,219,118,0.3)"
  },
  active: {
    backgroundColor: "rgba(255,213,121,0.35)"
  },
  span: {
    borderRadius: 0,
    border: "1px solid #9AA2A8",
    marginRight: "1px",
    color: "#08090A",
    fontWeight: "bold",
    padding: ".3rem .5rem",
    fontSize: ".7rem",
    lineHeight: 1,
    whiteSpace: "nowrap",
    "&:hover": {
      cursor: "help"
    }
  }
}));
