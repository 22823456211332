import axios from "axios";
import RequestModel from "models/request.model";
import { Action, action, Thunk, thunk, Computed, computed } from "easy-peasy";
import {
  RequestReducerModel,
  RequestsSearchCriteriaType,
  UploadRequestFilePayload,
  FetchFilePayload,
  RequestState,
  SearchRequestsResponse,
  RequestsFilterCriteria,
  StoreModel
} from "types";
import { openDefaultSortBy, defaultSortDirection } from "components/DashboardPage/RequestsTable/requestsTableUtils";
import { omit } from "lodash";
import { getFilteredData, getSortedData } from "./requestReducerUtils";
import { RequestPriority } from "types/RequestsTypes";

const setRequest: Action<RequestReducerModel, RequestModel> = action((state, request) => {
  state.request = request;
});

const setRequests: Action<RequestReducerModel, SearchRequestsResponse> = action((state, requests) => {
  state.requestsData = requests;
});

const createRequest: Thunk<RequestReducerModel, {}> = thunk(async (actions, data) => {
  const response: any = await axios.post("/requests", data);
  return response;
});

const getRequestById: Thunk<RequestReducerModel, number> = thunk(async (actions, requestId) => {
  const response: any = await axios.get(`/requests/${requestId}`);
  actions.setRequest(response);
});

const closeRequest: Thunk<RequestReducerModel, number> = thunk(async (actions, requestId) => {
  const response: any = await axios.patch(`/requests/${requestId}`, {
    closeRequest: true
  });
  return response;
});

const reOpenClosedRequest: Thunk<RequestReducerModel, number> = thunk(async (actions, requestId) => {
  const response: any = await axios.patch(`/requests/reopenrequest/${requestId}`, {
    reOpenClosedRequest: true
  });
  return response;
});

const updateRequestPriority: Thunk<RequestReducerModel,RequestPriority> = thunk(async (actions, requestPriority) => {
  const response: any = await axios.patch(`/requests/${requestPriority.requestId}`, {
    priority:requestPriority.priority,
    closeRequest:false
  });
  return response;
});


const setSearchCriteria: Action<RequestReducerModel, RequestsSearchCriteriaType> = action((state, searchCriteria) => {
  state.searchCriteria = searchCriteria;
});

const setFilterCriteria: Action<RequestReducerModel, RequestsFilterCriteria> = action((state, filterCriteria) => {
  state.filterCriteria = filterCriteria;
});

const getRequests: Thunk<RequestReducerModel, RequestsSearchCriteriaType> = thunk(async (actions, searchCriteria) => {
  const response: any = await axios.post("/requests/search", searchCriteria);
  actions.setRequests(response);
  return response.data;
});

const getAllRequests: Thunk<RequestReducerModel, RequestsSearchCriteriaType> = thunk(
  async (actions, searchCriteria) => {
    const response: any = await axios.post("/requests/search", searchCriteria);
    return response;
  }
);

const getMoreRequests: Thunk<RequestReducerModel, RequestsSearchCriteriaType> = thunk(
  async (actions, searchCriteria, { getState }) => {
    const { requestsData } = getState();
    const response: any = await axios.post("/requests/search", searchCriteria);
    const newReportData = [...requestsData.data, ...response.data];

    actions.setRequests({
      ...requestsData,
      data: newReportData,
      totalCount: response.totalCount
    });
    return response;
  }
);

const uploadRequestFiles: Thunk<RequestReducerModel, UploadRequestFilePayload> = thunk(async (actions, payload) => {
  const response: any = await axios.post(`requests/${payload.requestId}/files`, payload.filesFormData);

  return response;
});

const fetchFile: Thunk<RequestReducerModel, FetchFilePayload> = thunk(async (actions, payload) => {
  return axios
    .get(`files/${payload.fileId}`, { responseType: "arraybuffer" })
    .then((resp: any) => {
      return new Blob([resp]);
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      a.download = payload.name;
      document.body.appendChild(a);
      a.click();

      if (a.parentNode) {
        a.parentNode.removeChild(a);
      }

      window.URL.revokeObjectURL(url);
    })
    .catch(erro => console.error(erro));
});

export const requestsSelector: Computed<RequestReducerModel, RequestModel[], StoreModel> = computed(
  [state => state.requestsData, state => state.filterCriteria, (state, storeState) => storeState.auth.user],
  (requestsData, filterCriteria, loggedInUser) => {
    if (!requestsData) return [];

    const filteredRequests = getFilteredData(
      requestsData.data,
      loggedInUser,
      filterCriteria.onlyMyRequests,
      filterCriteria.searchText
    );

    return getSortedData(filteredRequests, filterCriteria.sortBy, filterCriteria.sortDirection);
  }
);

export const dashboardFiltersSelector: Computed<RequestReducerModel, RequestsSearchCriteriaType, StoreModel> = computed(
  [state => state.searchCriteria],
  searchCriteria => {
    return omit(searchCriteria, ["skip", "take"]);
  }
);

export const initialState: RequestState = {
  request: null,
  requestsData: {
    clientId: null,
    clientGroupId: null,
    members: [],
    createdByAgents: [],
    updatedByAgents: [],
    requestCreatedStart: null,
    requestCreatedEnd: null,
    requestUpdatedEnd: null,
    requestUpdatedStart: null,
    skip: 0,
    take: 0,
    data: [],
    totalCount: 0
  },
  searchCriteria: {
    skip: 0,
    take: 500,
    openOnly: true
  },
  filterCriteria: {
    onlyMyRequests: false,
    searchText: "",
    sortBy: openDefaultSortBy,
    sortDirection: defaultSortDirection
  }
};

const RequestReducer: RequestReducerModel = {
  ...initialState,
  dashboardFilters: dashboardFiltersSelector,
  requests: requestsSelector,
  setRequest,
  setRequests,
  createRequest,
  closeRequest,
  updateRequestPriority,
  getAllRequests,
  getRequestById,
  getMoreRequests,
  getRequests,
  uploadRequestFiles,
  fetchFile,
  setFilterCriteria,
  setSearchCriteria,
  reOpenClosedRequest
};

export default RequestReducer;
