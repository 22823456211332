import React from "react";
import { MUI } from "@amps/material-ui";
import { Field } from "formik";
import clsx from "clsx";

import FormikTextField from "containers/FormikTextField";
import NumberFormatField, { getMoneyValue } from "containers/NumberFormatField";

import useStyles from "components/shared/styles/MemberBenefits.style";

interface MemberBenefitsProps {
  viewMode: boolean;
  enableTooltips?: boolean;
  form: any;
}

export default function MemberBenefits(props: MemberBenefitsProps) {
  const { viewMode, form, enableTooltips } = props;
  const classes = useStyles();

  const isAmountAllowed = (values: any) => {
    const value = getMoneyValue(values);

    // maximum is 1 billion
    return !value || (value < 1000000000 && value > -1000000000);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.row}>
        <MUI.Typography variant="body2" color="textSecondary" className={classes.labelTitle}>
          Member Benefits
        </MUI.Typography>
        <MUI.Grid container spacing={3}>
          <MUI.Grid item xs={4}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              Deductible
            </MUI.Typography>
            <Field
              name="deductible"
              component={FormikTextField}
              variant="outlined"
              fullWidth
              placeholder="00.00"
              tooltipText={enableTooltips ? form.values.deductible : ""}
              InputProps={{
                startAdornment: <MUI.InputAdornment position="start">$</MUI.InputAdornment>,
                classes: {
                  root: classes.bw,
                  input: classes.textField,
                  disabled: clsx(viewMode && classes.disabledTextField)
                },
                inputComponent: NumberFormatField
              }}
              // eslint-disable-next-line
              inputProps={{
                fieldType: "money",
                isAllowed: isAmountAllowed
              }}
              disabled={viewMode}
            />
          </MUI.Grid>
          <MUI.Grid item xs={4}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              Deductible MET
            </MUI.Typography>
            <Field
              name="deductibleMET"
              component={FormikTextField}
              variant="outlined"
              fullWidth
              placeholder="00.00"
              tooltipText={enableTooltips ? form.values.deductibleMET : ""}
              InputProps={{
                startAdornment: <MUI.InputAdornment position="start">$</MUI.InputAdornment>,
                classes: {
                  root: classes.bw,
                  input: classes.textField,
                  disabled: clsx(viewMode && classes.disabledTextField)
                },
                inputComponent: NumberFormatField
              }}
              // eslint-disable-next-line
              inputProps={{
                fieldType: "money",
                isAllowed: isAmountAllowed
              }}
              disabled={viewMode}
            />
          </MUI.Grid>
        </MUI.Grid>
      </div>
      <div className={classes.row}>
        <MUI.Grid container spacing={3}>
          <MUI.Grid item xs={4}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              OOP
            </MUI.Typography>
            <Field
              name="oop"
              component={FormikTextField}
              variant="outlined"
              fullWidth
              placeholder="00.00"
              tooltipText={enableTooltips ? form.values.oop : ""}
              InputProps={{
                startAdornment: <MUI.InputAdornment position="start">$</MUI.InputAdornment>,
                classes: {
                  root: classes.bw,
                  input: classes.textField,
                  disabled: clsx(viewMode && classes.disabledTextField)
                },
                inputComponent: NumberFormatField
              }}
              // eslint-disable-next-line
              inputProps={{
                fieldType: "money",
                isAllowed: isAmountAllowed
              }}
              disabled={viewMode}
            />
          </MUI.Grid>
          <MUI.Grid item xs={4}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              OOP MET
            </MUI.Typography>
            <Field
              name="oopMet"
              component={FormikTextField}
              variant="outlined"
              fullWidth
              placeholder="00.00"
              tooltipText={enableTooltips ? form.values.oopMet : ""}
              InputProps={{
                startAdornment: <MUI.InputAdornment position="start">$</MUI.InputAdornment>,
                classes: {
                  root: classes.bw,
                  input: classes.textField,
                  disabled: clsx(viewMode && classes.disabledTextField)
                },
                inputComponent: NumberFormatField
              }}
              // eslint-disable-next-line
              inputProps={{
                fieldType: "money",
                isAllowed: isAmountAllowed
              }}
              disabled={viewMode}
            />
          </MUI.Grid>
          <MUI.Grid item xs={4}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              Co-Pay
            </MUI.Typography>
            <Field
              name="coPayAmount"
              component={FormikTextField}
              variant="outlined"
              fullWidth
              placeholder="00.00"
              tooltipText={enableTooltips ? form.values.coPayAmount : ""}
              InputProps={{
                startAdornment: <MUI.InputAdornment position="start">$</MUI.InputAdornment>,
                classes: {
                  root: classes.bw,
                  input: classes.textField,
                  disabled: clsx(viewMode && classes.disabledTextField)
                },
                inputComponent: NumberFormatField
              }}
              // eslint-disable-next-line
              inputProps={{
                fieldType: "money",
                isAllowed: isAmountAllowed
              }}
              disabled={viewMode}
            />
          </MUI.Grid>
        </MUI.Grid>
      </div>
      <div className={classes.row}>
        <MUI.Grid container spacing={3}>
          <MUI.Grid item xs={6}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              Co-Insurance
            </MUI.Typography>
            <Field
              name="coInsurance"
              component={FormikTextField}
              variant="outlined"
              fullWidth
              tooltipText={enableTooltips ? form.values.coInsurance : ""}
              disabled={viewMode}
              InputProps={{
                classes: {
                  input: classes.textField,
                  disabled: clsx(viewMode && classes.disabledTextField)
                }
              }}
            />
          </MUI.Grid>
          <MUI.Grid item xs={6}>
            <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
              Other
            </MUI.Typography>
            <Field
              name="other"
              component={FormikTextField}
              variant="outlined"
              fullWidth
              tooltipText={enableTooltips ? form.values.other : ""}
              disabled={viewMode}
              InputProps={{
                classes: {
                  input: classes.textField,
                  disabled: clsx(viewMode && classes.disabledTextField)
                }
              }}
            />
          </MUI.Grid>
        </MUI.Grid>
      </div>
    </div>
  );
}
