import React, { useState, useRef } from "react";
import { MUI } from "@amps/material-ui";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import useStyles from "components/shared/styles/Header.style";
import Logo from "./Logo";
import { useStoreState, useStoreActions } from "redux/reducers/hooks";
import { EmojiPeople, Description, AccountCircle, KeyboardArrowDown, ExitToApp } from "@material-ui/icons";

export function Header() {
  const { user } = useStoreState(state => state.auth);
  const logout = useStoreActions(actions => actions.auth.logout);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event: any) {
    const { current } = anchorRef as any;

    if (current && current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  if (!user) return null;

  return (
    <MUI.AppBar color="default" position="relative">
      <MUI.Toolbar>
        <Logo className={classes.logoSection} themeStyle="light" size={80} />
        <MUI.Typography variant="h6" color="inherit" noWrap className={classes.title}>
          Care Navigator
        </MUI.Typography>
        <div>
          <MUI.Button component={RouterLink} to="/">
            <EmojiPeople className={classes.leftIcon} />
            <span>Requests</span>
          </MUI.Button>
        </div>
        <div>
          <MUI.Button component={RouterLink} to="/reports">
            <Description className={classes.leftIcon} />
            <span>Reports</span>
          </MUI.Button>
        </div>
        <div>
          <MUI.Button ref={anchorRef} aria-controls="menu-list-grow" aria-haspopup="true" onClick={handleToggle}>
            <AccountCircle className={classes.leftIcon} />
            <span id="user-name">
              {user.firstName} {user.lastName}
            </span>
            <KeyboardArrowDown />
          </MUI.Button>
          <MUI.Popper open={open} anchorEl={anchorRef.current} transition disablePortal placement="bottom-end">
            {({ TransitionProps, placement }) => (
              <MUI.Grow {...TransitionProps}>
                <MUI.Paper id="menu-list-grow">
                  <MUI.ClickAwayListener onClickAway={handleClose}>
                    <MUI.MenuList>
                      <MUI.MenuItem onClick={() => logout(false)}>
                        <ExitToApp className={clsx(classes.leftIcon, classes.iconSmall)} />
                        Sign out
                      </MUI.MenuItem>
                    </MUI.MenuList>
                  </MUI.ClickAwayListener>
                </MUI.Paper>
              </MUI.Grow>
            )}
          </MUI.Popper>
        </div>
      </MUI.Toolbar>
    </MUI.AppBar>
  );
}

export default Header;
