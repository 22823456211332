import React, { useState, useRef } from "react";
import { getIn } from "formik";
import { MUI } from "@amps/material-ui";
import Select from "react-select";
import clsx from "clsx";
import sortBy from "lodash/sortBy";

const useStyles = MUI.makeStyles(theme => ({
  helperText: {
    position: "absolute",
    top: "100%",
    margin: 0,
    color: "#f44336",
    fontSize: "0.75rem"
  },
  container: {
    position: "relative"
  },
  popper: {
    whiteSpace: "pre-line"
  }
}));

export const selectTheme = (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#f05025"
  }
});

const getStyles = (isValid: boolean) => {
  const generalStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minHeight: "40.63px",
      backgroundColor: "#ffffff",
      borderColor: state.isFocused ? "#f44336" : state.isDisabled ? "#e6e6e6" : "rgba(0, 0, 0, 0.26)",
      "&:hover": { borderColor: state.isFocused ? "#f44336" : "#2b2b2b" }
    })
  };

  if (isValid) return generalStyles;

  return {
    ...generalStyles,
    control: (base: any) => ({
      ...base,
      minHeight: "40.63px",
      borderColor: "#f44336",
      "&:hover": { borderColor: "#f44336" }
    })
  };
};

export default function FormikReactSelect(props: any) {
  const {
    field,
    onChange,
    onKeyDown,
    onEnter,
    form: { touched, errors, setFieldValue, setFieldTouched },
    maxMenuHeight = 140,
    tooltipText = "",
    limitReachedText,
    maxOptionCount,
    isMulti,
    options,
    sortByParam,
    ...otherProps
  } = props;
  const selectRef = useRef(null);
  const message = getIn(touched, field.name) && getIn(errors, field.name);
  const styles = getStyles(!message);
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);
  const selectedOptionCount = isMulti && field && field.value ? field.value.length : 0;
  const isLimitReached = isMulti && maxOptionCount <= selectedOptionCount;

  const handleKeyDown = (event: any) => {
    onKeyDown && onKeyDown(event);

    if (onEnter && event.key === "Enter") {
      const isMenuClosed = !(selectRef.current as any).state.menuIsOpen;
      isMenuClosed && onEnter(event);
    }
  };

  return (
    <MUI.Tooltip title={isFocused ? "" : tooltipText || ""} placement="top" classes={{ popper: classes.popper }}>
      <div className={classes.container}>
        <Select
          {...field}
          {...otherProps}
          ref={selectRef}
          value={field.value}
          isMulti={isMulti}
          onChange={(option: any) => {
            const sortedOption = isMulti && sortByParam ? sortBy(option, sortByParam) : option;

            // Fix loosing focus on clearIndicator click on another select
            setTimeout(() => {
              setFieldValue(field.name, sortedOption);
              onChange && onChange(sortedOption);
            }, 0);
          }}
          onKeyDown={handleKeyDown}
          onBlur={() => {
            setIsFocused(false);
            setFieldTouched(field.name, true);
          }}
          onFocus={() => setIsFocused(true)}
          theme={selectTheme}
          styles={styles}
          maxMenuHeight={maxMenuHeight}
          options={isLimitReached ? [] : options}
          noOptionsMessage={() => (isLimitReached && limitReachedText ? limitReachedText : "No Options")}
        />
        {message && <p className={clsx("error-msg", classes.helperText)}>{message}</p>}
      </div>
    </MUI.Tooltip>
  );
}
