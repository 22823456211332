import * as Yup from "yup";

import ServiceModel from "models/service.model";
import { RadiusOption, radiusOptions } from "components/shared/TreatmentForm";
import { getYupMinDate } from "utils/dateTimeUtils";
import InteractionTypes from "utils/constants/InteractionTypes";
import Stages from "utils/constants/Stages";

export const getRadiusOption = (radius: string | null) => {
  if (!radius) return radiusOptions[0];

  return radiusOptions.find((option: RadiusOption) => {
    return option.value === radius;
  });
};

const getFormSettings = (service: ServiceModel, interactionType: string) => {
  const {
    stage: { name: stageName }
  } = service;

  const InteractionFormSettings: any = {
    [Stages.GATHERING_INFORMATION]: {
      [InteractionTypes.MEMBER_CALLOUT]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          isCompleted: false
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean()
        })
      },
      [InteractionTypes.MEMBER_CALLBACK]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          isCompleted: false
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean()
        })
      },
      [InteractionTypes.TPA_CALLOUT]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          isCompleted: false,
          pointOfContact: "",
          contactDate: getYupMinDate(new Date()),
          referenceNumber: "",
          deductible: service.deductible ? parseFloat(service.deductible).toFixed(2) : "0.00",
          coPayAmount: service.copayAmount ? parseFloat(service.copayAmount).toFixed(2) : "0.00",
          deductibleMET: service.deductibleMET ? parseFloat(service.deductibleMET).toFixed(2) : "0.00",
          oop: service.oop ? parseFloat(service.oop).toFixed(2) : "0.00",
          oopMet: service.oopMet ? parseFloat(service.oopMet).toFixed(2) : "0.00",
          coInsurance: service.coInsurance || "",
          other: service.other || ""
        },
        formElements: ["memberBenefits", "tpaCalloutAdditionalFields"],
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean(),
          pointOfContact: Yup.string().max(50, "Must be 50 characters or less"),
          contactDate: Yup.date().nullable(),
          referenceNumber: Yup.string().max(50, "Must be 50 characters or less"),
          deductible: Yup.string(),
          deductibleMET: Yup.string(),
          oop: Yup.string(),
          oopMet: Yup.string(),
          coPayAmount: Yup.string(),
          coInsurance: Yup.string().max(100, "Must be 100 characters or less"),
          other: Yup.string().max(100, "Must be 100 characters or less")
        })
      },
      [InteractionTypes.TPA_CALLBACK]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          isCompleted: false
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean()
        })
      },
      [InteractionTypes.INTERNAL_CALLOUT]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less")
        })
      },
      [InteractionTypes.INTERNAL_CALLBACK]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less")
        })
      }
    },
    [Stages.LOCATING_FACILITY]: {
      [InteractionTypes.MEMBER_CALLOUT]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          providerToggle: false,
          providerId: "",
          providerExperience: null,
          specialties: [],
          zip: service.zipCode || "",
          radius: getRadiusOption(service.searchRadius)
        },
        formElements: ["zipRadiusSpecialty"],
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          providerToggle: Yup.boolean(),
          providerId: Yup.string().when("providerToggle", {
            is: true,
            then: Yup.string().required()
          }),
          providerExperience: Yup.string()
            .nullable()
            .when("providerToggle", {
              is: true,
              then: Yup.string().required()
            }),
          specialties: Yup.array().nullable(),
          zip: Yup.string(),
          radius: Yup.object()
        })
      },
      [InteractionTypes.MEMBER_CALLBACK]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          providerToggle: false,
          providerId: "",
          providerExperience: null,
          specialties: [],
          zip: service.zipCode || "",
          radius: getRadiusOption(service.searchRadius)
        },
        formElements: ["zipRadiusSpecialty"],
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          providerToggle: Yup.boolean(),
          providerId: Yup.string().when("providerToggle", {
            is: true,
            then: Yup.string().required()
          }),
          providerExperience: Yup.string()
            .nullable()
            .when("providerToggle", {
              is: true,
              then: Yup.string().required()
            }),
          specialties: Yup.array().nullable(),
          zip: Yup.string(),
          radius: Yup.object()
        })
      },
      [InteractionTypes.TPA_CALLOUT]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean()
        })
      },
      [InteractionTypes.TPA_CALLBACK]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean()
        })
      },
      [InteractionTypes.PROVIDER_CALLOUT]: {
        isOptionalLabel: true,
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          providerToggle: false,
          providerId: "",
          specialties: [],
          providerExperience: null,
          zip: service.zipCode || "",
          radius: getRadiusOption(service.searchRadius)
        },
        formElements: ["zipRadiusSpecialty"],
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          providerToggle: Yup.boolean(),
          providerId: Yup.string().when("providerToggle", {
            is: true,
            then: Yup.string().required()
          }),
          providerExperience: Yup.string()
            .nullable()
            .when("providerToggle", {
              is: true,
              then: Yup.string().required()
            }),
          specialties: Yup.array().nullable(),
          zip: Yup.string(),
          radius: Yup.object()
        })
      },
      [InteractionTypes.PROVIDER_CALLBACK]: {
        isOptionalLabel: true,
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          providerToggle: false,
          providerId: "",
          specialties: [],
          providerExperience: null,
          zip: service.zipCode || "",
          radius: getRadiusOption(service.searchRadius)
        },
        formElements: ["zipRadiusSpecialty"],
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          providerToggle: Yup.boolean(),
          providerId: Yup.string().when("providerToggle", {
            is: true,
            then: Yup.string().required()
          }),
          providerExperience: Yup.string()
            .nullable()
            .when("providerToggle", {
              is: true,
              then: Yup.string().required()
            }),
          specialties: Yup.array().nullable(),
          zip: Yup.string(),
          radius: Yup.object()
        })
      },
      [InteractionTypes.VENDOR_CALLOUT]: {
        isOptionalLabel: true,
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          vendor: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          vendor: Yup.string()
            .required("Required")
            .max(50, "Must be 50 characters or less")
        })
      },
      [InteractionTypes.VENDOR_CALLBACK]: {
        isOptionalLabel: true,
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          vendor: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          vendor: Yup.string()
            .required("Required")
            .max(50, "Must be 50 characters or less")
        })
      },
      [InteractionTypes.INTERNAL_CALLOUT]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less")
        })
      },
      [InteractionTypes.INTERNAL_CALLBACK]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less")
        })
      }
    },
    [Stages.CREATING_APPOINTMENT]: {
      [InteractionTypes.MEMBER_CALLOUT]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          isCompleted: false
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean()
        })
      },
      [InteractionTypes.MEMBER_CALLBACK]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          isCompleted: false
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean()
        })
      },
      [InteractionTypes.TPA_CALLOUT]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          isCompleted: false
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean()
        })
      },
      [InteractionTypes.TPA_CALLBACK]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          isCompleted: false
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean()
        })
      },
      [InteractionTypes.PROVIDER_CALLOUT]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          isCompleted: false
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean()
        })
      },
      [InteractionTypes.PROVIDER_CALLBACK]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          isCompleted: false
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean()
        })
      },
      [InteractionTypes.VENDOR_CALLOUT]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          isCompleted: false,
          providerToggle: false,
          providerId: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean(),
          providerToggle: Yup.boolean(),
          providerId: Yup.string().when("providerToggle", {
            is: true,
            then: Yup.string().required()
          })
        })
      },
      [InteractionTypes.VENDOR_CALLBACK]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: "",
          isCompleted: false,
          providerToggle: false,
          providerId: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean(),
          providerToggle: Yup.boolean(),
          providerId: Yup.string().when("providerToggle", {
            is: true,
            then: Yup.string().required()
          })
        })
      },
      [InteractionTypes.INTERNAL_CALLOUT]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less")
        })
      },
      [InteractionTypes.INTERNAL_CALLBACK]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less")
        })
      }
    },
    [Stages.FINAL_FOLLOWUP]: {
      [InteractionTypes.MEMBER_CALLOUT]: {
        initialValues: {
          externalNote: "",
          internalNote: "",
          isCompleted: false,
          careDenied: false
        },
        validationSchema: Yup.object().shape({
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean(),
          careDenied: Yup.boolean()
        })
      },
      [InteractionTypes.MEMBER_CALLBACK]: {
        initialValues: {
          externalNote: "",
          internalNote: "",
          isCompleted: false,
          careDenied: false
        },
        validationSchema: Yup.object().shape({
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean(),
          careDenied: Yup.boolean()
        })
      },
      [InteractionTypes.TPA_CALLOUT]: {
        initialValues: {
          externalNote: "",
          internalNote: "",
          isCompleted: false,
          careDenied: false
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean(),
          careDenied: Yup.boolean()
        })
      },
      [InteractionTypes.TPA_CALLBACK]: {
        initialValues: {
          externalNote: "",
          internalNote: "",
          isCompleted: false,
          careDenied: false
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less"),
          isCompleted: Yup.boolean(),
          careDenied: Yup.boolean()
        })
      },
      [InteractionTypes.INTERNAL_CALLOUT]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less")
        })
      },
      [InteractionTypes.INTERNAL_CALLBACK]: {
        initialValues: {
          followUpDate: null,
          externalNote: "",
          internalNote: ""
        },
        validationSchema: Yup.object().shape({
          followUpDate: Yup.date().nullable(),
          externalNote: Yup.string()
            .required("Required")
            .max(2000, "Must be 2000 characters or less"),
          internalNote: Yup.string().max(2000, "Must be 2000 characters or less")
        })
      }
    }
  };

  return InteractionFormSettings[stageName][interactionType];
};

export default getFormSettings;
