import React, { useRef, useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import { MUI } from "@amps/material-ui";
import useStyles from "components/ReportsPage/styles/ReportsPage.style";
import { RouteComponentProps } from "react-router-dom";
import { debounce } from "lodash";
import { initialState } from "redux/reducers/reportReducer";
import { useStoreActions, useStoreState } from "redux/reducers/hooks";
import PageTitle from "components/shared/PageTitle";
import ReportsToolbar from "components/ReportsPage/ReportsToolbar";
import ReportsTable from "components/ReportsPage/ReportsTable";
import { ReportsSearchCriteriaType } from "types";
import { Modals } from "utils/modalUtils";

interface ReportsPageProps {}

type TRouteParams = { id: string };
type Props = ReportsPageProps & RouteComponentProps<TRouteParams>;

export function ReportsPage(props: Props): React.ReactElement<Props> | null {
  const classes = useStyles();
  const [isSearching, setIsSearching] = useState(false);
  const { getClientReport, getFullReport, getReports, setSearchCriteria } = useStoreActions(actions => actions.report);
  const { searchCriteria, reports } = useStoreState(state => state.report);
  const openModal = useStoreActions(state => state.modal.openModal);

  const fetchData = useCallback(
    async (newSearchCriteria: ReportsSearchCriteriaType) => {
      setIsSearching(true);
      try {
        await getReports(newSearchCriteria);
      } finally {
        setIsSearching(false);
      }
    },
    [getReports]
  );

  const onCreateReport = async (reportType: string) => {
    try {
      setIsSearching(true);
      switch (reportType) {
        case "full":
          await getFullReport();
          break;
        case "client":
          await getClientReport();
          break;
        default:
          break;
      }
    } finally {
      setIsSearching(false);
    }

    setSearchCriteria({ ...initialState.searchCriteria });
  };

  const onSearch = useRef(
    debounce((value: any) => {
      const newSearchCriteria = {
        ...initialState.searchCriteria,
        clientGroupName: value
      };
      setSearchCriteria(newSearchCriteria);
    }, 1000)
  ).current;

  /* 
    Gets the reports when the component loads and when there is an 
    update in the search criteria 
  */
  useEffect(() => {
    fetchData(searchCriteria);
  }, [fetchData, searchCriteria]);

  /*
    Resets the search criteria to the original state when unmounting 
    the component 
  */
  useEffect(() => {
    return () => setSearchCriteria(initialState.searchCriteria);
  }, [setSearchCriteria]);

  const onModalOpen = () => {
    openModal({ modalType: Modals.CustomReport });
  };

  return (
    <MUI.Container className={classes.container} maxWidth="lg">
      <PageTitle title="Reports Center" />
      <ReportsToolbar onCreateReport={onCreateReport} onModalOpen={onModalOpen} onSearch={onSearch} />
      <ReportsTable isLoading={isSearching} searchCriteria={searchCriteria} reportData={reports} />
    </MUI.Container>
  );
}

export default withRouter(ReportsPage);
