import { formatToTimeZone } from "date-fns-timezone";
import { default as dateFnsFormat } from "date-fns/format";
import compareAsc from "date-fns/compare_asc";

import LuxonUtils from "@date-io/luxon";
import { DateTime } from "luxon";

// https://advancedpricing.atlassian.net/browse/DA-909
// Firefox returns decimal value for date.getTimezoneOffset() for whatever reason
// https://github.com/date-fns/date-fns/issues/1310
(Date.prototype as any).getTimezoneOffset = (function(ref) {
  return function(this: any) {
    return Math.ceil(ref.apply(this));
  };
})(Date.prototype.getTimezoneOffset);

const luxon = new LuxonUtils();
const getYearRange = (
  minDate: DateTime | string,
  maxDate: DateTime | string
) => {
  // Original but wrong implementation is
  // https://github.com/dmtrKovalenko/date-io/blob/master/packages/luxon/src/luxon-utils.ts
  const start = luxon.date(minDate);
  const end = luxon.date(maxDate);
  const { years } = end.diff(start, "years").toObject();

  if (!years || years <= 0) {
    return [];
  }

  const range = new Array<number>(Math.ceil(years) + 1)
    .fill(0)
    .map((num, i) => i)
    .map(year => start.plus({ years: year }));

  range[range.length - 1].year > end.year && range.pop();

  return range;
};
LuxonUtils.prototype.getYearRange = getYearRange;

const getDateString = (
  date: Date | string | number,
  timeZone?: string
): string => {
  return getFormattedDate(date, "MMM DD, YYYY", timeZone);
};

const getDateTimeString = (
  date: Date | string | number,
  timeZone?: string
): string => {
  return getFormattedDate(date, "MMM DD, YYYY hh:mm A", timeZone);
};

const getFormattedDate = (
  date: Date | string | number,
  format: string,
  timeZone?: string
): string => {
  if (!timeZone) {
    const browserDate = new Date(date); // date in the browser timezone

    return dateFnsFormat(browserDate, format);
  }

  return formatToTimeZone(date, format, {
    timeZone
  });
};

const getISO8601DateString = (date: Date | string | number): string => {
  return getFormattedDate(date, "YYYY-MM-DDTHH:mm:ssZ");
};

const isDateLess = (dateStart: string, dateEnd: string) => {
  if (!dateStart || !dateEnd) return true;

  return compareAsc(dateEnd, dateStart) !== -1;
};

const minDate = "01/01/1900";
const maxDate = "01/01/2100";

const getYupMinDate = (date?: Date | string | number): string => {
  return getFormattedDate(date || minDate, "YYYY-MM-DDT00:00:00");
};

const getPickerMinDate = (date?: Date | string | number): string => {
  return getFormattedDate(date || minDate, "YYYY-MM-DDT00:00:00Z");
};

const getYupMaxDate = (date?: Date | string | number): string => {
  return getFormattedDate(date || maxDate, "YYYY-MM-DDT23:59:59");
};

const getPickerMaxDate = (date?: Date | string | number): string => {
  return getFormattedDate(date || maxDate, "YYYY-MM-DDT23:59:59Z");
};

export {
  getDateString,
  getDateTimeString,
  getFormattedDate,
  getISO8601DateString,
  isDateLess,
  getYupMinDate,
  getPickerMinDate,
  getYupMaxDate,
  getPickerMaxDate,
  getYearRange,
  LuxonUtils
};
