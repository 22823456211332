import React from "react";
import { getDateString } from "utils/dateTimeUtils";
import { getUserFullName } from "utils/userUtils";
import RequestStatusLabel from "components/shared/RequestStatusLabel";

export const defaultSortDirection: "asc" | "desc" = "asc";
export const openDefaultSortBy = "nearestFollowUp";
export const closeDefaultSortBy = "id";
export const headerHeight = 38;
export const rowHeight = 40;

export const closedRequestsTableColumns = [
  {
    width: 150,
    flexGrow: 0,
    label: "Request ID",
    dataKey: "id",
    type: "numeric", // Right alignment
    index: 0
  },
  {
    width: 130,
    flexGrow: 0,
    label: "Status",
    dataKey: "requestStatus",
    disableSort: true,
    cellContentRenderer: (cellRendererProps: any) => {
      if (!cellRendererProps.cellData) return null;
      if (cellRendererProps.cellData === "...") return "...";

      const status = cellRendererProps.cellData;

      return <RequestStatusLabel status={status} />;
    }
  },
  {
    width: 220,
    flexGrow: 0,
    label: "Current Stage",
    dataKey: "currentStage",
    cellContentRenderer: (cellRendererProps: any) => {
      if (!cellRendererProps.cellData) return null;
      if (cellRendererProps.cellData === "...") return "...";

      const { id, name } = cellRendererProps.cellData;
      return `${id}: ${name}`;
    }
  },
  {
    width: 180,
    flexGrow: 0,
    label: "Followup Date",
    dataKey: "nearestFollowUp",
    cellContentRenderer: (cellRendererProps: any) => {
      if (!cellRendererProps.cellData) return null;
      if (cellRendererProps.cellData === "...") return "...";

      return getDateString(cellRendererProps.cellData);
    }
  },
  {
    width: 220,
    flexGrow: 0,
    label: "Member Name",
    dataKey: "member",
    disableSort: true,
    cellContentRenderer: (cellRendererProps: any) => {
      if (!cellRendererProps.cellData) return null;
      if (cellRendererProps.cellData === "...") return "...";

      const { lastName, firstName } = cellRendererProps.cellData;

      return `${lastName}, ${firstName}`;
    }
  },
  {
    width: 180,
    flexGrow: 0,
    label: "Request Updated",
    dataKey: "updatedOn",
    cellContentRenderer: (cellRendererProps: any) => {
      if (!cellRendererProps.cellData) return null;
      if (cellRendererProps.cellData === "...") return "...";

      return getDateString(cellRendererProps.cellData);
    }
  },
  {
    width: 180,
    flexGrow: 0,
    label: "Updated By",
    dataKey: "updatedBy",
    disableSort: true,
    cellContentRenderer: (cellRendererProps: any) => {
      if (!cellRendererProps.cellData) return null;
      if (cellRendererProps.cellData === "...") return "...";

      return getUserFullName(cellRendererProps.cellData);
    }
  }
];
