import React, { useState } from "react";
import { getIn } from "formik";
import { MUI } from "@amps/material-ui";
import Async from "react-select/async";
import clsx from "clsx";
import debounce from "lodash/debounce";
import calculateSize from "calculate-size";

const useStyles = MUI.makeStyles(theme => ({
  helperText: {
    position: "absolute",
    top: "100%",
    margin: 0,
    color: "#f44336",
    fontSize: "0.75rem"
  },
  container: {
    position: "relative"
  },
  popper: {
    whiteSpace: "pre-line"
  }
}));

const selectTheme = (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#f05025"
  }
});

const getStyles = (isValid: boolean) => {
  const generalStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minHeight: "40.63px",
      backgroundColor: "white",
      borderColor: state.isFocused ? "#f44336" : "rgba(0, 0, 0, 0.26)",
      "&:hover": { borderColor: state.isFocused ? "#f44336" : "#2b2b2b" }
    }),
    placeholder: (base: any, state: any) => ({
      ...base,
      maxWidth: "calc(100% - 8px)",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      opacity: state.isDisabled ? "0.5" : "0.8"
    }),
    clearIndicator: (base: any) => ({
      ...base,
      "&:hover": { cursor: "pointer" }
    }),
    loadingIndicator: (base: any) => ({
      ...base,
      width: "36px",
      padding: 0,
      margin: 0
    }),
    multiValue: (base: any) => ({
      ...base,
      maxWidth: "97%"
    }),
    // Fix for Edge
    input: (base: any, state: any) => {
      const inputSize = calculateSize(state.value, {
        font: "Arial",
        fontSize: "18px"
      });

      return {
        ...base,
        "& input": {
          width: `${inputSize.width}px !important`,
          minWidth: "2px"
        }
      };
    }
  };

  if (isValid) return generalStyles;

  return {
    ...generalStyles,
    control: (base: any, state: any) => ({
      ...base,
      minHeight: "40.63px",
      borderColor: "#f44336",
      "&:hover": { borderColor: "#f44336" }
    })
  };
};

export default function FormikReactSelectAsync(props: any) {
  const {
    field,
    maxOptionCount,
    loadOptions,
    isMulti,
    limitReachedText,
    tooltipText = "",
    form: { touched, errors, setFieldValue, setFieldTouched },
    maxMenuHeight = 140,
    ...otherProps
  } = props;
  const message = getIn(touched, field.name) && getIn(errors, field.name);
  const classes = useStyles();
  const styles = getStyles(!message);
  const [isFocused, setIsFocused] = useState(false);

  const selectedOptionCount = isMulti && field && field.value ? field.value.length : 0;

  const debouncedFetch = debounce((searchTerm, callback) => {
    loadOptions(searchTerm)
      .then((result: any) => callback(result))
      .catch((error: any) => callback([]));
  }, 250);

  const getOptions = (input: string, callback: Function) => {
    if (isMulti && maxOptionCount <= selectedOptionCount) return callback([]);

    if (input.length < 3) {
      return callback([]);
    }

    debouncedFetch(input, callback);
  };

  return (
    <MUI.Tooltip title={isFocused ? "" : tooltipText || ""} placement="top" classes={{ popper: classes.popper }}>
      <div className={classes.container}>
        <Async
          {...field}
          {...otherProps}
          value={field.value}
          onChange={(option: any) => setFieldValue(field.name, option)}
          onBlur={() => {
            setIsFocused(false);
            setFieldTouched(field.name, true);
          }}
          onFocus={() => setIsFocused(true)}
          theme={selectTheme}
          styles={styles}
          isMulti={isMulti}
          maxMenuHeight={maxMenuHeight}
          loadOptions={getOptions}
          isClearable={true}
          noOptionsMessage={() =>
            isMulti && maxOptionCount <= selectedOptionCount && limitReachedText ? limitReachedText : "No Options"
          }
        />
        {message && <p className={clsx("error-msg", classes.helperText)}>{message}</p>}
      </div>
    </MUI.Tooltip>
  );
}
