import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  editButton: {
    margin: "0px 4px"
  },
  divider: {
    width: 1,
    height: 25,
    margin: "2px"
  },
  textField: {
    fontSize: "14px",
    padding: "8px 12px"
  },
  multilineRoot: {
    padding: 0
  },
  disabledTextField: {
    color: "black"
  },
  noteDivider: {
    margin: "16px 0px"
  },
  helperText: {
    fontStyle: "italic",
    fontSize: "12px",
    marginTop: "12px"
  },
  noteText: {
    padding: 0,
    fontSize: "14px"
  },
  noteTitle: {
    padding: 0,
    fontSize: "16px",
    fontWeight: "bold"
  },
  actionButtons: {
    display: "flex",
    marginTop: "12px"
  },
  progressWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-12px 0 0 -12px"
  }
}));
