import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  rootContainer: {
    maxHeight: "calc(100vh - 250px)",
    overflow: "auto"
  },
  headCell: {
    backgroundColor: "#FCF2F1",
    position: "sticky",
    fontSize: "0.75rem"
  },
  cell: {
    padding: "2px 6px 2px 4px"
  },
  bodyCell: {
    maxWidth: "210px",
    overflowWrap: "break-word"
  },
  shortBodyCell: {
    maxWidth: "200px",
    overflowWrap: "break-word"
  },
  hover: {
    cursor: "pointer"
  },
  noPointer: {
    "&:hover": {
      cursor: "default"
    }
  },
  noOptions: {
    textAlign: "center",
    backgroundColor: "#e6eaed"
  },
  noSortIcon: {
    color: "rgba(200,200,200,0)!important"
  },
  starIcon: {
    color: "gold"
  },
}));
