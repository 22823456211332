import axios from "axios";
import ProviderModel from "models/provider.model";
import ProviderExperienceTypeModel from "models/providerExperienceType.model";
import { Thunk, Action, action, thunk } from "easy-peasy";
import { ProviderReducerModel, GetProviderPayload } from "types";

export function getAddressString(provider: ProviderModel) {
  if (!provider.address) return undefined;

  const {
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    state,
    postalCode,
    country
  } = provider.address;

  const addressArr = [];

  addressLine1 && addressArr.push(addressLine1);
  addressLine2 && addressArr.push(addressLine2);
  addressLine3 && addressArr.push(addressLine3);
  city && addressArr.push(city);
  state && addressArr.push(state);
  postalCode &&
    addressArr.push(
      postalCode.length === 9
        ? `${postalCode.slice(0, 5)}-${postalCode.slice(5)}` // #####-####
        : postalCode
    );
  country && addressArr.push(country);

  return addressArr.join(", ");
}

const setProviders: Action<ProviderReducerModel, ProviderModel[]> = action(
  (state, providers) => {
    state.providers = providers;
  }
);

const setExperienceTypes: Action<
  ProviderReducerModel,
  ProviderExperienceTypeModel[]
> = action((state, experienceTypes) => {
  state.experienceTypes = experienceTypes;
});

const getProviders: Thunk<ProviderReducerModel, GetProviderPayload> = thunk(
  async (actions, payload) => {
    const params = {
      sortBy: payload.sortBy,
      sortDirection: "asc",
      skip: payload.skip,
      take: payload.take,
      ...payload.searchData
    };

    const response: any = await axios.get("/providers", {
      params
    });

    return response as any;
  }
);

const getProviderExperienceTypes: Thunk<ProviderReducerModel> = thunk(
  async actions => {
    const response: any = await axios.get("/provider-experience-types");

    // .slice(0, 3) - we should display ONLY the first 3 options
    actions.setExperienceTypes(response.slice(0, 3));
  }
);

const getPushbackSubTypes: Thunk<ProviderReducerModel, string> = thunk(
  async (actions, mainType) => {
    const response: any = await axios.get("/provider-pushback-types", {
      params: {
        mainType
      }
    });

    return response;
  }
);

const getRequestReasons: Thunk<ProviderReducerModel> = thunk(async () => {
  const response: any = await axios.get("/sca-request-reasons");

  return response;
});

const getPatientStatuses: Thunk<ProviderReducerModel> = thunk(async () => {
  const response: any = await axios.get("/sca-member-statuses");

  return response;
});

const ProviderReducer: ProviderReducerModel = {
  providers: [],
  experienceTypes: [],
  setProviders,
  setExperienceTypes,
  getProviders,
  getProviderExperienceTypes,
  getPushbackSubTypes,
  getRequestReasons,
  getPatientStatuses
};

export default ProviderReducer;
