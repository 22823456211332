import React, { useState } from "react";
import { MUI } from "@amps/material-ui";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import useStyles from "components/DashboardPage/styles/RequestsTable.style";
import RequestModel from "models/request.model";
import RequestStatusLabel from "components/shared/RequestStatusLabel";
import DownloadReport from "components/DashboardPage/DownloadReport";
import RequestsTableHead from "components/DashboardPage/RequestsTable/RequestsTableHead";
import clsx from "clsx";
import { getFormattedDate } from "utils/dateTimeUtils";
import { getUserFullName } from "utils/userUtils";
import { getServiceStartIntentions } from "utils/serviceUtils";
import ReactDOM from "react-dom";
import { useDownloadReportContainer } from "utils/hooks/portalHooks";
import { openDefaultSortBy, defaultSortDirection } from "./requestsTableUtils";
import { StarRate, Warning } from "@material-ui/icons";

type RequestsTableProps = {
  requests: RequestModel[];
  isLoading: boolean;
  onSelectRequest: (request: RequestModel) => void;
  onSortChange: (sortBy: string, sortDirection: "asc" | "desc") => void;
};

type Props = RequestsTableProps & RouteComponentProps<any>;

export function RequestsTable(props: Props) {
  const classes = useStyles();
  const downloadReportContainer = useDownloadReportContainer();
  const [order, setOrder] = useState(defaultSortDirection);
  const [orderBy, setOrderBy] = useState(openDefaultSortBy);
  const { onSelectRequest, onSortChange, isLoading, requests } = props;

  const handleRequestSort = (event: any, newOrderBy: string) => {
    const sortDirection = order === "asc" ? "desc" : "asc";
    const sortBy = orderBy === newOrderBy && order === "desc" ? "" : newOrderBy;

    if (orderBy !== newOrderBy) {
      setOrder(defaultSortDirection);
      setOrderBy(newOrderBy);
      onSortChange(newOrderBy, defaultSortDirection);
    } else {
      setOrder(sortDirection);
      setOrderBy(sortBy);
      onSortChange(sortBy, sortDirection);
    }
  };
  return (
    <div>
      {isLoading && <MUI.LinearProgress />}

      {!requests.length && !isLoading && (
        <MUI.Paper>
          <MUI.Typography className={classes.noOptions}>No requests</MUI.Typography>
        </MUI.Paper>
      )}

      {!isLoading && !!requests.length && (
        <MUI.Paper className={classes.rootContainer}>
          <MUI.Table size="small">
            <RequestsTableHead
              order={order}
              defaultOrder={defaultSortDirection}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <MUI.TableBody>
              {requests.map((request) => (
                <MUI.TableRow
                  hover
                  onClick={() => onSelectRequest(request)}
                  key={request.id}
                  classes={{
                    hover: classes.hover
                  }}
                >
                  <MUI.TableCell align="center" classes={{ root: classes.cell }}>
                    {request?.priority ? <Warning style={{ color: "gold" }} /> : ""}
                  </MUI.TableCell>

                  <MUI.TableCell align="center" classes={{ root: classes.cell }}>
                    {request.id}
                  </MUI.TableCell>
                  <MUI.TableCell classes={{ root: clsx(classes.cell, classes.bodyCell) }}>
                    {request.requestStatus && (
                      <RequestStatusLabel status={request.requestStatus} additionalStatus={request.additionalStatus} />
                    )}
                  </MUI.TableCell>
                  <MUI.TableCell align="left" classes={{ root: classes.cell }}>
                    {getServiceStartIntentions(request.services[0])}
                  </MUI.TableCell>
                  <MUI.TableCell classes={{ root: classes.cell }}>
                    {request.currentStage && `${request.currentStage.id}: ${request.currentStage.name}`}
                  </MUI.TableCell>
                  <MUI.TableCell classes={{ root: classes.cell }}>
                    {request.nearestFollowUp && getFormattedDate(request.nearestFollowUp, "MM/DD/YYYY")}
                  </MUI.TableCell>

                  <MUI.TableCell classes={{ root: classes.cell }}>
                    {request?.member?.isVip ? <StarRate style={{ color: "gold" }} /> : ""}
                  </MUI.TableCell>
                  <MUI.TableCell
                    classes={{
                      root: clsx(classes.cell, classes.shortBodyCell)
                    }}
                  >
                    {request.member && `${request.member.lastName}, ${request.member.firstName}`}
                  </MUI.TableCell>
                  <MUI.TableCell align="left" classes={{ root: classes.cell }}>
                    {(request.client && request.clientGroup) && (
                      <>
                        {`${request.client.code} `}
                        {request.clientGroup.name}
                        {request.clientGroup.isVip && (
                          <MUI.Icon className={classes.starIcon}>
                            <StarRate />
                          </MUI.Icon>
                        )}
                      </>
                    )}
                  </MUI.TableCell>
                  <MUI.TableCell
                    classes={{
                      root: clsx(classes.cell, classes.shortBodyCell)
                    }}
                  >
                    {getUserFullName(request.updatedBy)}
                  </MUI.TableCell>
                </MUI.TableRow>
              ))}
            </MUI.TableBody>
          </MUI.Table>
        </MUI.Paper>
      )}
      {ReactDOM.createPortal(<DownloadReport openRequests={requests} />, downloadReportContainer)}
    </div>
  );
}
export default withRouter(RequestsTable);
