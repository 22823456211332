import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2)
  },
  tableContainer: {
    marginTop: 16
  }
}));
