import React from "react";
import { useStoreState } from "redux/reducers/hooks";
import { ModalType } from "types";
import CustomReportModal from "components/ReportsPage/CustomReportModal";
import NewRequestModal from "components/DashboardPage/NewRequestModal";
import ConfirmationModal from "components/shared/ConfirmationModal";
import NewServiceModal from "components/shared/NewServiceModal";

type ModalLookup = {
  [key: string]: Function;
};

const modalComponentLookupTable: ModalLookup = {
  CustomReportModal,
  NewRequestModal,
  NewServiceModal,
  ConfirmationModal
};

export default function ModalManager() {
  const { modals } = useStoreState(state => state.modal);

  const renderedModals = modals.map((modal: ModalType, index: number) => {
    const { modalType, modalProps = {} } = modal;
    const ModalComponent = modalComponentLookupTable[modalType];

    return <ModalComponent {...modalProps} key={modalType + index} />;
  });
  return renderedModals.length ? <span>{renderedModals}</span> : null;
}
