import React, { useState, useRef, useCallback, useEffect, ReactNode } from "react";
import IdleTimer from "react-idle-timer";
import distanceInWords from "date-fns/distance_in_words";
import { history } from "Router";
import throttle from "lodash/throttle";
import { useStoreActions } from "redux/reducers/hooks";
import { WarnModal } from "@amps/material-ui";
import Layout from "components/shared/Layout";
import ModalManager from "ModalManager";
import { ErrorOutline } from "@material-ui/icons";

const ONE_SECOND = 1000;
const IDLE_TIMEOUT = ONE_SECOND * 60 * 10; // 10 min
const LOGOUT_TIMEOUT = ONE_SECOND * 60 * 5; // 5 min
const THROTTLE_TIMEOUT = ONE_SECOND * 60 * 5; // 5 min
const ACTIVITY_EVENTS = [
  "mousemove",
  "keydown",
  "scroll",
  "wheel",
  "DOMMouseScroll",
  "mouseWheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove"
];

type Props = {
  children: ReactNode;
};

export function AuthenticatedPage(props: Props) {
  const { children } = props;
  const { getClosingReasons, getServiceStages, getServiceTypes } = useStoreActions(actions => actions.service);
  const getClients = useStoreActions(actions => actions.client.getClients);
  const getSpecialties = useStoreActions(actions => actions.specialty.getSpecialties);

  const [openIdleDialog, setOpenIdleDialog] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const timeoutRef = useRef(0);
  const intervalRef = useRef(0);
  const timeLeftInWords = distanceInWords(0, timeLeft, { addSuffix: true });
  const renew = useStoreActions(actions => actions.auth.renew);

  useEffect(() => {
    function fetchDictionaries() {
      getClosingReasons();
      getServiceStages();
      getServiceTypes();
      getSpecialties();
      getClients();
    }

    fetchDictionaries();
  }, [getClients, getSpecialties, getClosingReasons, getServiceTypes, getServiceStages]);

  function onIdle() {
    if (openIdleDialog) return;

    setOpenIdleDialog(true);

    timeoutRef.current = window.setTimeout(() => {
      handleLogout();
    }, LOGOUT_TIMEOUT);

    setTimeLeft(LOGOUT_TIMEOUT);
    intervalRef.current = window.setInterval(() => {
      setTimeLeft(timeLeft => timeLeft - ONE_SECOND);
    }, ONE_SECOND);
  }

  const onAction = useCallback(
    throttle(() => {
      if (openIdleDialog) return;
      // don't call renew if there is Idle popup
      renew();
    }, THROTTLE_TIMEOUT),
    [openIdleDialog]
  );

  function handleLogout() {
    clearHandlers();
    closeIdleDialog();

    history.push("/login");
  }

  function closeIdleDialog() {
    setOpenIdleDialog(false);
  }

  function handleStayLoggedIn() {
    renew();

    clearHandlers();
    closeIdleDialog();
  }

  function clearHandlers() {
    window.clearTimeout(timeoutRef.current);
    window.clearInterval(intervalRef.current);
  }

  return (
    <Layout>
      <IdleTimer
        element={document}
        onIdle={onIdle}
        timeout={IDLE_TIMEOUT}
        events={ACTIVITY_EVENTS}
        onAction={onAction}
      />
      {/* Related to https://advancedpricing.atlassian.net/browse/DA-723 */}
      <IdleTimer
        element={document}
        onIdle={handleLogout}
        timeout={IDLE_TIMEOUT + LOGOUT_TIMEOUT}
        events={ACTIVITY_EVENTS}
      />
      <WarnModal
        open={openIdleDialog}
        Icon={ErrorOutline}
        header="Are you still there?"
        body={
          <span>
            For your security you will be logged out
            <span> {timeLeftInWords}</span>
          </span>
        }
        onOk={handleStayLoggedIn}
        buttonText="Stay logged in"
      />
      <ModalManager />
      {children}
    </Layout>
  );
}

export default AuthenticatedPage;
