import React, { useState } from "react";
import ReactDOM from "react-dom";
import { MUI, InfiniteLoadingTable } from "@amps/material-ui";
import useStyles from "components/DashboardPage/styles/ClosedRequestsTable.style";
import DownloadReport from "components/DashboardPage/DownloadReport";
import { useDownloadReportContainer } from "utils/hooks/portalHooks";
import { useStoreActions, useStoreState } from "redux/reducers/hooks";
import { SearchRequestsResponse, RequestsSearchCriteriaType } from "types";
import { closedRequestsTableColumns, rowHeight, headerHeight, defaultSortDirection } from "./requestsTableUtils";
import RequestModel from "models/request.model";

type ClosedRequestsTableProps = {
  searchCriteria: RequestsSearchCriteriaType;
  isLoading: boolean;
  requestsData: SearchRequestsResponse;
  onSelectRequest: (request: RequestModel) => void;
  onSearchClosedRequests: (newSearchCriteria: RequestsSearchCriteriaType) => Promise<void>;
};

export function ClosedRequestsTable(props: ClosedRequestsTableProps) {
  const { isLoading, requestsData, onSelectRequest, searchCriteria, onSearchClosedRequests } = props;
  const [isSearching, setIsSearching] = useState(false);
  const { getMoreRequests } = useStoreActions(actions => actions.request);
  const { dashboardFilters } = useStoreState(state => state.request);
  const downloadReportContainer = useDownloadReportContainer();
  const classes = useStyles();

  const wrapperHeight =
    requestsData.data && requestsData.data.length
      ? `${rowHeight * requestsData.data.length + headerHeight + 2}px`
      : `${headerHeight}px`;

  const rowCount =
    requestsData.data && requestsData.data.length
      ? Math.min(requestsData.data.length + requestsData.take, requestsData.totalCount)
      : 0;

  const handleRowClick = (row: any) => {
    const { rowData: request } = row;

    if (request.id === "...") return; // request has not been loaded yet

    onSelectRequest(request);
  };

  const getMoreRows = async (startIndex: number, stopIndex: number) => {
    if (isSearching || !requestsData) return;

    setIsSearching(true);

    const sortBy = searchCriteria.sortBy === "currentStage" ? "currentStageId" : searchCriteria.sortBy;

    try {
      await getMoreRequests({
        ...searchCriteria,
        sortBy,
        skip: startIndex,
        take: stopIndex - startIndex + 1
      });
    } finally {
      setIsSearching(false);
    }
  };

  const handleRequestSort = async (sortParams: any) => {
    if (!requestsData) return;

    let { sortBy, sortDirection } = sortParams;

    // Remove sorting on 3rd click
    if (searchCriteria.sortBy !== sortBy) {
      sortDirection = defaultSortDirection;
    } else if (searchCriteria.sortDirection === "DESC") {
      sortBy = "";
      sortDirection = defaultSortDirection;
    }

    sortBy = sortBy === "currentStage" ? "currentStageId" : sortBy;

    const searchData = {
      ...searchCriteria,
      sortBy,
      sortDirection
    };
    onSearchClosedRequests(searchData);
  };

  return (
    <>
      {isLoading && <MUI.LinearProgress />}

      {(!requestsData || !requestsData.data.length) && !isLoading && (
        <MUI.Paper id="no-requests-message">
          <MUI.Typography className={classes.noOptions}>No requests</MUI.Typography>
        </MUI.Paper>
      )}

      {requestsData && !!requestsData.data.length && !isLoading && (
        <MUI.Paper className={classes.paper} style={{ height: wrapperHeight }}>
          <InfiniteLoadingTable
            isFilteringToolbarNeeded={false}
            rowCount={rowCount}
            onRowClick={handleRowClick}
            data={requestsData.data}
            hasMoreRows={true}
            onDataRequired={getMoreRows}
            classes={{
              tableRow: classes.tableRow,
              rootContainer: classes.rootContainer
            }}
            tableProps={{
              sort: handleRequestSort,
              sortBy: searchCriteria.sortBy,
              sortDirection: searchCriteria.sortDirection,
              defaultOrder: defaultSortDirection,
              cellClass: classes.tableCell,
              headerClass: classes.tableHead,
              headerHeight,
              rowHeight,
              gridStyle: { outline: "none" },
              headerStyle: { outline: "none" },
              rowStyle: { outline: "none" }
            }}
            infiniteLoaderProps={{
              minimumBatchSize: rowCount,
              threshold: 0
            }}
            columns={closedRequestsTableColumns}
          />
          {!requestsData.data.length && <MUI.LinearProgress />}
        </MUI.Paper>
      )}
      {ReactDOM.createPortal(<DownloadReport filters={dashboardFilters} />, downloadReportContainer)}
    </>
  );
}

export default ClosedRequestsTable;
