import { createTypedHooks } from "easy-peasy";
import { StoreModel } from "types";

const typedHooks = createTypedHooks<StoreModel>();

export const {
  useStoreActions,
  useStoreDispatch,
  useStoreState,
  useStore
} = typedHooks;
