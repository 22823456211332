import React from "react";
import { MUI } from "@amps/material-ui";
import clsx from "clsx";

import ServiceStatuses from "utils/constants/ServiceStatuses";
import ServiceStatusModel from "models/serviceStatus.model";

const useStyles = MUI.makeStyles(theme => ({
  chip: {
    maxWidth: "85%",
    height: "auto",
    minHeight: "24px",
    fontWeight: 600,
    borderRadius: "4px"
  },
  actionRequired: {
    backgroundColor: "rgba(161,35,16,0.25)"
  },
  pending: {
    backgroundColor: "rgba(255,213,121,0.35)"
  },
  closed: {
    backgroundColor: "rgba(58,219,118,0.3)"
  },
  label: {
    whiteSpace: "normal"
  }
}));

interface Props {
  status: ServiceStatusModel | string;
}

function ServiceStatusLabel(props: Props) {
  const { status } = props;
  const classes = useStyles();

  const getColorClass = (status: ServiceStatusModel | string) => {
    const statusName = typeof status === "string" ? status : status.name;

    switch (statusName) {
      case ServiceStatuses.ACTION_REQUIRED:
        return classes.actionRequired;
      case ServiceStatuses.PENDING:
      case ServiceStatuses.PENDING_SCA:
        return classes.pending;
      case ServiceStatuses.CLOSED:
        return classes.closed;
    }
  };

  const colorClass = getColorClass(status);
  const label = typeof status === "string" ? status : status.name;

  return (
    <MUI.Chip
      label={label}
      size="small"
      className={clsx(classes.chip, colorClass)}
      classes={{
        label: classes.label
      }}
    />
  );
}

export default ServiceStatusLabel;
