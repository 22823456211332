enum InteractionTypes {
  MEMBER_CALLED = "Member Called",
  MEMBER_CALLBACK = "Member Callback",
  MEMBER_CALLOUT = "Member Callout",
  TPA_CALLOUT = "TPA Callout",
  TPA_CALLBACK = "TPA Callback",
  PROVIDER_CALLOUT = "Provider Callout",
  PROVIDER_CALLBACK = "Provider Callback",
  VENDOR_CALLOUT = "Vendor Callout",
  VENDOR_CALLBACK = "Vendor Callback",
  INTERNAL_CALLOUT = "Internal Callout",
  INTERNAL_CALLBACK = "Internal Callback"
}

export default InteractionTypes;
