import axios from "axios";
import ServiceModel from "models/service.model";
import ServiceTypeModel from "models/serviceType.model";
import ServiceClosingReasonModel from "models/serviceClosingReason.model";
import ServiceIntentionModel from "models/serviceIntention.model";
import ServiceIntentionTypes from "utils/constants/ServiceIntentionTypes";
import StageModel from "models/stage.model";
import { Action, action, Thunk, thunk } from "easy-peasy";
import {
  ServiceReducerModel,
  UpdateServicePayload,
  CloseServicePayload,
  UpdateServiceDetailsPayload
} from "types";

const setServices: Action<ServiceReducerModel, ServiceModel[]> = action(
  (state, services) => {
    state.services = services;
  }
);

const setTypes: Action<ServiceReducerModel, ServiceTypeModel[]> = action(
  (state, types) => {
    state.types = types;
  }
);

const setStages: Action<ServiceReducerModel, StageModel[]> = action(
  (state, stages) => {
    state.stages = stages;
  }
);

const setClosingReasons: Action<
  ServiceReducerModel,
  ServiceClosingReasonModel[]
> = action((state, closingReasons) => {
  state.closingReasons = closingReasons;
});

const mapserviceIntention = (serviceIntention: { id: any; name: any; }[]) => {
  const intentions = [...serviceIntention]
  let otherServiceIntension: any;
  let otherServiceIntensionId:any
  otherServiceIntension = intentions.filter((intention,id) => {
    if (intention.name.includes("Other")) {
      otherServiceIntensionId=id
      return intention.id,intention.name
    }
  })
  intentions.splice(otherServiceIntensionId,1)
  intentions.push(otherServiceIntension[0])
  return intentions;
}

const setStartIntentions: Action<
  ServiceReducerModel,
  ServiceIntentionModel[]
> = action((state, startIntentions) => {
  let startIntention = mapserviceIntention(startIntentions)
  state.startIntentions = startIntention;
});

const setEndIntentions: Action<
  ServiceReducerModel,
  ServiceIntentionModel[]
> = action((state, endIntentions) => {
  let endIntention = mapserviceIntention(endIntentions)
  state.endIntentions = endIntention;
});

const getServiceTypesByMemberId: Thunk<ServiceReducerModel, string> = thunk(
  async (actions, memberId) => {
    const response: any = await axios.get(`/members/${memberId}/service-types`);

    return response;
  }
);

const getServiceTypes: Thunk<ServiceReducerModel> = thunk(
  async (actions, payload, { getState }) => {
    const { types } = getState();

    if (!!types.length) return;

    const response: any = await axios.get("/service-types");

    actions.setTypes(response);
  }
);

const getServiceStages: Thunk<ServiceReducerModel> = thunk(
  async (actions, payload, { getState }) => {
    const { stages } = getState();

    if (!!stages.length) return;

    const response: any = await axios.get("/service-stages");

    actions.setStages(response);
  }
);

const createService: Thunk<ServiceReducerModel, {}> = thunk(
  async (actions, data) => {
    const response: any = await axios.post("/services", data);

    return response;
  }
);

const updateService: Thunk<ServiceReducerModel, UpdateServicePayload> = thunk(
  async (actions, data) => {
    const response: any = await axios.patch("/services", data);

    return response;
  }
);

const closeService: Thunk<ServiceReducerModel, CloseServicePayload> = thunk(
  async (actions, data) => {
    const response: any = await axios.patch("/services", data);
    return response;
  }
);

const reOpenService: Thunk<ServiceReducerModel, number> = thunk(async (actions, serviceId) => {
const response: any = await axios.patch(`/services/reopenservice/${serviceId}`, {
  reOpenService: true,serviceId
});
return response;
  });

const updateServiceDetails: Thunk<
  ServiceReducerModel,
  UpdateServiceDetailsPayload
> = thunk(async (actions, payload) => {
  const response: any = await axios.patch(
    `/services/${payload.serviceId}/details`,
    payload.data
  );

  return response;
});

const getServiceIntentions: Thunk<ServiceReducerModel, string> = thunk(
  async (actions, type, { getState }) => {
    const { startIntentions, endIntentions } = getState();

    if (
      (type === ServiceIntentionTypes.START && !!startIntentions.length) ||
      (type === ServiceIntentionTypes.END && !!endIntentions.length)
    )
      return;

    const response: any = await axios.get("/service-intentions", {
      params: {
        type
      }
    });

    type === ServiceIntentionTypes.START &&
      actions.setStartIntentions(response);
    type === ServiceIntentionTypes.END && actions.setEndIntentions(response);
  }
);

const getClosingReasons: Thunk<ServiceReducerModel> = thunk(
  async (actions, payload, { getState }) => {
    const { closingReasons } = getState();

    if (!!closingReasons.length) return;

    const response: any = await axios.get("/service-closing-reasons");

    actions.setClosingReasons(response);
  }
);

const ServiceReducer: ServiceReducerModel = {
  services: [],
  types: [],
  stages: [],
  closingReasons: [],
  startIntentions: [],
  endIntentions: [],
  setServices,
  setTypes,
  setStages,
  setClosingReasons,
  setStartIntentions,
  setEndIntentions,
  getServiceTypesByMemberId,
  getServiceTypes,
  getServiceStages,
  createService,
  updateService,
  closeService,
  updateServiceDetails,
  getServiceIntentions,
  getClosingReasons,
  reOpenService,
};

export default ServiceReducer;
