import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  panelTitle: {
    height: "36px",
    fontSize: "18px"
  },
  expPanel: {
    marginBottom: "16px",
    borderRadius: "4px"
  },
  noOptions: {
    width: "100%",
    marginTop: "15px",
    textAlign: "center"
  },
  attachmentDivider: {
    margin: "16px 0px"
  },
  downloadFileLink: {
    wordBreak: "break-all",
    border: "none",
    background: "transparent",
    color: "#f05025",
    textTransform: "lowercase"
  }
}));
