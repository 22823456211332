import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  paper: {
    maxHeight: "165px",
    overflow: "auto",
    marginBottom: "16px"
  },
  table: {
    maxHeight: "100px"
  },
  tableHead: {
    backgroundColor: "rgba(240, 80, 37, 0.08)"
  },
  hover: {
    cursor: "pointer"
  },
  noOptions: {
    textAlign: "center",
    backgroundColor: "#e6eaed"
  },
  name: {
    position: "relative",
    top: "5px",
    color: "gold"
  },
  cell: {
    marginTop: "2px"
  }
}));
