import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  dialogTitle: {
    fontSize: "24px"
  },
  dialogPaper: {
    overflow: "visible",
    padding: "18px 24px 8px"
  },
  row: {
    marginBottom: "16px",
    display: "grid"
  },
  rowTitle: {
    marginBottom: "8px",
    fontSize: "16px"
  },
  textField: {
    fontSize: "14px",
    padding: "12px"
  },
  toogleButton: {
    backgroundColor: "rgba(240, 80, 37, 0.08)!important"
  },
  searchButton: {
    marginTop: "30px"
  },
  gridItem: {
    maxWidth: "27.77%",
    flexBasis: "27.77%"
  },
  progressWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: 3,
    marginLeft: -12
  }
}));
