import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(() => ({
  toolbar: {
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "17px"
  },
  textField: {
    fontSize: "14px",
    padding: "12px"
  },
  rowItem: {
    flexGrow: 1,
    marginRight: 20,
    "&--right": {
      marginLeft: "auto",
      marginRight: 0
    }
  }
}));
