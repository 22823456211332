import React, { useState, useEffect } from "react";
import { MUI, Button } from "@amps/material-ui";
import clsx from "clsx";
import useStyles from "components/RequestPage/styles/CloseServiceModal.style";
import ServiceModel from "models/service.model";
import RequestModel from "models/request.model";
import ServiceClosingReasonModel from "models/serviceClosingReason.model";
import InteractionModel from "models/interaction.model";
import ServiceIntentionModel from "models/serviceIntention.model";
import ServiceIntentionTypes from "utils/constants/ServiceIntentionTypes";
import { getInteractionWithNonClosedSCA } from "utils/serviceUtils";
import { useStoreActions, useStoreState } from "redux/reducers/hooks";
import { Close } from "@material-ui/icons";
import { Modals } from "utils/modalUtils";

interface NewInteractionModalProps {
  service: ServiceModel;
  request: RequestModel;
}

export function CloseServiceModal(props: NewInteractionModalProps) {
  const classes = useStyles();
  const { service, request } = props;
  const [open, setOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null as ServiceClosingReasonModel | null);
  const [isRequestCompleted, setIsRequestCompleted] = useState(false);
  const [isCloseServiceSubmitting, setIsCloseServiceSubmitting] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [isLastStep, setIsLastStep] = useState(false);
  const [selectedIntention, setSelectedIntention] = useState(null as ServiceIntentionModel | null);
  const {
    request: { getRequestById, closeRequest },
    service: { getServiceIntentions, closeService },
    modal: { openModal }
  } = useStoreActions(actions => actions);

  const { closingReasons: serviceClosingReasons, endIntentions: serviceEndIntentions } = useStoreState(
    actions => actions.service
  );

  useEffect(() => {
    getServiceIntentions(ServiceIntentionTypes.END);
  }, [serviceEndIntentions, getServiceIntentions]);

  const handleOpenModal = (event: any) => {
    event.stopPropagation();

    setOpen(true);
  };

  const handleCloseModal = () => {
    setIsLastStep(false);
    setSelectedIntention(null);
    setSelectedReason(null);
    setIsRequestCompleted(false);

    setOpen(false);
  };

  const handleCloseService = async () => {
    if (shouldConfirmationPopupRender()) {
      openModal({
        modalType: Modals.Confirmation,
        modalProps: {
          onOk: handleCloseConfirmationModal,
          cancelButtonText: "No",
          okButtonText: "Yes",
          messageContent: (
            <span>
              We will need to close the SCA request for <b>{providerName}</b>
            </span>
          )
        }
      });
      return;
    }

    submitCloseService();
  };

  const submitCloseService = async () => {
    if (!selectedReason || !selectedIntention) return;

    setIsCloseServiceSubmitting(true);

    try {
      const data: any = {
        serviceId: parseInt(service.id),
        closingReasonId: parseInt(selectedReason.id),
        endIntentionId: parseInt(selectedIntention.id)
      };
      const scaRequestInteraction = getInteractionWithNonClosedSCA(service);

      scaRequestInteraction &&
        scaRequestInteraction.scaRequest &&
        (data.scaRequestIdToClose = parseInt(scaRequestInteraction.scaRequest.id));

      await closeService(data);

      if (isRequestCompleted) {
        await closeRequest(request.id);
      }

      await getRequestById(request.id);
      handleCloseModal();
    } finally {
      setIsCloseServiceSubmitting(false);
    }
  };

  const handleCloseConfirmationModal = () => {
    submitCloseService();
  };

  const shouldConfirmationPopupRender = () => {
    const scaRequestInteraction: InteractionModel | undefined = getInteractionWithNonClosedSCA(service);

    if (!scaRequestInteraction || !scaRequestInteraction.provider) return false;

    setProviderName(scaRequestInteraction.provider.name);

    return true;
  };

  const handleNextStep = () => {
    setIsLastStep(true);
  };

  const handleBack = () => {
    setIsLastStep(false);
    setSelectedReason(null);
    setIsRequestCompleted(false);
  };

  return (
    <>
      <MUI.Button name="open-modal-button" size="small" className={classes.menuButton} onClick={handleOpenModal}>
        <Close />
        Close Service
      </MUI.Button>
      <MUI.Dialog id="close-service-modal" open={open} maxWidth="sm" fullWidth={true} disableBackdropClick={true}>
        {!isLastStep && (
          <>
            <MUI.DialogContent className={classes.dialogPaper}>
              <div className={classes.row}>
                <MUI.Typography variant="h6" className={classes.dialogTitle}>
                  Close Service
                </MUI.Typography>
              </div>
              <div className={classes.row}>
                <b>What was the Final Process the Member went through:</b>
              </div>
              <div className={classes.row}>
                {serviceEndIntentions.map((intention: ServiceIntentionModel) => (
                  <Button
                    className={clsx(
                      classes.mb,
                      selectedIntention && selectedIntention.id === intention.id && classes.selected
                    )}
                    key={intention.id}
                    name={`intention-button-${intention.id}`}
                    variant="outlined"
                    fullWidth
                    onClick={() => setSelectedIntention(intention)}
                  >
                    {intention.name}
                  </Button>
                ))}
              </div>
            </MUI.DialogContent>
            <MUI.DialogActions>
              <Button name="close-modal-button" onClick={handleCloseModal} color="primary">
                Cancel
              </Button>
              <div className={classes.progressWrapper}>
                <Button
                  name="next-step-button"
                  onClick={handleNextStep}
                  color="primary"
                  disabled={!selectedIntention}
                  variant={!selectedIntention ? "text" : "contained"}
                >
                  Next
                </Button>
              </div>
            </MUI.DialogActions>
          </>
        )}
        {isLastStep && (
          <>
            <MUI.DialogContent className={classes.dialogPaper}>
              <div className={classes.row}>
                <MUI.Typography variant="h6" className={classes.dialogTitle}>
                  Close Service
                </MUI.Typography>
              </div>
              <div className={classes.row}>
                <b>Choose Reason for Closing Out Service:</b>
              </div>
              <div className={classes.row}>
                {serviceClosingReasons.map(reason => (
                  <Button
                    className={clsx(classes.mb, selectedReason && selectedReason.id === reason.id && classes.selected)}
                    key={reason.id}
                    name={`reason-button-${reason.id}`}
                    variant="outlined"
                    fullWidth
                    onClick={() => setSelectedReason(reason)}
                  >
                    {reason.name}
                  </Button>
                ))}
              </div>
              <div>
                <MUI.FormControlLabel
                  control={
                    <MUI.Switch
                      color="primary"
                      value={isRequestCompleted}
                      onChange={(event: object, checked: boolean) => setIsRequestCompleted(checked)}
                    />
                  }
                  label="Request Completed?"
                  labelPlacement="start"
                  classes={{
                    root: classes.switchLabel
                  }}
                />
                {isRequestCompleted && (
                  <MUI.Typography variant="body2" color="textSecondary" className={classes.helperText}>
                    The request will be closed
                  </MUI.Typography>
                )}
              </div>
            </MUI.DialogContent>
            <MUI.DialogActions>
              <Button onClick={handleBack} color="primary">
                Back
              </Button>
              <div className={classes.progressWrapper}>
                <Button
                  name="close-service-button"
                  onClick={handleCloseService}
                  color="primary"
                  disabled={!selectedReason || isCloseServiceSubmitting}
                  variant={!selectedReason ? "text" : "contained"}
                >
                  Save and Close Service
                </Button>
                {isCloseServiceSubmitting && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </MUI.DialogActions>
          </>
        )}
      </MUI.Dialog>
    </>
  );
}

export default CloseServiceModal;
