enum ActivityLogTypes {
  FOLLOWUP_DATE_ADDING = "FOLLOWUP_DATE_ADDING",
  FOLLOWUP_DATE_UPDATING = "FOLLOWUP_DATE_UPDATING",
  FOLLOWUP_DATE_REMOVING = "FOLLOWUP_DATE_REMOVING",
  TREATMENT_AND_DETAILS_FORM_UPDATING = "TREATMENT_AND_DETAILS_FORM_UPDATING",
  SERVICE_STAGE_UPDATING = "SERVICE_STAGE_UPDATING",
  SERVICE_STATUS_UPDATING = "SERVICE_STATUS_UPDATING",
  SERVICE_CLOSING = "SERVICE_CLOSING",
  SERVICE_COMPLETED = "SERVICE_COMPLETED",
  REQUEST_STATUS_UPDATING = "REQUEST_STATUS_UPDATING",
  REQUEST_CLOSING = "REQUEST_CLOSING",
  INTERNAL_NOTES_ADDING = "INTERNAL_NOTES_ADDING",
  INTERNAL_NOTES_UPDATING = "INTERNAL_NOTES_UPDATING",
  INTERNAL_NOTES_REMOVING = "INTERNAL_NOTES_REMOVING",
  EXTERNAL_NOTES_UPDATING = "EXTERNAL_NOTES_UPDATING",
  SCA_REQUEST_CREATION = "SCA_REQUEST_CREATION",
  SCA_REQUEST_STATUS_CHANGING = "SCA_REQUEST_STATUS_CHANGING"
}

export default ActivityLogTypes;
