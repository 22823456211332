import React, { useState, useEffect } from "react";
import { MUI } from "@amps/material-ui";
import RequestStatusLabel from "components/shared/RequestStatusLabel";

import { RequestsSearchCriteriaType } from "types";

import useStyles from "components/RequestPage/styles/MemberRequests.style";

import MemberModel from "models/member.model";
import RequestModel from "models/request.model";
import { useStoreActions } from "redux/reducers/hooks";
import { ExpandMore } from "@material-ui/icons";

interface MemberRequestsProps {
  member: MemberModel;
  currentRequestId: number;
}

export function MemberRequests(props: MemberRequestsProps) {
  const { member, currentRequestId } = props;
  const classes = useStyles();
  const [memberRequests, setMemberRequests] = useState([] as RequestModel[]);
  const [memberRequestsLoading, setMemberRequestsLoading] = useState(false);
  const getAllRequests = useStoreActions(actions => actions.request.getAllRequests);

  useEffect(() => {
    if (!member) return;

    async function fetchRequests() {
      setMemberRequestsLoading(true);

      try {
        const { id } = member;
        const searchData: RequestsSearchCriteriaType = {
          members: [parseInt(id)]
        };

        const requests = await getAllRequests(searchData);
        const requestsWithoutCurrent = requests.data.filter((request: RequestModel) => request.id !== currentRequestId);

        setMemberRequests(requestsWithoutCurrent);
      } finally {
        setMemberRequestsLoading(false);
      }
    }

    fetchRequests();
  }, [member, currentRequestId, getAllRequests]);

  function getLegacyRequestsLink() {
    const env = process.env.REACT_APP_SERVER_ENV;

    return `https://portal.advancedpricing.com/login?next=%2Fconnex%2Fpatient%2F${member.id}`; // redirect to patient page after login
  }

  return (
    <MUI.Accordion classes={{ root: classes.expPanel }}>
      <MUI.AccordionSummary expandIcon={<ExpandMore />}>
        <MUI.Grid container spacing={0} alignItems="center" className={classes.panelTitle}>
          <span>
            <b>Member Requests</b>
          </span>
        </MUI.Grid>
      </MUI.AccordionSummary>
      <MUI.Divider variant="middle" />
      <MUI.AccordionDetails>
        <MUI.Grid container spacing={2}>
          <MUI.Grid item xs={12} className={classes.legacyRequestsLink}>
            <MUI.Link id="legacy-requests-link" href={getLegacyRequestsLink()} target="_blank" rel="noreferrer">
              Legacy Requests
            </MUI.Link>
          </MUI.Grid>
          {!!memberRequests.length &&
            memberRequests.map((request: RequestModel) => (
              <MUI.Grid item xs={6} key={request.id}>
                <MUI.Link href={`/request/${request.id}`} target="_blank" rel="noreferrer">
                  {request.id}
                </MUI.Link>{" "}
                <RequestStatusLabel status={request.requestStatus} />
              </MUI.Grid>
            ))}
          {!memberRequests.length && !memberRequestsLoading && (
            <MUI.Typography className={classes.noOptions}>No Other Requests</MUI.Typography>
          )}
          {memberRequestsLoading && (
            <MUI.Grid item xs={12}>
              <MUI.LinearProgress />
            </MUI.Grid>
          )}
        </MUI.Grid>
      </MUI.AccordionDetails>
    </MUI.Accordion>
  );
}

export default MemberRequests;
