import { MUI } from "@amps/material-ui";

export default MUI.makeStyles(theme => ({
  root: {
    display: "flex",
    position: "relative",
    flexFlow: "nowrap",
    marginBottom: 38
  },
  logoSection: {
    flexBasis: 150,
    "&.dark #lgWording": {
      fill: "#FFF"
    },
    "&.light #lgWording": {
      fill: "#444"
    }
  },
  titleSection: {
    fontSize: 28,
    lineHeight: "1.2em",
    fontWeight: 300,
    flexShrink: 1,
    paddingLeft: 12
  },
  divider: {
    height: 80
  }
}));
