import React, { useState, useEffect } from "react";
import { MUI, SideIconButton } from "@amps/material-ui";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router";
import { getDateString, getDateTimeString } from "utils/dateTimeUtils";
import { getUserFullName } from "utils/userUtils";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useStyles from "components/RequestPage/styles/RequestPage.style";
import MemberModel from "models/member.model";
import ClientModel from "models/client.model";
import GroupModel from "models/group.model";
import ProductModel from "models/product.model";
import ServiceModel from "models/service.model";
import MemberProfile from "components/shared/MemberProfile";
import ServiceProgressBar from "components/shared/ServiceProgressBar";
import ActivitiesLog from "components/RequestPage/ActivitiesLog";
import MemberProfileNotes from "components/RequestPage/MemberProfileNotes";
import MemberRequests from "components/RequestPage/MemberRequests";
import PlanNotes from "components/RequestPage/PlanNotes";
import RequestAttachments from "components/RequestPage/RequestAttachments";
import RequestStatusLabel from "components/shared/RequestStatusLabel";
import { useStoreActions, useStoreState } from "redux/reducers/hooks";
import { NavigateBefore, Info, ReportProblem, ExpandMore, StarRate, Add } from "@material-ui/icons";
import { Modals } from "utils/modalUtils";
import { hasReOpenClosedRequestPermission } from "utils/permissionUtils";

type TRouteParams = { id: string };
type Props = RouteComponentProps<TRouteParams>;

export function RequestPage(props: Props): React.ReactElement<Props> | null {
  const {
    match: {
      params: { id }
    }
  } = props;
  const requestId = id ? Number.parseInt(id) : -1;

  let request = useStoreState(state => state.request.request);

  if (request && request.id !== requestId) {
    // ignore request from redux
    // if URL param isn't equal to current request in redux
    request = null;
  }
  const [member, setMember] = useState(null as MemberModel | null);
  const [client, setClient] = useState(null as ClientModel | null);
  const [group, setGroup] = useState(null as GroupModel | null);
  const [products, setProducts] = useState([] as ProductModel[]);
  const [isMemberProfileLoading, setIsMemberProfileLoading] = useState(false);
  const [currentService, setCurrentService] = useState(undefined as ServiceModel | undefined);
  const classes = useStyles();
  const memberId = request && request.memberId;
  const { getRequestById, fetchFile, updateRequestPriority } = useStoreActions(actions => actions.request);
  const getMemberById = useStoreActions(actions => actions.member.getMemberById);
  const getClientById = useStoreActions(actions => actions.client.getClientById);
  const getGroupById = useStoreActions(actions => actions.group.getGroupById);
  const getProductsByGroupId = useStoreActions(actions => actions.product.getProductsByGroupId);
  const [isReOpenClosedRequest, setIsReOpenClosedRequest] = useState(false);
  const permissions = useStoreState(state => state.auth.permissions);
  const {
    request: { reOpenClosedRequest },
    modal: { openModal }
  } = useStoreActions(actions => actions);
  useEffect(() => {
    getRequestById(requestId);
  }, [requestId, getRequestById]);

  useEffect(() => {
    if (!request) return;

    const currentService = request.services.find(service => service.isCurrent);

    setCurrentService(currentService);
  }, [request]);

  useEffect(() => {
    async function fetchMember() {
      if (!memberId) return;

      const member = await getMemberById(memberId);
      setMember(member);
    }

    fetchMember();
  }, [memberId, getMemberById]);

  useEffect(() => {
    async function fetchData() {
      if (!member) return;

      setIsMemberProfileLoading(true);

      try {
        const client = await getClientById(member.clientId);
        setClient(client);

        const group = await getGroupById(member.clientGroupId);
        setGroup(group);

        const data = await getProductsByGroupId(member.clientGroupId);
        setProducts(data.products);
      } finally {
        setIsMemberProfileLoading(false);
      }
    }

    fetchData();
  }, [member, getClientById, getGroupById, getProductsByGroupId]);

  if (!request || !currentService) return <MUI.CircularProgress size={48} className={classes.buttonProgress} />;

  const onFetchFileHandler = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.preventDefault();

    const { name, id } = evt.currentTarget;

    fetchFile({ fileId: id, name });
  };

  const onPriorityChange = async (event: any) => {
    if (request) {
      await updateRequestPriority({ requestId: request.id, priority: !request.priority });
      await getRequestById(requestId);
    }
  };
  const onReOpenClosedRequestClick = () => {
    openModal({
      modalType: Modals.Confirmation,
      modalProps: {
        onOk: handleReOpenClosedRequest,
        isOkInProgress: isReOpenClosedRequest,
        okButtonText: "Reopen Closed Request"
      }
    });
  };

  const handleReOpenClosedRequest = async () => {
    setIsReOpenClosedRequest(true);

    try {
      await reOpenClosedRequest(requestId);
      await getRequestById(requestId);
    } finally {
      setIsReOpenClosedRequest(false);
    }
  };

  return (
    <MUI.Container className={classes.container} maxWidth="lg">
      <div className={classes.pageTitle}>
        <SideIconButton component={Link} to="/" color="primary" Icon={NavigateBefore} align="left">
          Back to Dashboard
        </SideIconButton>
        <MUI.Typography variant="h4" className={classes.titleText}>
          Member Service Request
        </MUI.Typography>
      </div>

      {request.requestStatus && request.requestStatus.name === "Closed" && (
        <div className={classes.sectionRow}>
          <MUI.Paper className={classes.closedRequestPaper}>
            <Info className={classes.infoIcon} />
            <span className={classes.infoMainText}>Request has been Closed</span>
            {request.closedOn && request.closedBy && (
              <span className={classes.infoHelperText}>
                on {getDateTimeString(request.closedOn)} by {getUserFullName(request.closedBy)}
              </span>
            )}
            {hasReOpenClosedRequestPermission(permissions) && (
              <MUI.Grid className={classes.reOpenButton}>
                <SideIconButton
                  id="re-open-button"
                  variant="contained"
                  color="secondary"
                  Icon={Add}
                  align="left"
                  onClick={onReOpenClosedRequestClick}
                >
                  Reopen Request
                </SideIconButton>
              </MUI.Grid>
            )}
          </MUI.Paper>
        </div>
      )}

      <div className={classes.sectionRow} id="request-panel">
        <MUI.Paper className={classes.paper}>
          <div className={classes.row}>
            <MUI.Grid container spacing={2}>
              <MUI.Grid item xs={2}>
                <MUI.Typography variant="body2" color="textSecondary">
                  Request ID
                </MUI.Typography>
                {request.id}
              </MUI.Grid>
              <MUI.Grid item xs={3}>
                <MUI.Typography variant="body2" color="textSecondary">
                  Request Created
                </MUI.Typography>
                {getDateString(request.createdOn)} by
                <span> {getUserFullName(request.createdBy)}</span>
              </MUI.Grid>
              <MUI.Grid item xs={2}>
                <MUI.Typography variant="body2" color="textSecondary">
                  Current Service
                </MUI.Typography>
                <b>{currentService.type.name}</b>
              </MUI.Grid>
              <MUI.Grid item xs={3}>
                <MUI.Typography variant="body2" color="textSecondary">
                  Current Stage
                </MUI.Typography>
                <ServiceProgressBar service={currentService} />
              </MUI.Grid>
              <MUI.Grid item xs={2}>
                <MUI.Typography variant="body2" color="textSecondary">
                  Current Status
                </MUI.Typography>
                {request.requestStatus && <RequestStatusLabel status={request.requestStatus} />}
              </MUI.Grid>
            </MUI.Grid>
          </div>

          <MUI.Grid container spacing={2}>
            <MUI.Grid item xs={2}>
              <MUI.FormControlLabel
                label="Priority"
                control={
                  <MUI.Switch color="primary" name="priority" onChange={onPriorityChange} checked={request.priority} />
                }
              />
              {request.priority}
            </MUI.Grid>
            <MUI.Grid item xs={3}>
              <MUI.Typography variant="body2" color="textSecondary">
                Request Updated
              </MUI.Typography>
              {getDateString(request.updatedOn)} by
              <span> {getUserFullName(request.updatedBy)}</span>
            </MUI.Grid>
            <MUI.Grid item xs={2}>
              <MUI.Typography variant="body2" color="textSecondary">
                Goal
              </MUI.Typography>
              {request.goal}
            </MUI.Grid>
            <MUI.Grid item xs={2}>
              {request.currentUrgentFlag && (
                <MUI.Typography variant="body2" color="textSecondary">
                  <ReportProblem color="error" className={classes.emergencyIcon} />
                  <b> Urgent/Emergency</b>
                </MUI.Typography>
              )}
              {request.nearestFollowUp && (
                <>
                  Follow up by: <b>{getDateString(request.nearestFollowUp)}</b>
                </>
              )}
            </MUI.Grid>
          </MUI.Grid>
        </MUI.Paper>
      </div>
      {member && (
        <div className={classes.sectionRow}>
          <MUI.Grid container spacing={3}>
            <MUI.Grid item xs={4}>
              <MUI.Accordion className={classes.expPanel} defaultExpanded>
                <MUI.AccordionSummary expandIcon={<ExpandMore />}>
                  <MUI.Paper elevation={0}>
                    <MUI.Typography variant="body2" color="textSecondary" className={classes.row}>
                      MEMBER ID# <b>{member.memberNumber}</b>
                    </MUI.Typography>
                    <MUI.Typography variant="h5" className={clsx(classes.row, classes.longTitle)}>
                      {member.isVip && <StarRate className={classes.starIcon} />}
                      {member.lastName}, {member.firstName}
                    </MUI.Typography>
                    <MUI.Typography variant="body2" color="textSecondary">
                      <span>Born: </span>
                      <b>
                        {getDateString(member.dateOfBirth, "UTC")} ({member.age}
                        y)
                      </b>
                    </MUI.Typography>
                    {member.gender && (
                      <MUI.Typography variant="body2" color="textSecondary">
                        <span>Sex: </span>
                        <b>{member.gender}</b>
                      </MUI.Typography>
                    )}
                    {(member.addressLine1 || member.city || member.postalCode) && (
                      <div className={classes.mt}>
                        <MUI.Typography variant="body2" color="textSecondary">
                          <b>{member.addressLine1}</b>
                        </MUI.Typography>
                        <MUI.Typography variant="body2" color="textSecondary">
                          <b>{member.city}</b> <b>{member.postalCode}</b>
                          <MUI.Typography variant="body2" color="textSecondary">
                            <b>{member.state}</b>
                          </MUI.Typography>
                        </MUI.Typography>
                      </div>
                    )}
                  </MUI.Paper>
                </MUI.AccordionSummary>
                <MUI.Divider variant="middle" />
                <MUI.AccordionDetails>
                  <>
                    {client && group && !isMemberProfileLoading && (
                      <MemberProfile singleColumn member={member} client={client} group={group} products={products} />
                    )}
                    {isMemberProfileLoading && (
                      <MUI.Grid item xs={12}>
                        <MUI.LinearProgress />
                      </MUI.Grid>
                    )}
                  </>
                </MUI.AccordionDetails>
              </MUI.Accordion>

              <RequestAttachments request={request} onFetchFileHandler={onFetchFileHandler} />

              <MemberProfileNotes member={member} />

              <MemberRequests currentRequestId={request.id} member={member} />
            </MUI.Grid>
            <MUI.Grid item xs={8}>
              <ActivitiesLog request={request} member={member} />
            </MUI.Grid>
          </MUI.Grid>
        </div>
      )}
    </MUI.Container>
  );
}

export default withRouter(RequestPage);
