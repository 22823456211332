import NoteEditingPermission from "utils/constants/NoteEditingPermissions";
import ReOpenClosedRequestPermissions from "./constants/ReOpenClosedRequestPermission";

const hasEditorPermission = (permissions: string[]) => {
  if (!permissions) return false;

  return permissions.includes(
    NoteEditingPermission.CARE_NAVIGATOR_NOTES_EDITOR
  );
};

const hasReOpenClosedRequestPermission = (permissions: string[]) => {
  if (!permissions) return false;

  return permissions.includes(
    ReOpenClosedRequestPermissions.RE_OPEN_CLOSED_REQUEST
  );
};

export { hasEditorPermission, hasReOpenClosedRequestPermission};
